import React, { useEffect, useLayoutEffect, useState, memo } from 'react';
import { View, Pressable, Platform } from 'react-native';
import { FontAwesome5, Ionicons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import { useWindowDimensions } from 'react-native';
import styles from '@stylesheet';
import searchStyles from '../searchStyles';
import { GenerateArrayTypes } from '../Types';
import { Popover } from 'tamagui';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { useDeviceType } from '@hooks/useDeviceType';

const AnimatedIcon = ({ name }) => (
  <Animatable.View animation="fadeInDown" duration={250} style={searchStyles.iconStyle}>
    <FontAwesome5 name={name} size={name === 'ellipsis-h' ? 24 : 20} color="rgba(0,0,0,0.25)" style={searchStyles.searchIconStyle} />
  </Animatable.View>
);

const IconItem = ({ item, index, handleFunction, setExtendedMenu, extendedMenu }) => (
  <Pressable
    style={[styles.iconStyle, {backgroundColor:"rgb(90,192,234)"}]}
    key={item.functionName}
    onPress={() => {
      handleFunction(item.function());
      if (item.extendedOptions && extendedMenu !== item.functionName) {
        setExtendedMenu(item.functionName);
      }
    }}
  >
    {({ hovered }) => {
      return (
        <Animatable.View transition={'backgroundColor'} style={[styles.centerEverything, { borderRadius: 10, padding: 5, backgroundColor: hovered ? 'rgba(0,0,0,0.1)' : 'transparent' }]}>
      {item.icon}
        </Animatable.View>
      )
    }}
  </Pressable>
);

const GenerateArray = ({
  customIcons,
  setExtendedMenu,
  extendedMenu,
  disableBurger,
  setShowExtendedMenu,
  width
}: GenerateArrayTypes) => {
  const [sizeOfWindow, setSizeOfWindow] = useState(0);
  const sizeOfArray = customIcons.length * 40;
const theme = themeSelector();
  const { isTablet } = useDeviceType();


  const [breakpointAchieved, setBreakpointAchieved] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useLayoutEffect(() => {
    breakPointHandler();
  }, []);

  const handleFunction = (passedFunction) => {
    setOpenMenu(false);
    if (typeof passedFunction === 'function') {
      passedFunction();
    }
  };

  const breakPointHandler = () => {
    const breakpoint = width ? sizeOfArray > width : sizeOfArray < sizeOfWindow;
    setBreakpointAchieved(breakpoint);
  };

  const renderIcons = () => {
    return customIcons.map((item, index) => (
      <IconItem
        key={item.functionName}
        item={item}
        index={index}
        handleFunction={handleFunction}
        setExtendedMenu={setExtendedMenu}
        extendedMenu={extendedMenu}
      />
    ));
  };

  return (
    <View style={[
      searchStyles.iconArrayStyle,
      { flexGrow: 1, alignSelf: "center", alignContent: "center", alignItems: "center" }
    ]}>
      <View style={{ flexDirection: 'row', flex: 1, marginRight: 60, gap: 10, width: breakpointAchieved && !isTablet ? customIcons.length * 50 + 50 : undefined, maxWidth: breakpointAchieved && !isTablet ? customIcons.length * 50 + 150 : 60 }}>
        {breakpointAchieved && !isTablet && Platform.OS != 'web' && !disableBurger ? (
          <BurgerComponent
            customIcons={customIcons}
            renderIcons={renderIcons}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
        ) : (
            renderIcons()
        )}
      </View>
    </View>
  );
};

const BurgerComponent = ({ customIcons, renderIcons, openMenu, setOpenMenu }) => {
  return (
    <View style={[
      styles.centerEverything,
      { zIndex: -1, flexGrow: 1, gap: 20, flex: 0.5, flexShrink: 0.1, alignContent: 'center', alignItems: 'center', justifyContent: 'center', maxWidth: 40, minWidth: 30 }
    ]}>
      <Popover
      stayInFrame
      placement='bottom'
      >
        <Popover.Trigger asChild>
          <Pressable hitSlop={15} onPress={() => setOpenMenu(!openMenu)} style={[
        styles.centerEverything,
        { alignContent: 'center', justifyContent: 'center', alignSelf: 'center', alignItems: 'center' }
      ]}>
            <Ionicons name="menu-sharp" size={24} color="rgb(90,192,234)" style={[
          styles.centerEverything,
          { verticalAlign: 'middle', textAlign: 'center', alignSelf: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }
        ]} />
      </Pressable>
        </Popover.Trigger>
        <Popover.Content
          enterStyle={{ y: 50, opacity: 0 }}
          exitStyle={{ y: 30, opacity: 0 }}
          animation={[
            'superBouncy',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
        >
          <Popover.Arrow borderColor={"rgb(90,192,234)"} />
          <View style={{
          gap: 15,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
          <View pointerEvents="none" style={{
            transform: [{ rotateZ: '45deg' }],
            width: 15,
            zIndex: -5,
            height: 15,
            left: 17
          }} />
          {renderIcons()}
          </View>
        </Popover.Content>
      </Popover>
    </View>
  );
};

export default memo(GenerateArray);
