const abortControllers = new Map();

export const addAbortController = (key, controller) => {
  console.log('added an abort controller');
  abortControllers.set(key, controller);
};

export const removeAbortController = (key) => {
  abortControllers.delete(key);
};

export const abortRequest = (key) => {
  console.log('calling abort with key:', key);
  if (abortControllers.has(key)) {
    abortControllers.get(key).abort();
    abortControllers.delete(key);
  }
};

export const abortAllRequests = async () => {
  console.log('abortAllRequests');
  const abortPromises = Array.from(abortControllers.values()).map(controller => {
    return new Promise((resolve) => {
      if (controller) {
        controller.abort?.();
      }
      resolve();
    });
  });
  await Promise.all(abortPromises);
  abortControllers.clear();
};