import UserIcon from '@assets/icons/UserIcon';
import AvatarHandler from '@components/Global/AvatarHandler/AvatarHandler';
import { DropDownPicker } from '@constants/Themed';
import { AntDesign } from '@expo/vector-icons';
import toTitleCase from '@hooks/toTitleCase';
import styles from '@stylesheet';
import React, { useEffect, useState } from 'react';
import { View, Text, Image, ImageStyle, ViewStyle } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { SizableText, XStack, YStack } from 'tamagui';

interface UserListItemProps {
  item: string | { id: string, name: string, surname: string, email: string, avatar: string | { img: string } };
  index?: number;
  filterUser?: (id: string) => void;
  row?: boolean;
  removePermission?: boolean;
  textColor?: string;
  hideEmail?: boolean;
  avatarImageStyle?: ImageStyle;
  inverse?: boolean;
  hideDetails?: boolean;
  containerStyle?: ViewStyle;
  size?: 'large' | 'small';
}

const UserListItem = ({ size, containerStyle, hideDetails, inverse, avatarImageStyle, hideEmail = false, item, textColor, index, row, removePermission = false, filterUser }: UserListItemProps) => {
  const [userItem, setUserItem] = useState(item);


  if (!item || item === 'localhost' || item === 'web'){
    return (
      <Animatable.View animation={'bounceIn'} style={{ flexDirection: row ? (inverse ? "column-reverse" : "column") : (inverse ? "row-reverse" : "row"), gap: 10, flex: 1, marginVertical: 10, ...containerStyle }}>
        <Image source={require('@assets/icons/cloud-connection.svg')} style={[styles.centerEverything, { aspectRatio: 1, borderColor: "white", width: 50, height: 50, borderRadius: avatarImageStyle?.width ? avatarImageStyle?.width / 2 : 50 }, avatarImageStyle]} />
      </Animatable.View>
    )
  }

    useEffect(() => {
      if (item?.profile_information) {
        setUserItem(item.profile_information)
      }

    }, [item]);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([
    { label: 'Admin', value: 'admin' },
    { label: 'Common', value: 'common' }
  ]);

  return (
    <Animatable.View animation={'bounceIn'} style={{ flexDirection: row ? (inverse ? "column-reverse" : "column") : (inverse ? "row-reverse" : "row"), gap: 10, flex: 1, marginVertical: 10, ...containerStyle }}>
      <XStack rowGap={20} gap={10} flexWrap='wrap'>
        <XStack flex={1}>
          <AvatarHandler avatar={userItem?.avatar} avatarImageStyle={{ width: 50, height: 50, borderRadius: 50, ...avatarImageStyle }} />
        {
          item?.count && <SizableText style={{ fontSize: 24, textAlign: "center", color: textColor ? textColor : undefined, fontWeight: '400' }}>{item?.count}</SizableText>
          }
        {
          !hideDetails &&
            <View style={{ flexDirection: "column", flex: 5, gap: 0, minWidth: "55%", justifyContent: 'center' }}>
              <Text style={{ fontSize: size === 'large' && 32, flexWrap: 'wrap', textAlign: "center", color: textColor ? textColor : undefined, fontWeight: '400' }}>{toTitleCase(userItem?.name) || `Inviting user to join your company: \n ${userItem?.email}`} {toTitleCase(userItem?.surname) || ""}</Text>
              {
                !hideEmail &&
              <Text style={{ textAlign: "center", color: textColor ? textColor : undefined, fontWeight: '400' }}>{userItem.email || ""}</Text>
              }
            </View>
        }
        </XStack>
        {
          !removePermission &&
          <DropDownPicker
            open={open}
            style={[styles.dropdownInput, { flex: 1, maxHeight: 50, top: -20 }]}
            dropDownContainerStyle={{ maxWidth: 120 }}
            closeAfterSelecting={true}
            placeholder="Role"
            setOpen={setOpen}
            items={items}
            value={value}
            setValue={setValue}
          />
        }
        {/* <AntDesign name="delete" size={24} color="black" onPress={() => { console.log(item); filterUser(item.id) }} /> */}
      </XStack>
      </Animatable.View>
    )

}
export default UserListItem;