import { FlatList, Text, View, Pressable, StyleSheet } from "react-native";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import topics from "./topicMap";
import toTitleCase from '@utils/toTitleCase';
import * as Animatable from 'react-native-animatable';
import striptags from 'striptags';
import moment from 'moment';
import { useQuery } from "react-query";
import apis from "@api/api";
import debounce from 'lodash/debounce';

type HelpSearchTypes = {
  searchTerm: string;
  handleFlip: (item: any) => void;
};

const ShowHelp = ({ searchTerm, handleFlip }: HelpSearchTypes) => {
  const [data, setData] = useState<any[]>([]);
  const [topicSelected, setTopicSelected] = useState<{ id: number | null, name: string | null }>({ id: null, name: null });

  // Retrieve common help articles
  const handleGetData = useCallback(async () => {
    try {
      const response = await fetch('https://smartsaas.co.uk/wp-json/wp/v2/docs');
      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error(err);
    }
  }, []);

  // React Query for internal knowledge
  const { data: internalData } = useQuery(
    'helpInternalKnowledge',
    async () => {
      const result = await apis.getInternalKnowledge();
      return result.internal;
    },
    {
      onSuccess: (internal) => {
        setData((prevData) => [internal, ...prevData]);
      },
      retry: 0,
    }
  );

  // Memoized topic list to prevent recalculation on every render
  const topicList = useMemo(() => {
    if (!data) return topics;
    return [{ name: "internal", id: 0, icon: null }, ...topics];
  }, [data]);

  const filterByTopic = useCallback(
    (topic) => {
      if (topicSelected?.id === topic.id) {
        setTopicSelected({ id: null, name: null });
      } else {
        setTopicSelected(topic);
        const filtered = data.filter((item) =>
          item?.doc_category?.some((category) => category === topic.id)
        );
        setData(filtered);
      }
    },
    [data, topicSelected]
  );

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    if (searchTerm) {
      debounce(() => filterByTopic({ name: searchTerm, id: 0 }), 300);
    }
  }, [searchTerm, filterByTopic]);

  const _renderItem = useCallback(({ item, index }) => {
    if (!item) return null;

    return (
      <Animatable.View animation={'slideInLeft'} delay={index * 100}>
        <Pressable style={styles.itemPressable} onPress={() => handleFlip(item)}>
          <View style={styles.itemContent}>
            <Text>{typeof item?.title === 'string' ? item.title : item.title?.rendered}</Text>
            <Text numberOfLines={2} style={styles.itemExcerpt}>
              {striptags(item.excerpt?.rendered)}
            </Text>
          </View>
          <View style={styles.itemMeta}>
            <Text style={styles.updatedText}>Updated:</Text>
            <Text>{moment(item?.modified).format('ddd DD MM yyyy')}</Text>
          </View>
        </Pressable>
      </Animatable.View>
    );
  }, [handleFlip]);

  const _renderTopics = useCallback(({ item }) => {
    const { name, id } = item;
    return (
      <Pressable
        onPress={() => filterByTopic({ name, id })}
        style={[styles.topicPressable, topicSelected?.id === id && styles.selectedTopic]}
      >
        <Text style={styles.topicText}>{toTitleCase(name)}</Text>
      </Pressable>
    );
  }, [filterByTopic, topicSelected]);

  return (
    <View style={styles.container}>
      <FlatList
        keyExtractor={(item) => String(item?.id)}
        data={topicList}
        renderItem={_renderTopics}
        horizontal
        style={{ minHeight: 70, maxHeight: 80 }}
        contentContainerStyle={styles.topicList}
      />
      <FlatList
        keyExtractor={(item) => String(item?.id)}
        data={data}
        renderItem={_renderItem}
        contentContainerStyle={styles.dataList}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignSelf: "stretch", minHeight: 70 },
  topicList: { gap: 20, padding: 10 },
  dataList: { gap: 10 },
  itemPressable: {
    padding: 10,
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  itemContent: { flex: 3 },
  itemExcerpt: { color: 'grey', fontStyle: 'italic' },
  itemMeta: { flex: 1, textAlign: 'right' },
  updatedText: { textAlign: 'right', fontStyle: 'italic' },
  topicPressable: {
    paddingVertical: 5,
    width: 100,
    minHeight: 40,
    borderRadius: 10,
    backgroundColor: '#ececec',
  },
  selectedTopic: { backgroundColor: 'rgb(90,192,234)' },
  topicText: { textAlign: 'center', fontWeight: 'bold' },
});

export default ShowHelp;