import { Text, TextInput, View, ScrollView, Pressable, Platform, useWindowDimensions, TouchableOpacity, FlatList } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Animatable from 'react-native-animatable';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import Checkbox from 'expo-checkbox';
import { useDispatch, useSelector } from 'react-redux';
import useGlobalUser from '@hooks/getGlobalUser';
import { DefaultRootState } from '@reduxLocal/persistState';
import { ADD_STICKY_NOTE, CHANGE_STICKY_NOTE, MARK_NOTE_COMPLETE, NEXT_STICKY_NOTE, PIN_NOTE, PREVIOUS_STICKY_NOTE, REMOVE_STICKY_NOTE, SHOW_STICKY_NOTES_MODAL } from '@reduxLocal/actions/types';
import apis, { addStickyNote } from '@api/api';
import { NoteTypes, IndividualNote } from './NoteTypes';
import { useTranslation } from 'react-i18next';
import decreaseBrightness from '@hooks/decreaseBrightness';
import TempScreen from '@components/Global/htmlEditor/HtmlEditor';
import { Popover } from '@mui/material';


const colors = ["#ffc09f", "#ffee93", "#fcf5c7", "#a0ced9", "#adf7b6"];

const RenderNote = ({ unassigned = false, unbounded, note, count, refetch, isStuck = false, inputCallback = () => { } }: NoteTypes) => {
  const userId = useGlobalUser();
  const [checked, setChecked] = useState(note.completed);
  const [currentStickyNote, setCurrentStickyNote] = useState<IndividualNote>({ _id: note?._id || "", content: note?.content || "", completed: note?.completed, color: note?.color });
  const { t } = useTranslation();
  const [showColorSelect, setShowColorSelect] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const stickyNoteRef = useRef(null);
  const dispatch = useDispatch();
  const [colorSelect, setColorSelect] = useState(null);

  const ColorSelect = ({color, selectedNote, unassigned}) => {

    const _handleFunction = async () => {
      if (unassigned) {
        _setNoteColor(color);
        setShowColorSelect(false);
      } else {
      await apis.setNoteColor(userId, note).then(res => {
        _setNoteColor(color);
        setShowColorSelect(false);
      })
      }
    };

    return (
      <Pressable style={{ flex: 1, backgroundColor: color, borderWidth: 3, borderColor: 'white', minWidth: 40, maxWidth: 45, minHeight: 40, maxHeight: 40, borderRadius: 20 }} onPress={() => _handleFunction()} />
    )
  }


  const mouseDownHandler = (event) => {
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
  }

  function _setNoteColor(color) {
    setCurrentStickyNote({ ...currentStickyNote, color: color })
  }

  async function _handleCheck() {
    if (!checked) {
      await apis.markNoteComplete(userId, currentStickyNote).then(() => {
        setCurrentStickyNote({ ...currentStickyNote, completed: true });
        dispatch({ type: MARK_NOTE_COMPLETE, payload: currentStickyNote })
        setChecked(true);
        refetch()
      })
    }
  }

  async function _handleDeleteNote() {
    await apis.removeStickyNote(userId, note?._id).then(() => {
      dispatch({ type: REMOVE_STICKY_NOTE, payload: currentStickyNote?._id });
      refetch()
    })
  }

  async function _handleModifyNote(value) {
    console.log('value', value);
    if(unassigned){
    return setCurrentStickyNote({ ...currentStickyNote, content: value });
    }
    if (currentStickyNote?.content === note?.content && currentStickyNote.color === note.color) {
      return
    }
    if(inputCallback){
      inputCallback()
    } 
    else {
      await apis.modifyStickyNote(currentStickyNote).then(res => {
      if (res.data.success) {
        dispatch({ type: CHANGE_STICKY_NOTE, payload: currentStickyNote })
      }
    })
  }
  }

  async function _handleAddNote() {
    await apis.addStickyNote(currentStickyNote).then(res => {
      if (res.data.success) {
        console.log('added note', res.data);
        dispatch({ type: ADD_STICKY_NOTE, payload: currentStickyNote })
      }
    })
  }

  return (
    <View style={{ flexDirection: 'column', maxHeight: Platform.OS === 'web' ? (unbounded ? undefined : 400) : 200, minWidth: unbounded ? "90%" : 300, maxWidth: unbounded ? undefined : 350, margin: 5, flex: 4, backgroundColor: currentStickyNote.color ? currentStickyNote.color : 'yellow', elevation: 1, shadowRadius: 5, shadowOpacity: .4, shadowOffset: { height: 4, width: 4 } }} >
      <View style={{ padding: 25, flex: 1, flexDirection: 'row' }} >
        <ScrollView scrollEnabled={false} style={{ flex: 2, minWidth: '90%' }}>
         <TempScreen initialContentHTML={note?.content} onFocus={()=>console.log('focused')} noBackground ref={stickyNoteRef} onChange={_handleModifyNote} placeHolder="Add a sticky note" />
        </ScrollView>
        <View style={{ flex: 0.7, flexDirection: 'column', gap: 15, zIndex: 5, minWidth: 50, minHeight: 150, right: -20 }}>
          <TouchableOpacity style={{ left: 5, flex: 1, maxHeight: 30, borderRadius: 20, borderColor: 'white', borderWidth: 3, maxWidth: 20, minHeight: 20, maxHeight: 20 }} onPress={(ev) => { setColorSelect(ev.currentTarget) }} />
          {
            Platform.OS === 'web' && !isStuck &&
            <Pressable style={{ marginHorizontal: 5, flex: 1, maxHeight: 30 }}
              onPress={async () => {
                if (unassigned) {
                  _handleAddNote().then(() => {
                    dispatch({ type: PIN_NOTE, payload: currentStickyNote })
                  })
                } else {
                  dispatch({ type: PIN_NOTE, payload: currentStickyNote });
                }
              }}>
              <Entypo name={"pin"} size={20} color={'rgba(0,0,0,.8)'} />
            </Pressable>
          }
          {
            !unassigned ?
            <>
          <Pressable style={{ marginHorizontal: 5, flex: 1, maxHeight: 30 }} onPress={() => _handleDeleteNote()}>
            <FontAwesome5 name={"trash-alt"} size={20} color={'rgba(0,0,0,.8)'}  ></FontAwesome5>
          </Pressable>
                <Checkbox style={{ flex: 1, margin: 5, maxHeight: 20 }} value={checked} onChange={() => _handleCheck()} />
            </>
              :
              <Pressable style={{ marginHorizontal: 5, flex: 1, maxHeight: 30 }} onPress={() => _handleAddNote()}>
                <FontAwesome5 name={"save"} size={20} color={'rgba(0,0,0,.8)'}  ></FontAwesome5>
              </Pressable>
          }
        </View>
      </View>
      <LinearGradient
        // Background Linear Gradient
        colors={['transparent', decreaseBrightness(currentStickyNote.color ? currentStickyNote.color : '#FFFF00')]}
        style={{flex: 0.1, position:"static", minHeight: 35, zIndex: 100, flexDirection: 'row', opacity: Platform.OS != 'web' ? 0.3 : 1 }}
      />
      <Popover
        open={!!colorSelect}
        anchorEl={colorSelect}
        onClose={() => setColorSelect(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ borderRadius: 20 }}
        style={{ padding: 10 }}
      >
        <FlatList data={colors} renderItem={({ item }) => { return (<ColorSelect unassigned={unassigned} color={item} selectedNote={undefined} />) }} horizontal scrollEnabled={false} contentContainerStyle={{ padding: 10, flexWrap: 'wrap', gap: 10, flexDirection: "row" }} />
      </Popover>
    </View>
  )
}
export default RenderNote;