import React, { useState, useEffect } from 'react';
import { StyleSheet, TextProps, View, ViewProps } from 'react-native';
import { Label, Text } from 'tamagui';
import { LinearGradient } from 'expo-linear-gradient';
import * as Animatable from 'react-native-animatable';
import { Animated } from 'react-native';

interface PercentageBarProps {
  title?: string;
  navigation?: any;
  height?: number;
  backgroundColor?: any;
  percentage: number;
  completedColor: any;
  style?: any;
  limit?: number;
  dataCount?: number | String;
  customTextStyle?: TextProps;
}

const PercentageBar = ({
  navigation,
  dataCount,
  percentage,
  limit,
  customTextStyle,
  height = 20, // Default height if not provided
  backgroundColor = '#1E90FF', // Default background color
  completedColor = 'red', // Default completed color
  title,
  style,
}: PercentageBarProps) => {
  const [getPercentage, setPercentage] = useState(0); // Initialize with 0
  const [getHeight, setHeight] = useState(height);
  const [getBackgroundColor, setBackgroundColor] = useState(backgroundColor);
  const [getCompletedColor, setCompletedColor] = useState(completedColor);

  const animatedValue = useState(new Animated.Value(0))[0]; // Animated value for color interpolation

  useEffect(() => {
    // Animate the percentage from 0 to the new value
    let start = 0;
    const end = parseInt(percentage.toString().replace('%', ''), 10);
    if (start === end) return;

    const incrementTime = (0.5 / end) * 500;

    let timer = setInterval(() => {
      start += 1;
      setPercentage(start);
      if (start === end) clearInterval(timer);
    }, incrementTime);

    // Interpolate color based on the percentage
    Animated.timing(animatedValue, {
      toValue: end,
      duration: 1000, // Duration of the color change
      useNativeDriver: false, // No need for native driver here since we're changing color
    }).start();

    return () => clearInterval(timer);
  }, [percentage]);

  // Interpolate the color based on the percentage
  const backgroundColorInterpolation = animatedValue.interpolate({
    inputRange: [0, 80, 100],
    outputRange: ['green', 'yellow', `${completedColor || "red"}`], // Gradual color change
  });

  return (
    <View style={style}>
      <Label>{title} - {dataCount} {dataCount && limit ? "/" : ""} {limit}</Label>
      <View style={{ justifyContent: 'center', top: 2, flex: 1 }}>
        <Animatable.View
          transition="width" // Animate width
          style={{
            width: `${getPercentage}%`, // Animated width
            height: getHeight,
            bottom: 10, borderRadius: 10,
            padding: 5,
            overflow: "hidden",
            backgroundColor: backgroundColorInterpolation, // Use interpolated color
          }}
        >
          <LinearGradient colors={['white', '#ffffff00']} style={{ position: "absolute", minHeight: getHeight, marginHorizontal: "-30%", left: "20%", alignSelf: "stretch", width: "130%" }} />
          {
            parseInt(percentage.toString().replace('%', '')) > 10 &&
            <Text
              style={{
                  minHeight: 30,
                  textShadowColor: 'black',
                  textShadowOffset: { width: 1, height: 1 },
                textAlign: 'right',
                flex: 1,
                  top: -5,
                fontSize: 18,
                fontWeight: "bold",
                color: '#333333',
                  textShadow: '1px 1px 0px black',
                flexWrap: 'nowrap',
                  overflow: 'visible',
              }}
              numberOfLines={1}
            >
              {getPercentage}%
            </Text>
          }
        </Animatable.View>
      </View>
      {
        parseInt(percentage.toString().replace('%', '')) <= 10 &&
        <Text
          adjustsFontSizeToFit
          style={{
            textAlign: 'right',
            flex: 1, position: 'absolute',
            fontSize: 18,
            fontWeight: "bold",
            flexWrap: 'nowrap',
            overflow: 'visible',
            top: 25,
            right: -1,
            ...customTextStyle
          }}
          numberOfLines={1}
        >
          {getPercentage}%
        </Text>
      }
    </View>
  );
};

export default PercentageBar;
