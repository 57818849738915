import React, { useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { SafeAreaView, Platform, Text, View, Linking, AppState, Dimensions, Animated } from 'react-native';
import useCachedResources from '@hooks/useCachedResources';
// import useColorScheme from '@hooks/useColorScheme';
import Navigation from './src/navigation';
import { Provider } from 'react-redux';
import { store, persistor } from '@reduxLocal/persistState';
import { PersistGate } from 'redux-persist/integration/react';
import "./i18n.config";
import { QueryClient, QueryClientProvider } from 'react-query';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import '@tensorflow/tfjs-react-native';
import LottieView from '@utils/lottieAnimation';
import { useFonts } from 'expo-font';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SafeAreaContext, SafeAreaFrameContext, SafeAreaProvider } from 'react-native-safe-area-context';
import { PortalProvider } from '@tamagui/portal'

export const ColorSchemeContext = createContext({
  colorScheme: 'light', // default value
  setColorScheme: (scheme) => { },
});

export const useColorScheme = () => React.useContext(ColorSchemeContext);

// Helper functions for service worker and notifications
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register(new URL('@utils/service-worker.js', import.meta.url));

        if (registration.waiting) {
          await checkForBytecodeUpdate(registration);
        }

        registration.addEventListener('updatefound', () => {
          registration.installing.addEventListener('statechange', async () => {
            if (registration.waiting && registration.waiting.state === 'installed') {
              await checkForBytecodeUpdate(registration);
            }
          });
        });
      } catch (err) {
        console.error('ServiceWorker registration failed: ', err);
      }
    });
  }
};

const checkForBytecodeUpdate = async (registration) => {
  try {
    const response = await fetch('/bytecode');
    const latestBytecode = await response.text();
    const currentBytecode = await registration.installing.scriptURL;

    if (latestBytecode !== currentBytecode) {
      await registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  } catch (error) {
    console.error('Error checking for bytecode update: ', error);
  }
};



const sendNotification = () => {
  const text = "Take a look at this brand new t-shirt!";
  const title = "New Product Available";
  const options = {
    body: text,
    vibrate: [200, 100, 200],
    tag: "new-product",
    actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
  };
  navigator.serviceWorker.ready.then((serviceWorker) => {
    serviceWorker.showNotification(title, options);
  }).catch((error) => {
    console.error('Notification error: ', error);
  });
};

const Container = (({ showUpdateDetail }: { showUpdateDetail: boolean }) => {
  const [colorScheme, setColorScheme] = useState("light");

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);
    handleChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [colorScheme]);

  return (
    <ColorSchemeContext.Provider value={{ colorScheme, setColorScheme }}>
      <Navigation colorScheme={colorScheme} hasUpdatePending={showUpdateDetail} />
    </ColorSchemeContext.Provider>
  );
});

const queryClient = new QueryClient();

const compareAppVersion = async (version) => {
  try {
    const persistedState = localStorage.getItem('persist:root');

    if (persistedState === null) {
      localStorage.setItem('appVersion', version);
      return true;
    } else {
      const appVersion = JSON.parse(JSON.parse(persistedState)?.app)?.appVersion;
      if (appVersion === null || appVersion !== version) {
        document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        clearCache();
        localStorage.setItem('appVersion', version);
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error('Error comparing app version: ', error);
    throw error;
  }
};

const clearCache = () => {
  localStorage.clear();
};

export default function App() {
  const [updated, setUpdated] = useState(false);
  const [fontsLoaded, fontsError] = useFonts({
    Inter: require("@tamagui/font-inter/otf/Inter-Medium.otf"),
    InterBold: require('@tamagui/font-inter/otf/Inter-Bold.otf'),
    'Montserrat': require('@assets/fonts/Montserrat-Light.ttf'),
  });

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const updated = await compareAppVersion(process?.env.REACT_APP_VERSION);
        if (updated) {
          setUpdated(true);
        }
      } catch (error) {
        console.error('Error initializing app: ', error);
      }
    };

    initializeApp();

    if (Platform.OS === 'web') {
      if ("Notification" in window) {
        registerServiceWorker();
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            sendNotification();
          }
        });
      }
    }

    const handleDeepLink = (event) => {
      console.log('Received deep link:', event.url);
    };

    const handleAppStateChange = (newState) => {
      if (newState === 'active') {
        Linking.getInitialURL().then((url) => {
          if (url) {
            handleDeepLink({ url });
          }
        });
      }
    };

    const urlSub = Linking.addEventListener('url', handleDeepLink);
    // const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      urlSub.remove();
      // subscription.remove();
      clearWebCache();
    };
  }, []);

  const clearWebCache = () => {
    if (Platform.OS === 'web') {
      // Clear browser LocalStorage
      localStorage.clear();
      // Clear browser SessionStorage
      sessionStorage.clear();
      console.log('Web cache cleared!');
    }
  };

  const theme = useMemo(() => ({
    ...DefaultTheme,
    roundness: 2,
    colors: {
      ...DefaultTheme.colors,
      primary: 'rgb(90,192,234)',
      accent: '#f1c40f',
    },
  }), []);

    return (
      <SafeAreaProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <PaperProvider theme={theme}>
        <PortalProvider shouldAddRootHost>
              <Container showUpdateDetail={updated} />
        </PortalProvider>
            </PaperProvider>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
      </LocalizationProvider>
      </SafeAreaProvider>
    );
}
