import { stack } from '@navigation/rootNavigation';
import { Trash as MobileTrash, HomeIcon as MobileHomeIcon, DataLaptop as MobileDataLaptop, MobileUsersIcon, SearchIcon as MobileSearchIcon, MobileProjectIcon, MobileCalendarIcon, MobileReportIcon, MobileSettingsIcon, MobileIntegrationIcon, SalesIcon } from '@assets/--native/index';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { Platform } from 'react-native';

//Icon must be created in Lottie for Web and SVGR for mobile.
//Settings for SVGR include Icon, React Native and TypeScript with SVGO enabled

const superAdmin = [
	{
		menuType: 'empty',
		menuLabel: ' ',
		menu: [{
			id: 0,
			primary: true,
			header: 'menuWelcome',
			screen: 'Welcome',
			icon: require('@assets/animations/LordIcons/41-home-solid-edited.json'),
			mobileIcon: <MobileHomeIcon />,
			secondaryMenu: {
				active: false,
			},
		}]
	},
	{
		menuType: 'default',
		menuLabel: 'Management',
		menu: [{
			id: 1,
			primary: true,
			header: 'menuWelcome',
			screen: 'Welcome',
			icon: require('@assets/animations/LordIcons/41-home-solid-edited.json'),
			mobileIcon: <MobileHomeIcon />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 2,
			primary: true,
			header: 'menuData',
			screen: 'Data',
			default: 1,
			icon: require('@assets/animations/LordIcons/49-upload-file-solid-edited.json'),
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 20,
						parent: 'Data',
						screen: 'DataFavourites',
						header: 'dataFavourite',
						icon: Platform.OS != 'web' && require('@assets/animations/star.json'),
						path: 'star.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 21,
						parent: 'Data',
						screen: 'DataMenu',
						header: 'Browse',
						path: 'magnifExample.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 23,
						parent: 'Data',
						screen: 'CreateData',
						header: 'dataCreate',
						icon: Platform.OS != 'web' && require('@assets/animations/18332-create-a-shot-list.json'),
						path: '18332-create-a-shot-list.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 25,
						parent: 'Data',
						screen: 'DataTemplates',
						header: 'dataTemplates',
						icon: Platform.OS != 'web' && require('@assets/animations/42404-add-document.json'),
						path: '42404-add-document.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 24,
						parent: 'Data',
						screen: 'PublicData',
						header: 'dataPublic',
						icon: Platform.OS != 'web' && require('@assets/animations/public_dataset.json'),
						path: 'public_dataset.json',
						mobileIcon: null,
						state: false
					},

					{
						id: 22,
						parent: 'Data',
						screen: 'Upload',
						header: 'dataUpload',
						icon: Platform.OS != 'web' && require('@assets/animations/upload.json'),
						path: 'upload.json',
						mobileIcon: null,
						state: false,
						scale: 2
					},
				],
			},
		},
		{
			id: 3,
			primary: true,
			header: 'menuSales',
			screen: 'Sales',
			default: 0,
			icon: require('@assets/animations/LordIcons/178-work-solid-edited.json'),
			mobileIcon: <SalesIcon />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 31,
						parent: 'Sales',
						screen: 'SalesMenu',
						header: 'Sales Dashboard',
						icon: Platform.OS != 'web' && require('@assets/animations/star.json'),
						path: 'star.json',
						mobileIcon: null,
						state: false
					},
					{
						id: 32,
						parent: 'Sales',
						screen: 'ActiveCampaigns',
						header: 'Active',
						icon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						path: '105897-saved-folder.json',
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						state: false,
					},
					{
						id: 33,
						parent: 'Sales',
						screen: 'PreviousCampaigns',
						header: 'Expired',
						icon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						path: '105897-saved-folder.json',
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						state: false
					},
					{
						id: 34,
						parent: 'Forecast',
						screen: 'Forecast',
						header: 'Forecast',
						icon: Platform.OS != 'web' && require('@assets/animations/18841-bar-graph.json'),
						path: '105897-saved-folder.json',
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						state: false
					}
				]
			},
		},
		{
			id: 4,
			primary: true,
			default: 1,
			header: 'menuProjectManagement',
			screen: 'ProjectManagement',
			stack: "ProjectManagement",
			icon: require('@assets/animations/LordIcons/17-assignment-solid-edited.json'),
			mobileIcon: <MobileProjectIcon />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 40,
						parent: 'ProjectManagement',
						screen: 'ProjectFavourites',
						header: 'projectFavourites',
						icon: Platform.OS != 'web' && require('@assets/animations/star.json'),
						path: 'star.json',
						mobileIcon: null,
						state: false
					},
					{
						id: 41,
						parent: 'ProjectManagement',
						screen: 'ActiveProjects',
						header: 'projectActive',
						icon: Platform.OS != 'web' && require('@assets/animations/93690-completed-task-files.json'),
						path: '93690-completed-task-files.json',
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/93690-completed-task-files.json'),
						state: false,
					},
					{
						id: 42,
						parent: 'ProjectManagement',
						screen: 'ActiveProjects',
						header: 'projectCompleted',
						icon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						path: '105897-saved-folder.json', //
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						state: false,
					},
					{
						id: 43,
						parent: 'ProjectManagement',
						screen: 'Templates',
						header: 'projectTemplates',
						icon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						path: '105897-saved-folder.json',
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/105897-saved-folder.json'),
						state: false,
					},
				],
			},
		},
		{
			id: 5,
			primary: true,
			header: 'menuOrganiser',
			screen: 'Calendar',
			default: 0,
			icon: require('@assets/animations/LordIcons/23-calendar-solid-edited.json'),
			mobileIcon: <MobileCalendarIcon />,
			secondaryMenu: {
				active: true,
				content: [
					{
						parent: 'Calendar',
						screen: '',
						header: 'Calendar',
						icon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						state: false,
						path: '101700-calendar-animation.json',
						scale: 2
					},
					{
						parent: 'Calendar',
						screen: 'Reminders',
						header: 'Reminders',
						icon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						state: false,
						path: '101700-calendar-animation.json',
						scale: 2
					},
					{
						parent: 'Calendar',
						screen: 'Meetings',
						header: 'Meetings',
						icon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/101700-calendar-animation.json'),
						state: false,
						path: '101700-calendar-animation.json',
						scale: 2
					},
				],
			},
		},
		{
			id: 6,
			primary: true,
			header: 'menuReports',
			screen: 'Reports',
			icon: require('@assets/animations/10-analytics-solid-edited.json'),
			mobileIcon: <MobileReportIcon />,
			default: 0,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 61,
						parent: 'Reports',
						screen: 'IndividualReports',
						header: 'reportsIndividualReports',
						icon: Platform.OS != 'web' && require('@assets/animations/builder.json'),
						mobileIcon: null,
						state: false,
						path: 'builder.json'
					},
					{
						id: 62,
						parent: 'Reports',
						screen: 'ReportDashboard',
						header: 'reportsReportDashboard',
						icon: Platform.OS != 'web' && require('@assets/animations/builder.json'),
						mobileIcon: null,
						state: false,
						path: 'builder.json'
					},
				],
			},
		},
		{
			id: 7,
			primary: true,
			header: 'menuCompany',
			stack: 'Users',
			screen: 'Users',
			icon: require('@assets/animations/LordIcons/groups.json'),
			mobileIcon: <MobileUsersIcon />,
			default: 0,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 71,
						parent: 'Users',
						screen: 'UsersManagement',
						header: 'usersManageUser',
						icon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						state: false,
						path: '130755-switch-user-cards.json'
					},
					{
						id: 72,
						parent: 'Users',
						screen: "StartupBuilder",
						header: "Startup Builder",
						icon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						state: false,
						path: '130755-switch-user-cards.json'
					},
					{
						id: 73,
						parent: 'Users',
						screen: 'UserActivity',
						header: 'userUserActivity',
						icon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						mobileIcon: Platform.OS != 'web' && require('@assets/animations/130755-switch-user-cards.json'),
						state: false,
						path: '130755-switch-user-cards.json'
					},

				],
			},
		},
		{
			id: 8,
			primary: true,
			header: "menuKnowledge",
			screen: 'Knowledge',
			stack: "Knowledge",
			default: 0,
			icon: require('@assets/animations/LordIcons/19-book-solid-edited (1).json'),
			mobileIcon: <FontAwesome name="book" size={24} color="white" />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 81,
						parent: 'Knowledge',
						screen: 'InternalKnowledge',
						header: 'Internal Knowledge',
						icon: require('@assets/animations/LordIcons/19-book-solid-edited (1).json'),
						mobileIcon: null,
						state: false,
					},
					{
						id: 82,
						parent: 'Knowledge',
						screen: 'ExternalKnowledge',
						header: 'External Knowledge',
						icon: require('@assets/animations/LordIcons/19-book-solid-edited (1).json'),
						mobileIcon: null,
						state: false,
					}
				],
			},
		},
		{
			id: 9,
			primary: true,
			header: 'menuIntegrations',
			screen: 'Integrations',
			icon: require('@assets/animations/LordIcons/40-add-card-solid-edited.json'),
			mobileIcon: <MobileIntegrationIcon />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: '@assets/animations/LordIcons/17-assignment-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 10,
			default: 0,
			primary: true,
			header: 'menuNetworking',
			screen: 'Networking',
			icon: require('@assets/animations/wired-flat-27-globe.json'),
			mobileIcon: <FontAwesome name="globe" size={24} color="white" />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 101,
						parent: 'Networking',
						screen: 'Map',
						header: 'Map',
						icon: null,
						mobileIcon: null,
						state: false,
					},
					{
						id: 102,
						parent: 'Networking',
						screen: 'Search',
						header: 'Search',
						icon: null,
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 11,
			default: 0,
			primary: true,
			header: 'menuSettings',
			screen: 'Settings',
			icon: require('@assets/animations/LordIcons/63-settings-cog-solid-edited.json'),
			mobileIcon: <MobileSettingsIcon />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 111,
						parent: 'Settings',
						screen: 'Profile',
						header: 'settingsProfile',
						icon: Platform.OS != 'web' && require('@assets/animations/132017-profile.json'),
						path: '9lbSBNLFTg.json',
						mobileIcon: null,
					},
					// {
					// 	id: 112,
					// 	parent: 'Settings',
					// 	screen: 'Achievements',
					// 	header: 'Trophies',
					// 	icon: Platform.OS != 'web' && require('@assets/animations/111843-winner.json'),
					// 	mobileIcon: require('@assets/animations/2837-trophy-animation.json'),
					// 	path: '107653-trophy.json'
					// },
					{
						id: 113,
						parent: 'Settings',
						screen: 'Statistics',
						header: 'settingsStats',
						icon: Platform.OS != 'web' && require('@assets/animations/93777-chart-graph.json'),
						mobileIcon: require('@assets/animations/2837-trophy-animation.json'),
						path: '18841-bar-graph.json'
					},
					// {
					// 	id: 114,
					// 	parent: 'Settings',
					// 	screen: 'Company',
					// 	header: 'settingsCompany',
					// 	icon: Platform.OS != 'web' && require('@assets/animations/86442-company.json'),
					// 	mobileIcon: require('@assets/animations/41180-customer-service-support-agent-animation.json'),
					// 	path: '40272-business-app-animation.json'
					// },
					{
						id: 115,
						parent: 'Settings',
						screen: 'Upgrade',
						header: 'menuUpgrade',
						icon: Platform.OS != 'web' && require('@assets/animations/111742-diamond-upgrade-reward-animation.json'),
						mobileIcon: require('@assets/animations/18779-lightning-bolt.json'),
						path: '91620-jumping-lottie-animation.json'
					},
					{
						id: 116,
						parent: 'Settings',
						screen: 'Affiliate',
						header: 'Affiliate',
						icon: Platform.OS != 'web' && require('@assets/animations/111742-diamond-upgrade-reward-animation.json'),
						mobileIcon: require('@assets/animations/18779-lightning-bolt.json'),
						path: '91620-jumping-lottie-animation.json'
					},
					// {
					// 	id: 85,
					// 	parent: 'Settings',
					// 	screen: 'AppSettings',
					// 	header: 'appSettings',
					// 	icon: require('@assets/animations/110200-mobile-setting.json'),
					// 	mobileIcon: null,
					// 	path:'settingsSpin.json'
					// },
				],
			},
		},
		{
			id: 12,
			parent: "Academy",
			screen: 'Academy',
			header: 'Academy',
			primary: false,
			secondaryMenu: {
				active: true,
				content: [
					{
						parent: 'Academy',
						screen: 'Academy',
						header: 'Academy',
						icon: require('@assets/animations/LordIcons/63-settings-cog-solid-edited.json'),
						mobileIcon: <MobileSettingsIcon />,
						state: false,
					},
				]
			}
		}
		]
	},
	{
		menuType: 'adminOps',
		menuLabel: 'Admin/Operations',
		menu: [
			{
			id: 1,
			primary: true,
			header: 'Data',
			screen: 'Data',
			icon: null,
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: require('@assets/animations/LordIcons/1-share-solid-edited.json'),
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		]
	},
	{
		menuType: 'customerServices',
		menuLabel: 'Customer Services',
		menu: [{
			id: 1,
			primary: true,
			header: 'Data',
			screen: 'Data',
			icon: null,
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: require('@assets/animations/LordIcons/1-share-solid-edited.json'),
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		]
	},
	{
		menuType: 'accountingFinance',
		menuLabel: 'Finance',
		menu: [{
			id: 1,
			primary: true,
			header: 'menuSales',
			screen: 'Data',
			icon: require('@assets/animations/LordIcons/41-home-solid-edited.json'),
			mobileIcon: <MobileHomeIcon />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: require('@assets/animations/LordIcons/1-share-solid-edited.json'),
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 2,
			primary: true,
			header: 'Invoices',
			screen: 'Data',
			icon: require('@assets/animations/LordIcons/49-upload-file-solid-edited.json'),
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 21,
						parent: 'Data',
						screen: 'Find',
						header: 'dataFindData',
						icon: '@assets/animations/LordIcons/42-search-solid-edited_white.json',
						mobileIcon: <MobileSearchIcon />,
						state: false,
					},
					{
						id: 23,
						parent: 'Data',
						screen: 'Upload',
						header: 'dataUploadData',
						icon: '@assets/animations/LordIcons/49-upload-file-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 24,
						parent: 'Data',
						screen: 'CreateData',
						header: 'dataCreateData',
						icon: '@assets/animations/LordIcons/addData.json',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 3,
			primary: false,
			header: 'Reports',
			screen: 'MailLoginScreen',
			icon: '@assets/animations/120976-e-mail-worldwide-delivery.json',
			mobileIcon: null,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 31,
						parent: 'Mail',
						screen: 'MailLoginScreem',
						header: 'Inbox',
						icon: '@assets/animations/120976-e-mail-worldwide-delivery.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 32,
						parent: 'Mail',
						screen: 'MailBin',
						header: 'Bin',
						icon: '@assets/animations/LordIcons/39-trash-solid-edited.json',
						mobileIcon: <MobileTrash />,
						state: false,
					},
					{
						id: 33,
						parent: 'Mail',
						screen: 'MailDrafts',
						header: 'Drafts',
						icon: '@assets/animations/120976-e-mail-worldwide-delivery.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 34,
						parent: 'Mail',
						screen: 'MailImportant',
						header: 'Important',
						icon: '@assets/animations/120976-e-mail-worldwide-delivery.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 35,
						parent: 'Mail',
						screen: 'MailSent',
						header: 'Sent',
						icon: '@assets/animations/LordIcons/1-share-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 36,
						parent: 'Mail',
						screen: 'Spam',
						header: 'Spam',
						icon: '@assets/animations/LordIcons/1-share-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
					{
						id: 37,
						parent: 'Mail',
						screen: 'MailStarred',
						header: 'Starred',
						icon: '@assets/animations/1865-shooting-stars-outline-edited.json',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 4,
			primary: true,
			header: 'Data Sync',
			screen: 'Projects',
			icon: require('@assets/animations/LordIcons/17-assignment-solid-edited.json'),
			mobileIcon: <MobileProjectIcon />,
			secondaryMenu: {
				active: true,
				content: [
					{
						id: 41,
						parent: 'ProjectManagementScreen',
						screen: 'ActiveProjects',
						header: 'Active Projects',
						icon: '@assets/animations/LordIcons/17-assignment-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		{
			id: 5,
			primary: true,
			header: 'Users',
			screen: 'Calendar',
			icon: require('@assets/animations/LordIcons/23-calendar-solid-edited.json'),
			mobileIcon: <MobileCalendarIcon />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: '@assets/animations/LordIcons/17-assignment-solid-edited.json',
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		]
	},
	{
		menuType: 'inventory',
		menuLabel: 'Inventory',
		menu: [{
			id: 1,
			primary: true,
			header: 'Data',
			screen: 'Data',
			icon: null,
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: require('@assets/animations/LordIcons/1-share-solid-edited.json'),
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		]
	},
	{
		menuType: 'marketingSales',
		menuLabel: 'Marketing & Sales',
		menu: [{
			id: 1,
			primary: true,
			header: 'Data',
			screen: 'Data',
			icon: null,
			mobileIcon: <MobileDataLaptop />,
			secondaryMenu: {
				active: false,
				content: [
					{
						parent: '',
						screen: '',
						header: '',
						icon: require('@assets/animations/LordIcons/1-share-solid-edited.json'),
						mobileIcon: null,
						state: false,
					},
				],
			},
		},
		]
	}
];
export default superAdmin;
