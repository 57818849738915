import { View, Image, Animated, useWindowDimensions, Pressable, Easing } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-native-modal';
import { HIDE_HELP_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withAnchorPoint } from '@utils/anchorPoint';
import { AntDesign } from '@expo/vector-icons';
import styles from "@stylesheet";
import { BlurView } from "expo-blur";
import * as Animatable from 'react-native-animatable';
import { GiftedChat, InputToolbarProps, SystemMessage } from "react-native-gifted-chat";
import useGlobalUser from "@hooks/getGlobalUser";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import { debounce, uniqueId } from "lodash";
import { AI_CHAT_API_URL } from "@api/constant";

const HelpModal = ({ visibleState, setAiVisibleState }: { visibleState?: Boolean, setAiVisibleState: (boolean) => void }) => {
  const theme = themeSelector();
  const [searchText, setSearchText] = useState("");
  const rotationValue = useRef(new Animated.Value(0)).current; // Start at 90 degrees
  const rightValue = useRef(new Animated.Value(4)).current;
  const fadeValue = useRef(new Animated.Value(0)).current; // Initial value of the 'right' property
  const [modalDimensions, setModalDimensions] = useState({ width: 500, height: 700 });
  const { height, width } = useWindowDimensions();
  const [flip, setFlip] = useState(false);
  const [selection, setSelection] = useState();
  const [count, setCount] = useState(0);
  const [helpSearch, setHelpSearch] = useState("");
  const [AIHelp, setAIHelp] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatStarted, setChatStarted] = useState(false);
  const { height: screenHeight, width: screnWidth } = useWindowDimensions();
  const globalUser = useGlobalUser();
  const [inputDisabled, disableInput] = useState(false);
  const userData = useSelector((state: DefaultRootState) => state.promiseData.data.userData);
  const dispatch = useDispatch();
  const isMessageOpen = true;
  const toolbarVisible = false;

  const animateRotation = () => {
    Animated.timing(rotationValue, {
      toValue: 90, // End at 0 degrees
      duration: 300, // Animation duration in milliseconds
      useNativeDriver: true, // Use the native driver for performance
    }).start();
  };

  const animateRotationOut = () => {
    Animated.timing(rotationValue, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true
    }).start(() => { setTimeout(() => setModalDimensions({ width: 500, height: 700 }), 1000); setChatStarted(false) });
  }

  const animateFade = () => {
    Animated.timing(fadeValue, {
      toValue: 1,
      duration: 2000,
      useNativeDriver: false
    }).start();
  }

  const animateRightValue = () => {
    const targetRightValue = -40;
    Animated.timing(rightValue, {
      toValue: targetRightValue,
      duration: 400,
      useNativeDriver: false, // Don't use native driver for 'right' property
    }).start();
  };



  useEffect(() => { setCount(count + 1) }, [visibleState])

  const handleHelp = useCallback(() => {
    console.log('do something without refreshing');
  }, []);

  useEffect(() => {
    if (visibleState) {
      animateRotation();
      animateRightValue();
      animateFade();
    }
  }, [isMessageOpen, visibleState]);

  const handleClose = useCallback(() => {
    console.log('requested close');
    animateRotationOut();
  }, []);

  const getTransform = () => {
    let transform = {
      transform: [{
        perspective: 0
      }, {
        rotate: rotationValue.interpolate({
          inputRange: [0, 90],
          outputRange: ['270deg', '360deg'], // Inverted from 90 to 0 degrees
        }),
      }]
    }
    return withAnchorPoint(transform, { x: 0, y: 1 }, { width: modalDimensions.width, height: modalDimensions.height });
  }


  const handleFlip = useCallback((selection) => { setFlip(!flip); if (selection) { setSelection(selection) } }, [])

  return (
    <Modal
      isVisible={visibleState}
      animationIn={'fadeIn'}
      animationOut={'fadeOutDown'}
      onModalWillShow={() => {
        animateRotation();
        animateRightValue();
      }}
      onModalWillHide={() => { animateRotationOut() }}
      onBackdropPress={() => { handleClose(); setTimeout(() => dispatch({ type: HIDE_HELP_MODAL, payload: null }), 600) }}
      statusBarTranslucent
      deviceHeight={height}
      deviceWidth={width}
      hasBackdrop={false}
      coverScreen={false}
    >
      <Animated.View style={[styles.blurViewStyle, { opacity: fadeValue.interpolate({ inputRange: [0, 1], outputRange: [0, 1] }) }]}>
        <BlurView style={[styles.blurViewStyle, { opacity: fadeValue.interpolate({ inputRange: [0, 1], outputRange: [0, 1] }) }]} />
      </Animated.View>
      <Animatable.View
        transition={['right', 'width', 'height']}
        duration={!isMessageOpen ? 500 : 200}
        style={[getTransform(), {
          position: 'absolute',
          height: modalDimensions.height,
          width: modalDimensions.width,
          backgroundColor: 'white',
          borderRadius: 30,
          borderBottomLeftRadius: 0,
          borderWidth: 10,
          borderColor: 'rgb(90,192,234)',
          right: -610,
          bottom: 80,
          overflow: 'visible'
        }]}
      >
        <Pressable style={{ left: -15, top: -15, position: 'absolute', backgroundColor: 'red', borderRadius: 100, width: 30, height: 30, zIndex: 1, shadowRadius: 40, shadowOpacity: 0.6 }} onPress={() => { setAiVisibleState(!visibleState) }}>
          <AntDesign name="close" size={24} color="white" style={[styles.centerEverything, { padding: 3 }]} />
        </Pressable>
      </Animatable.View>
    </Modal >
  );
};


export default HelpModal;
