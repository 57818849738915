import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, Pressable, TextInput, StyleSheet, ScrollView, Platform } from 'react-native';
import { EvilIcons, AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import uniqueId from 'lodash/uniqueId';
import { TextField, TextFieldProps } from '@mui/material';
import { SizableText } from 'tamagui';

interface TagInputProps {
  placeHolder: string,
  tags: string[],
  setTags: (tags: string[]) => void,
  enabled?: boolean,
  initialTags?: string[],
  disable?: boolean,
  label?: string,
  disableInput?: boolean
}

const toCapitalCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const TagComponent = React.memo(({ text, cullTagFromTags, disableRemove }: { text: string, cullTagFromTags: (text: string) => void, disableRemove?: boolean }) => {
  const minWidth = text.length * 10;
  return (
    <View style={[styles.tagContainer]}>
      <SizableText numberOfLines={1} style={[styles.tagText, { minWidth }]}>{text}</SizableText>
      {!disableRemove && (
        <Pressable onPress={() => cullTagFromTags(text)}>
          <EvilIcons name="trash" size={24} color="white" />
        </Pressable>
      )}
    </View>
  );
});

const TagInput: React.FC<TagInputProps> = ({ disable, label, initialTags = [], disableInput, placeHolder, tags, setTags, enabled = true }) => {
  const inputRef = useRef<TextFieldProps>(null);
  const [inputValue, setInputValue] = useState('');
  const [blurred, setBlurred] = useState(false);
  const theme = themeSelector();

  const inputValueChangeHandler = useCallback((inputChange: string) => {
    setInputValue(inputChange);
    if (inputChange.endsWith(' ') && inputValue.trim() !== '') {
      const capitalizedInput = inputChange
        .trim()
        .split(' ')
        .map(word => toCapitalCase(word))
        .join(' ');
      setTags([...tags, capitalizedInput]);
      setInputValue('');
    }
  }, [inputValue, tags, setTags]);

  const cullTagFromTags = useCallback((tag: string) => {
    setTags(tags.filter(element => element !== tag));
  }, [tags, setTags]);

  return (
    <View style={{ flex: 1, alignSelf: "stretch", minHeight: 80, maxWidth: Platform.select({ web: 600, default: "100%" }), marginVertical: 10 }}>
      {
        !disableInput &&
      <TextField
        editable={enabled.toString()}
        fullWidth
          style={{ flex: 1, alignSelf: "stretch", minHeight: 40, opacity: blurred ? 0.2 : 1, maxHeight: blurred ? 10 : 70, transition: 'max-height 0.5s' }}
          label={toCapitalCase(label || "Tags, seperated by spaces") || "Tags, seperated by spaces"}
          onBlur={() => setBlurred(true)}
          onFocus={() => setBlurred(false)}
        ref={inputRef}
        value={inputValue}
        onChange={(ev) => inputValueChangeHandler(ev.target.value)}
          placeholder={placeHolder || "Tags, seperated by spaces"}
      />
      }
      {tags?.length > 0 && (
        <View style={[styles.tagsContainer, { backgroundColor: "white" }]}>
          <View style={[styles.scrollContainer, { gap: 10 }]}>
            <Animatable.View animation='fadeInLeft' style={[styles.iconContainer, {minWidth: 60}]}>
              <AntDesign name="tags" size={36} color="rgb(90,192,234)" />
            </Animatable.View>
            <ScrollView
              style={{ flex: 1, maxWidth: '100%' }}
              showsHorizontalScrollIndicator
              horizontal
              contentContainerStyle={[styles.scrollContent]}>
              {tags.map((tag, index) => (
                <TagComponent
                  disableRemove={disable}
                  text={toCapitalCase(tag)}
                  cullTagFromTags={cullTagFromTags}
                  key={uniqueId(tag + index)}
                />
              ))}
            </ScrollView>
          </View>
      </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    minHeight: 120,
    maxWidth: 350,
    marginBottom: 30,
  },
  inputOutline: {
    flex: 1,
    borderRadius: 5,
    minWidth: '90%',
    padding: 10,
  },
  tagsContainer: {
    flex: 5,
    flexDirection: 'row',
    gap: 20,
    minHeight: 50,
    maxHeight: 55,
    left: -20
  },
  scrollContainer: {
    flex: 1,
    gap: 20,
    flexDirection: 'row',
    maxHeight: 55,
    minHeight: 50,
  },
  iconContainer: {
    flex: 1,
    maxWidth: 35,
  },
  scrollContent: {
    gap: 10,
  },
  tagContainer: {
    gap: 10,
    justifyContent: 'space-between',
    backgroundColor: 'grey',
    borderRadius: 20,
    flexDirection: 'row',
    maxHeight: 40,
    paddingHorizontal: 10,
    marginHorizontal: 5,
  },
  tagText: {
    fontSize: 16,
    color: "white",
    fontWeight: '600',
    marginHorizontal: 30,
    paddingHorizontal: 10,
    textAlign: "center",
  },
});

export default TagInput;
