import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { View, Text, BlurView } from '@constants/Themed';
import { GestureHandlerGestureEvent, PanGestureHandler, State } from 'react-native-gesture-handler';
import Animated, { useSharedValue, withSpring, useAnimatedStyle, useAnimatedGestureHandler, withTiming } from 'react-native-reanimated';
import * as Animatable from 'react-native-animatable';
import { Modal, StyleSheet, Platform, Pressable, ViewStyle } from 'react-native';

import AnimatedTextFunction from '@hooks/AnimateTextEntry';
import LottieView from '@utils/lottieAnimation';
import { AntDesign, Entypo, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import YoutubePlayer from 'react-native-youtube-iframe';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDeviceType } from '@hooks/useDeviceType';

interface HelpRobotProps {
  textHelp: string[];
  dismissCallback?: () => void;
  removeSwipe?: boolean;
  videoId?: string;
  style?: ViewStyle;
  showByDefault?: boolean;
}

export interface RobotHandle {
  showHelp: () => void;
}

const SwipeableRobot = forwardRef<RobotHandle, HelpRobotProps>((props, ref) => {
  const { textHelp, dismissCallback, removeSwipe, style } = props;
  const [videoActive, setVideoActive] = useState(false);
  const globalRobot = false;
  const [help, setHelp] = useState(props.showByDefault || globalRobot);
  const translateX = useSharedValue(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const panRef = useRef<GestureHandlerGestureEvent>();
  const theme = themeSelector();
  const animVidRef = useRef<Animatable.AnimatableAnimationMethods>(null);
  const dispatch = useDispatch();
  const { isTablet } = useDeviceType();

  useImperativeHandle(ref, () => ({
    showHelp: () => {
      handleShowHelp();
    }
  }), [help])


  const handlePanGesture = ({ nativeEvent }) => {
    const { translationX, state } = nativeEvent;
    if (state === State.ACTIVE) {
      translateX.value = translationX;
    }
    if (state === State.END) {
      if (translateX.value < -150) {
        translateX.value = withSpring(0);
        setHelp(false);
        dismissCallback?.();
        dispatch({ type: "HIDE_ROBOT" })
        // Handle your logic when translationX is less than -1
      } else if (translateX.value < -1) {
        translateX.value = withSpring(0);
      }
      else if (translateX.value > 1) { translateX.value = withSpring(0); }
    }
  };

  const handleShowHelp = async () => {
    if (!help) {
      setHelp(true);
    }
    if (animVidRef.current) {
      await animVidRef.current?.fadeOutUp(300).then(() => {
        setTimeout(() => setCurrentIndex(currentIndex + 1 > textHelp.length - 1 ? 0 : currentIndex + 1), 100);
      }).finally(() => {
        animVidRef.current?.fadeInUp(300);
      });
    } else {
      setTimeout(() => setCurrentIndex(currentIndex + 1 > textHelp.length - 1 ? 0 : currentIndex + 1), 100);
    }
  }

  const getText = () => {
    return textHelp[currentIndex];
  }


  return help && (
    <>
      {
        removeSwipe ?
          <Animatable.View animation={'slideInLeft'} duration={300} style={[{ flex: 1, borderRadius: 20, shadowRadius: 20, shadowOpacity: .2, minWidth: '90%', }, style]}>
            <BlurView style={{ flex: 1, minWidth: 400, maxHeight: "30%", minHeight: 180, borderRadius: 20 }}>
              <Pressable onPress={() => handleShowHelp()} style={{ flexDirection: 'row', gap: 35 }}>
                <LottieView source={require('@assets/animations/automation_robot.json')} style={{ flex: 1, width: '60%', alignSelf: isTablet ? 'center' : 'flex-end', maxHeight: 150 }} loop autoPlay />
                <Animatable.View style={{ flex: 3, width: 400, top: 20, left: -30, height: 40, backgroundColor: 'white', borderWidth: 3, padding: 20, borderColor: 'rgb(90,192,234)', borderRadius: 20, borderBottomStartRadius: 0, justifyContent: 'center', paddingVertical: 30 }}>
                  <AnimatedTextFunction key={currentIndex + 'animatedHelpText'} fontColor='black' value={getText()} fontSize={14} />
                </Animatable.View>
              </Pressable>
            </BlurView>
          </Animatable.View >
          :
          <PanGestureHandler ref={panRef} onGestureEvent={handlePanGesture} onHandlerStateChange={handlePanGesture}>
            <Animated.View style={{ transform: [{ scale: Platform.select({ web: 1, default: isTablet ? 1 : 0.7 }) }, { translateX: translateX }], maxHeight: 80, width: Platform.select({ web: 650, default: 400 }), borderRadius: 20 }}>
              <Animatable.View animation={'slideInLeft'} duration={300} style={{ borderRadius: 20, shadowRadius: 20, shadowOpacity: .2, minWidth: Platform.select({ default: '100%', ios: "70%" }), position: 'relative', top: Platform.select({ default: -170, ios: -300 }), maxWidth: Platform.select({ ios: "80%", android: "80%" }), left: Platform.select({ web: 10, ios: -100 }) }}>
                <BlurView style={{ flex: 1, minWidth: 550, maxHeight: "30%", minHeight: 180, borderRadius: 20 }}>
                  {
                    props.videoId &&
                    <Animatable.View ref={animVidRef} animation={"fadeInUp"} style={{ backgroundColor: "white", shadowColor: "rgb(90,192,234)", shadowRadius: 10, shadowOpacity: .8, position: "absolute", left: 30, marginVertical: "7%", overflow: "visible", padding: 15, borderRadius: 5, maxWidth: "15%" }}>
                      <Modal
                        visible={!!videoActive}
                        animationType="slide"
                        onRequestClose={() => setVideoActive(false)}
                        transparent={true}
                      >
                        <View style={[styles.modalContainer, { justifyContent: "center", alignContent: "center" }]}>
                            <Pressable onPress={() => setVideoActive(false)} style={{ zIndex: 1, position: 'absolute', top: 15, right: 15, borderRadius: 40 }}>
                              <AntDesign name="closecircle" size={40} color="red" />
                            </Pressable>
                          <YoutubePlayer webViewStyle={{ width: "80%", alignSelf: "center", transform: [{ scale: 0.8 }], minHeight: "85%" }} height={"80%"} videoId={props.videoId || 'dQw4w9WgXcQ'} />
                        </View>
                      </Modal>
                          <Pressable onPress={() => setVideoActive(true)} style={{ flex: 1, zIndex: 10 }}>
                          <Entypo name="video" size={42} />
                        </Pressable>
                      </Animatable.View>
                  }
                  <Pressable onPress={() => handleShowHelp()} style={{ flexDirection: 'row', gap: 35, position: 'absolute', left: props.videoId && 100, bottom: 0 }}>
                    <LottieView source={require('@assets/animations/automation_robot.json')} style={{ flex: 1, width: Platform.select({ web: 120, ios: 70 }), height: 200, bottom: 20, alignSelf: isTablet ? 'center' : 'flex-end', maxHeight: 150 }} loop autoPlay />
                    <Animatable.View style={{ flex: 3, width: 400, left: -30, height: Platform.select({ default: 40, ios: 120 }), backgroundColor: 'white', borderWidth: 3, padding: 20, borderColor: 'rgb(90,192,234)', borderRadius: 20, borderBottomStartRadius: 20, justifyContent: 'center', paddingVertical: 30 }}>
                      <AnimatedTextFunction key={currentIndex + 'animatedHelpText'} fontColor='black' value={getText()} fontSize={14} />
                    </Animatable.View>
                  </Pressable>
                </BlurView>
                {
                  Platform.OS === 'web' &&
                <MaterialCommunityIcons name="drag-vertical-variant" size={24} color={theme ? "grey" : "white"} style={{ alignSelf: "stretch", right: Platform.select({web: 0, default: -15}), top: 80, position: "absolute" }} />
                }
              </Animatable.View>
            </Animated.View>
          </PanGestureHandler>
      }
    </>
  )
});

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
})

export default SwipeableRobot;
