import React from 'react';
import { View } from 'react-native';
import { Text } from 'tamagui';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught an error:', error);
    this.setState({ hasError: true, error });
    // You can also log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <View>
          <Text>Error: {this.state.error?.message}</Text>
        </View>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;