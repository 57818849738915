import { PAYMENT_REQUIRED } from "@reduxLocal/actions/types";
import { store } from "@reduxLocal/persistState";

export default function paymentDispatcher() {
  const paymentRequiredStatus = store.getState().app.paymentRequired;

  if(!paymentRequiredStatus) {
    return store.dispatch({
      type: PAYMENT_REQUIRED,
    })
  }
}