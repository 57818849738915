import { createNavigationContainerRef, StackActions } from '@react-navigation/native';
import { abortAllRequests } from '@utils/abortController';

export const navigationRef = createNavigationContainerRef();

export default function navigate(name, params) {
    if (navigationRef.isReady()) {
        navigationRef.current?.navigate(name, params);
        console.log('abortAllRequests');
        abortAllRequests();
    }
}

export function stack(...args) {   
    navigationRef.current?.dispatch(StackActions.push(...args));
}