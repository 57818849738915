import React from "react"
import LottieView from "@utils/lottieAnimation"

export default function HandleIcon(index: number) {
  switch (index) {
    case 0: {
    return <LottieView source={require('@assets/animations/LordIcons/63-home-gradient-edited.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
    }
    case 1: {
    return <LottieView source={require('@assets/animations/LordIcons/neighbourhood.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
    }
    case 2: {
    return <LottieView source={require('@assets/animations/LordIcons/building.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
    }
    case 3: {
    return <LottieView source={require('@assets/animations/LordIcons/manyBuildings.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
    }
    case 4: {
    return <LottieView source={require('@assets/animations/LordIcons/beta-test.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  }
}
}

const lottieStyle = {
  flex: 1,
  width: 150,
  height: 150
}