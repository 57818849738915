import { useDispatch } from "react-redux";
import addCompanyInfo from "../../screens/TopLevel/Components/AddCompanyInfo";
import styles from "@stylesheet";
import { Platform, View, Text, TextInput, Pressable, ScrollView, KeyboardAvoidingView, ActivityIndicator } from 'react-native';
import { Image } from 'expo-image';
import { addCompany } from "@api/api";
import { forwardRef, useEffect, useState } from "react";
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { HIDE_ADD_COMPANY_MODAL, SHOW_AUTH_MODAL } from "@reduxLocal/actions/types";
import React from "react";
import { Formik } from "formik";
import Button from "../../../components/UI/Button/Button";
import { FontAwesome } from "@expo/vector-icons";
import * as Animatable from 'react-native-animatable';
import useGlobalUser from "@hooks/getGlobalUser";
import GenerateSchemaForm from "@hooks/generateSchemaForm";

const AddCompanyForm = forwardRef((_, ref) => {
  const userID = useGlobalUser();
  const dispatch = useDispatch();
  const [image, setImage] = useState('null');
  const [hovered, setHovered] = useState(false);
  const [imageData, setImageData] = useState({});
  const formRef = React.useRef(null);
  const [loading, setLoading] = useState(false);


    useEffect(() => {
      (async () => {
        if (Platform.OS != 'web') {
          const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
          if (status != 'granted') {
            alert('Camera roll permissions required');
          }
        }
      })();
    }, []);

    const _resizeImage = async () => {
      const pickImage = async () => {
        let result: ImagePicker.ImagePickerResult;
        const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permissionResult.granted === false) {
          alert("You've refused to allow this app to access your photos!");
          return;
        }

        result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          base64: true,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });


        if (!result.canceled) {
          return result;
        }
        if (result.canceled) {
          alert("Upload canceled");
          return;
        }
        return result;
      };

      pickImage().then(async result => {
        const { assets } = result;
        const uri = assets[0].uri;
        setImage(uri)
        const manipResultImg = await manipulateAsync(uri, [
          {
            resize: { height: 200 }
          }
        ], { format: SaveFormat.PNG, compress: 1, base64: true }
        );
        let img = manipResultImg.base64;
        setImageData({ img })
        formRef.current?.setFieldValue('logo', img);
      });
    }


    return (
      <View style={{ flex: 1, minHeight: "84%" }}>
        <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={50} style={{ flex: 10, paddingHorizontal: 10 }}>
              <View style={{ flex: 1 }}>
                {loading ?
                  <ActivityIndicator />
                  :
              <View style={{ gap: 35, flex: 10, alignSelf: 'center' }}>
                <View style={{ flex: 2 }}>
                      <Pressable onPress={_resizeImage} onHoverIn={() => { setHovered(true) }} onHoverOut={() => { setHovered(false) }}>
                    <View style={{ minHeight: 100, borderRadius: 20, borderWidth: image != 'null' && imageData ? 0 : 3, justifyContent: 'center', padding: 20, borderStyle: 'dashed', alignContent: 'center', alignSelf: 'stretch', alignItems: 'center', backgroundColor: hovered ? 'grey' : 'white' }}>
                          {
                        image != 'null' && imageData ?
                          <Animatable.View animation={'bounceIn'} style={{ flex: 1 }}>
                            <Pressable style={{ position: 'absolute', top: -50, right: 0, zIndex: 1 }} onPress={() => { setImage('null'); setImageData({}) }}>
                              <FontAwesome name="times" size={24} color="red" />
                            </Pressable>
                            <Image source={{ uri: image }} style={{ top: -50, flex: 1, resizeMode: "center", width: 300, minHeight: 120 }} />
                              </Animatable.View>
                              :
                              <View>
                                <FontAwesome style={styles.menuItemIcon} size={25} name={'plus'} color={'#00000081'} />
                                <Text adjustsFontSizeToFit >Add Logo</Text>
                              </View>
                          }
                        </View>
                      </Pressable>
                    </View>
                <View style={[styles.credentialInputContainer, { marginLeft: -20, alignContent: 'flex-start', flex: 10, gap: 25 }]}>
                  <GenerateSchemaForm
                    schema={addCompanyInfo}
                    removeSubmit
                  />
                    </View>
                  </View>
                }
          </View>
        </KeyboardAvoidingView>
      </View>
    )
});

export default AddCompanyForm;