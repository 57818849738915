import React from 'react';
import { Text, View, Dimensions, Pressable } from 'react-native';
import { Image } from 'expo-image';
import { Title } from '@constants/Themed';
import * as SVG from 'react-native-svg';
import * as Animatable from 'react-native-animatable';
import moment from 'moment';
import * as WebBrowser from 'expo-web-browser';
import RenderHtml from 'react-native-render-html';
import styles from '@stylesheet';
import { uniqueId } from 'lodash';
import { Card, H3, Separator, SizableText } from 'tamagui';

const decodeHtmlEntities = (text) => {
  return text
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
};

const BlogItem = ({item, index}) => {

  const id = uniqueId();
  const getImage = () => {
    try {
      const image = item.content.rendered?.match(/src=\"([^\"]*)/)[1];
      return image
    } catch (e) {
    }
  }
  return (
    <Card key={item.id || id} style={{ borderRadius: 20, padding: 20, margin: 20, flexDirection: 'row', minHeight: 300, paddingVertical: 30, flexWrap: "wrap", flex: 1, alignSelf: 'stretch', overflow: "visible" }}>
      <View style={{ flex: 10, gap: 20 }} >
        <View style={{ flex: 1, height: 200, borderRadius: 20, overflow: 'hidden', backgroundColor: 'white', shadowRadius: 20, shadowOpacity: .2 }} onPress={() => WebBrowser.openBrowserAsync(item?.link)}>
          <Image source={{ uri: getImage() }} style={{ flex: 1, height: 120, }} contentFit='cover' />
        </View>
        <Pressable style={{ flex: 1, zIndex: -1, gap: 0, padding: 10, borderRadius: 20, backgroundColor: 'white', shadowRadius: 20, shadowOpacity: .2 }} onPress={() => WebBrowser.openBrowserAsync(item?.link)}>
          <View style={{ alignContent: 'space-between', alignSelf: 'stretch', borderColor: 'grey', paddingHorizontal: 5 }}>
            <H3 style={{ fontSize: 16, flex: 3, fontWeight: "bold", lineHeight: 20 }}>{decodeHtmlEntities(item?.title.rendered)}</H3>
            <H3 style={{ textAlign: 'left', fontSize: 12, flex: 2, top: -5 }}>{moment(item.date).format('dddd DD MMM YY')}</H3>
          </View>
          <Separator maxHeight={1} />
          <SizableText style={{ flex: 1, top: -20, paddingHorizontal: 10 }} numberOfLines={4} adjustsFontSizeToFit><RenderHtml source={{ html: item?.excerpt.rendered }} /></SizableText>
        </Pressable>
      </View>
    </Card>
  )
}
export default BlogItem;