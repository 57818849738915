import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as tf from '@tensorflow/tfjs';
import { bundleResourceIO } from '@tensorflow/tfjs-react-native';
import * as jpeg from 'jpeg-js';
import * as nsfwjs from 'nsfwjs';
import { useDispatch } from 'react-redux';
import { HIDE_LOADING_MODAL, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL } from '@reduxLocal/actions/types';
import { store } from '@reduxLocal/persistState';

const modelJson = require('./models/nsfw-model.json');
const modelWeights = require('./models/nsfw-weights.bin');

async function imageToTensor(rawImageData: ArrayBuffer) {
  const TO_UINT8ARRAY = true;
  const { width, height, data } = jpeg.decode(rawImageData, {useTArray: true});
  // Drop the alpha channel info for mobilenet
  const buffer = new Uint8Array(width * height * 3);
  let offset = 0; // offset into original data
  for (let i = 0; i < buffer.length; i += 3) {
    buffer[i] = data[offset];
    buffer[i + 1] = data[offset + 1];
    buffer[i + 2] = data[offset + 2];

    offset += 4;
  }

  return tf.tensor3d(buffer, [height, width, 3]);
}

const handleImageUpload = async (local: boolean) => {
  const dispatch = store.dispatch;
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

  if (status !== 'granted') {
    alert('Sorry, we need camera roll permissions to make this work!');
    return;
  }

  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 3],
  });

  if (result.canceled) {
    alert('No image selected');
    return;
  }

  dispatch({ type: SHOW_LOADING_MODAL });
  // Resize image for model input (224x224)
  const resizedImage = await manipulateAsync(
    result.assets[0].uri,
    [{ resize: { width: 224, height: 224 } }],
    { compress: 1, format: SaveFormat.JPEG }
  );

  try {
    await tf.ready();

    // Load NSFW model
    const model = await nsfwjs.load(bundleResourceIO(modelJson, modelWeights));

    // Load resized image as binary data
    const image = await fetch(resizedImage.uri);
    const rawImageData = await image.arrayBuffer();

    // Convert image to tensor
    let imageTensor = await imageToTensor(rawImageData);

    // Resize tensor to match model input and normalize
    // imageTensor = tf.image.resizeBilinear(imageTensor, [224, 224])
    //   .expandDims(0)
    //   .toFloat()
    //   .div(tf.scalar(255.0));  // Normalize pixel values between 0 and 1

    console.log('Preprocessed image shape:', imageTensor.shape);

    // Run the model prediction
    const predictions = await model.classify(imageTensor);
    console.log('Predictions:', predictions);
    // const predictionArray = await predictions.array();

    // console.log('Predictions:', predictionArray);
    imageTensor.dispose();
    // NSFW content detection logic
    const nsfwContent = predictions.some((pred: any) =>
      ['Porn', 'Sexy', 'Hentai'].includes(pred.className) && pred.probability > 0.7
    );

    if (nsfwContent) {
      dispatch({ type: HIDE_LOADING_MODAL });
      alert('NSFW content detected! Please choose another image.');
      return;
    }

  } catch (error) {
    dispatch({ type: HIDE_LOADING_MODAL });
    console.error('Error in image classification:', error);
    alert('Error processing the image.');
  }
  dispatch({ type: HIDE_LOADING_MODAL });
  return local ? resizedImage : resizedImage.base64;
};

export default handleImageUpload;
