import undoable from 'redux-undo';
import { updateObject } from './utils/globalStateFunctions';
import {
	ADD_ACTION,
	CLEAR_ACTION_HANDLER_HISTORY,
	REMOVE_ACTION,
	SET_ACTIONS,
	UPDATE_ACTION_ITEM,
} from '@reduxLocal/actions/types';

const initialState = {
	actions: [],
	mods: {},
};

const actionHandler = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ACTION_HANDLER_HISTORY: {
			return updateObject(state, {
				initialState,
			});
		}
		case UPDATE_ACTION_ITEM: {
			const { actionItem, index } = action.payload;
			return updateObject(state, {
				actions: state.actions.map((action, i) => {
					if (i === index) {
						return { ...action, ...actionItem };
					}
					return action;
				}),
			});
		}
		case SET_ACTIONS: {
			const actions = action.payload;
			return updateObject(state, {
				actions: actions || [],
			});
		}
		case ADD_ACTION: {
			const { actionData } = action.payload;
			const i = action.payload.index;
			if (typeof i === 'number' && i >= 0) {
				return updateObject(state, {
					actions: [
						...state.actions.slice(0, i),
						actionData,
						...state.actions.slice(i),
					],
				});
			} else {
				return updateObject(state, {
					actions: [...state.actions, actionData],
				});
			}
		}
		case REMOVE_ACTION: {
			const { index } = action.payload;
			return updateObject(state, {
				actions: state.actions.filter((_, i) => i !== index),
			});
		}
		default:
			return state;
	}
};

const undoablePreviewer = undoable(actionHandler, {
	limit: 50,
	clearHistoryType: CLEAR_ACTION_HANDLER_HISTORY,
});

export default undoablePreviewer;
