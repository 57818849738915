import React, { forwardRef } from 'react';

interface ColorPickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (color: string) => void; // Ensure onChange expects a string
}

const ColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(({ onChange, ...props }, ref) => {
  return (
    <input
      type="color"
      ref={ref}
      {...props}
      onChange={(e) => {
        if (onChange) onChange(e.target.value); // Ensure only the color value (string) is passed
      }}
      style={{ cursor: 'pointer', ...props.style }}
    />
  );
});

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;
