import React from 'react';
import * as Animatable from 'react-native-animatable';
import { AntDesign } from '@expo/vector-icons';
import { Image } from 'react-native';
import { Text, View, H2 as H4, H1, ListItemSubtitle, Button, Card, XStack, H2, Separator } from 'tamagui'
import styles from '@stylesheet';
import './css/packagestyles.css';
import { useNavigation } from '@react-navigation/native';
import transform from 'css-to-react-native';
import { getPropertyName, getStylesForProperty } from 'css-to-react-native';
import { StyleSheet } from 'react-native';
import { Title } from '@constants/Themed';


const Packages = ({ open, setOpen }: { open: boolean, setOpen: (v: boolean) => void }) => {

  return open && (
    <View style={packageStyles.card}>
      <View style={packageStyles.cardBody}>
        <View gap={60} style={{ flex: 1 }}>
          <View style={[styles.centerEverything, { gap: 20 }]}>
            <Title style={{ fontSize: 36 }}>Upgrading your plan? Great Idea!</Title>
            <ListItemSubtitle style={{ fontSize: 18 }}>No contracts. No surprise fees.</ListItemSubtitle>
          </View>
          <XStack style={{ flex: 1, flexDirection: "row", gap: 20 }}>
            <Animatable.View style={packageStyles.pricingPlan} animation={'fadeInUp'}>
              <View style={[styles.centerEverything, { gap: 20 }]}>
                <View style={packageStyles.iconContainer}>
                  <Image style={{ width: 40, height: 40, resizeMode: "contain" }} source={require("@assets/images/pricing/price-icon1.png")} />
                </View>
                <View>
                  <ListItemSubtitle>For individuals</ListItemSubtitle>
                  <H4 style={[packageStyles.planNameStyle, { textAlign: "center", color: "black" }]}>Basic</H4>
                </View>
              </View>
              <Text style={{ lineHeight: 24, color: "black", textAlign: "center" }}>Start building your organisation with uncapped data models and storage</Text>
              <H4 style={[packageStyles.planNameStyle, { textAlign: "center", color: "black" }]}>$15 <Text style={{ color: "black" }}>/monthly</Text></H4>
              <Text style={{ color: "black" }}>What’s included</Text>
              <Separator />
              <View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>All analytics features</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>AI features</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>Normal support</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>Up to 1 team members</Text>
                </View>
              </View>
              <Button style={packageStyles.button} onPress={() => { setOpen(false) }}>
                <Text style={packageStyles.buttonText}>Get started</Text>
              </Button>
            </Animatable.View>
            <Animatable.View style={packageStyles.pricingPlanPopular} delay={200} animation={'fadeInUp'}>
              <Text style={{ position: "absolute", color: "white", backgroundColor: "red", padding: 10, paddingHorizontal: 15, paddingInline: 30, left: -35, top: 10, shadowRadius: 20, shadowOpacity: .2, transform: [{ rotate: '-45deg' }] }}>{" "}{"Popular"}</Text>
              <View style={[styles.centerEverything, { gap: 20 }]}>
                <View style={packageStyles.iconContainer}>
                  <Image style={{ width: 40, height: 40, resizeMode: "contain" }} source={require("@assets/images/pricing/price-icon2.png")} />
                </View>
                <View>
                  <ListItemSubtitle style={{ color: "white" }}>For startups</ListItemSubtitle>
                  <H4 style={[packageStyles.planNameStyle, { color: "white", textAlign: "center" }]}>Pro</H4>
                </View>
              </View>
              <Text style={{ color: "white", textAlign: "center" }}>Start you organisations journey, with industry level tools at non-industry level prices.</Text>
              <H4 style={[packageStyles.planNameStyle, { color: "white", textAlign: "center" }]}>$35 <Text style={{ color: "white" }}>/monthly</Text></H4>
              <Text style={{ color: "white" }}>What’s included</Text>
              <Separator />
              <View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "white" }}>All analytics features</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "white" }}>Up to 250,000 tracked visits</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "white" }}>Normal support</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "white" }}>Up to 3 team members</Text>
                </View>
              </View>
              <Button style={packageStyles.button} onPress={() => { setOpen(false) }}>
                <Text style={packageStyles.buttonText}>Get started</Text>
              </Button>
            </Animatable.View>
            <Animatable.View style={packageStyles.pricingPlanEnterprise} animation={'fadeInUp'}>
              <View style={[styles.centerEverything, { gap: 20 }]}>
                <View style={packageStyles.iconContainer}>
                  <Image style={{ width: 40, height: 40, resizeMode: "contain" }} source={require("@assets/images/pricing/price-icon3.png")} />
                </View>
                <View>
                  <ListItemSubtitle style={{ color: "black" }}>For big companies</ListItemSubtitle>
                  <H4 style={[packageStyles.planNameStyle, { textAlign: "center", color: "black" }]}>Enterprise</H4>
                </View>
              </View>
              <Text style={{ textAlign: "center", color: "black" }}>For those with more stringent requirements and large scale companies starting at 100 members+</Text>
              <H4 style={{ color: "black" }}>Get a quote</H4>
              <Text style={{ color: "black" }}>What’s included</Text>
              <Separator />
              <View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>All analytics features</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>Up to 250,000 tracked visits</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>Normal support</Text>
                </View>
                <View style={packageStyles.featureItem}>
                  <View style={packageStyles.featureIcon}>
                    <AntDesign name="checkcircleo" style={{ borderRadius: 24 }} size={24} color="white" />
                  </View>
                  <Text style={{ color: "black" }}>Up to 3 team members</Text>
                </View>
              </View>
              <Button style={packageStyles.button} onPress={() => { setOpen(false) }}>
                <Text style={packageStyles.buttonText}>Get started</Text>
              </Button>
            </Animatable.View>
          </XStack>
        </View>
      </View>
    </View >
  )
}

const packageStyles = StyleSheet.create({
  planNameStyle: {
    fontSize: 36,
    fontWeight: "600",
    shadowRadius: 20,
    shadowOpacity: .4,
  },
  card: {
    "flex": 1,
    minWidth: "80%",
    "backgroundColor": "var(--white)",
    "borderTopLeftRadius": 8,
    "borderTopRightRadius": 8,
    "borderBottomRightRadius": 8,
    "borderBottomLeftRadius": 8,
    "shadowOffset": {
      "width": 0,
      "height": 0.25
    },
    "shadowRadius": 1.875,
    "shadowColor": "rgba(46, 45, 116, 0.05)",
    "shadowOpacity": 1,
    "borderWidth": 0,
    "borderColor": "black",
    "borderStyle": "solid",
    // "color": "var(--text-secondary-light)"
  },
  cardHeader: {
    borderBottomWidth: 1,
    backgroundColor: '#f8f9fa',
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 20,
  },
  cardBody: {
    paddingHorizontal: 20,
    paddingVertical: 40,
    flex: 1,
  },
  pricingPlan: {
    position: 'relative',
    borderRadius: 24,
    shadowRadius: 20,
    gap: 20,
    shadowOpacity: .2,
    overflow: 'hidden',
    backgroundColor: '#e0e7ff',
    alignItems: 'center',
    padding: 16,
    flex: 1,
  },
  pricingPlanPopular: {
    position: 'relative',
    shadowRadius: 20,
    shadowOpacity: .2,
    borderRadius: 24,
    gap: 20,
    top: -40,
    minHeight: "110%",
    overflow: 'hidden',
    backgroundColor: '#2563eb',
    color: 'white',
    alignItems: 'center',
    padding: 16,
    flex: 1.2,
    transform: [{ scale: 1.1 }]
  },
  pricingPlanEnterprise: {
    position: 'relative',
    borderRadius: 24,
    shadowRadius: 20,
    flex: 1,
    gap: 20,
    shadowOpacity: .2,
    overflow: 'hidden',
    backgroundColor: '#d1fae5',
    alignItems: 'center',
    padding: 16,
  },
  iconContainer: {
    width: 72,
    height: 72,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: '#ffffff',
  },
  pricingPlanText: {
    fontWeight: '500',
    fontSize: 16,
    color: '#6b7280',
  },
  pricingPlanTitle: {
    marginBottom: 0,
    fontSize: 24,
    fontWeight: '600',
  },
  pricingPlanDescription: {
    marginTop: 16,
    marginBottom: 28,
    color: '#6b7280',
  },
  pricingPlanPrice: {
    marginBottom: 24,
    fontSize: 32,
    fontWeight: '700',
  },
  pricingPlanFeatures: {
    marginBottom: 20,
    fontWeight: '500',
  },
  featureItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    marginBottom: 16,
  },
  featureIcon: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: '#7c3aed',
  },
  featureText: {
    fontSize: 16,
    color: '#6b7280',
  },
  button: {
    marginHorizontal: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 10,
    minHeight: 50,
    borderRadius: 15,
  },
  buttonText: {
    color: 'white',
  },

})

export default Packages;