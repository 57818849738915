import React from 'react';
import { Platform, Pressable } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import _, { uniqueId } from 'lodash';
import styles from '@stylesheet';
import { Text } from 'tamagui';

export type ActionTypeProps = { 
  function: (e) => void;
  icon: string | JSX.Element;
  color?: string;
  hint?: string;
  label?: string;
  special?: JSX.Element;
};

export type ActionButtonProps = {
  action: ActionTypeProps;
  index?: number;
  large?: boolean;
  color?: string;
  noBackground?: boolean;
  disabled?: boolean;
  active?: boolean;
};

const ActionButton = ({ noBackground, active, action, index, large, color, disabled }: ActionButtonProps) => {

  if (action.special) {
    return (
      <>
        {action.special}
      </>
    )
  }

  const [hovered, setHovered] = React.useState(false);
  const [colorState, setColorState] = React.useState(color);
  const uuid = uniqueId();

  const handlePress = (e) => {
    action.function(e);
  };

  return (
    <Pressable
      onPress={(e) => { if (!disabled) { handlePress?.(e) } }}
      key={index+ uuid}
      accessibilityHint={action.hint}
      onBlur={() => setHovered(false)}
      // onPressIn={handlePressIn}
      // onPressOut={handlePressOut}
      style={[
        styles.LoginButton,
        { minHeight: large ? 80 : 50, minWidth: large ? 80 : 50, maxHeight: large ? 80 : 50, shadowOpacity: 0, borderWidth: 0, borderColor: "transparent", backgroundColor: "transparent", padding: 5, paddingVertical: 2, borderRadius: 10, marginHorizontal: 0, flex: 1, maxWidth: 50, transform: [{ scale: Platform.OS != 'web' ? 0.8 : 1 }] }
      ]}
    >
      {({ hovered }) => (
        <Animatable.View
          transition={['backgroundColor']}
          duration={200}
          style={[styles.centerEverything, { minHeight: large ? 80 : 50, minWidth: large ? 80 : 50, maxHeight: large ? 80 : 50, backgroundColor: noBackground ? undefined : (hovered || active ? 'lightblue' : '#ececec'), margin: 4, padding: 10, borderRadius: 10 }]}
        >
          {
            typeof action.icon === 'string' ?
              <AntDesign name={action.icon} size={24} style={{ paddingHorizontal: 5, paddingVertical: 2, minWidth: 40, flex: 1, left: 3 }} color={action.color || colorState} />
            :
            action.icon
          }
          {
            action.label && Platform.OS === 'web' && <Text style={{ flex: 1, textAlign: 'center', fontSize: 12, color: "white" }}>{action.label}</Text>
          }
        </Animatable.View>
      )}
    </Pressable>
  );
};

export default ActionButton;
