import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Platform, Pressable, View, ViewProps } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_PAGE_BADGES, HIDE_MESSAGE_MODAL, SECOND_MENU_CONTEXT, SECOND_MENU_TOGGLE } from '@reduxLocal/actions/types';
import styles from '@stylesheet';
import LottieView from '@utils/lottieAnimation';
import { useTranslation } from 'react-i18next';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useNavigation } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import { Badge } from 'react-native-paper';
import { YStack } from 'tamagui';
import { CountUp } from 'use-count-up';

type PrimaryMenuTypes = {
  item: { key: string, screen: string, mobileIcon: JSX.Element, header: string, icon: any, function: Function, secondaryMenu: { active: boolean, content: any } };
  style?: ViewProps;
};

const 
PrimaryMenu = ({ item, style }: PrimaryMenuTypes) => {
  const { t } = useTranslation();
  const [buttonState, setButtonState] = useState(false);
  const animationRef = useRef<LottieView | null>(null);
  const menuOpen = useSelector((state: DefaultRootState) => state.UI.menuOpen);
  const page = useSelector((state: DefaultRootState) => state.navigation.page);
  const dispatch = useDispatch();
  const markedStyle = styles.menuItem;
  const unMarkedStyle = styles.menuItemSelected;
  const hoveredStyle = styles.menuHovered;
  const navigation = useNavigation();
  const badges = useSelector((state: DefaultRootState) => state.UI?.badges?.[item.screen?.toLowerCase()]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        animationRef.current.stop();
        animationRef.current.destroy();
      }
    }
  }, [])


  const LottieIcon = useMemo(() => {
    return <LottieView ref={animationRef} style={styles.menuItemIcon} source={item.icon} autoPlay />;
  }, [item.icon]);

  const confirmedScreen = (item) => (page.name === (item.stack ? item.stack : item.screen) ? markedStyle : unMarkedStyle);


  const _handleNavigate = () => {
    if (item.stack) {
      navigation.replace(item.stack, { screen: item.screen });
    } else {
      navigation.replace(item.screen);
    }
  }


  return (
    <View style={{ flex: 1, minHeight: 40, overflow: 'visible' }} key={item.key}>
      <Pressable
        style={[
          { minHeight: 40, minWidth: Platform.OS === 'web' ? '90%' : '90%' },
        ]}
        hitSlop={0}
        pointerEvents={'auto'}
        onHoverIn={() => {
          setButtonState(true);
          animationRef.current?.reset();
          animationRef.current?.play();
        }}
        onHoverOut={() => setButtonState(false)}
        onPress={() => {
          try{
            dispatch({type: CLEAR_PAGE_BADGES, payload: item.screen.toLowerCase()});
          } catch(e){
            console.log('Page does not have a badge');
          }
          if (item.function) {
            item.function();
          } else {
            const secondMenuArray = item.secondaryMenu.content;
            if (item.secondaryMenu.active === true) {
              dispatch({ type: SECOND_MENU_CONTEXT, data: secondMenuArray });
              _handleNavigate();
            } else {
              dispatch({ type: SECOND_MENU_TOGGLE });
              _handleNavigate();
            }
          }
        }}
      >
        {({ hovered }) => (
          <Animatable.View duration={20} transition={['backgroundColor']} style={[hovered ? hoveredStyle : confirmedScreen(item), { flexDirection: 'row', flex: 1, alignSelf: 'stretch', overflow: 'visible', borderRadius: 10, ...style }]}>
          {
            menuOpen &&
          <View style={{ flex: 3, justifyContent: 'center', overflow: 'visible' }}>
            <Animatable.Text
              adjustsFontSizeToFit
              selectable={false}
              numberOfLines={1}
              duration={menuOpen ? 1500 : 50}
              transition={['fontSize', 'opacity', 'flex']}
              direction={menuOpen ? 'normal' : 'reverse'}
              style={{
                flex: menuOpen ? 3 : 0.001,
                verticalAlign: 'middle',
                color: 'white',
                top: !hovered && !confirmedScreen(item) ? 12: -2,
                justifyContent: 'center',
                opacity: menuOpen ? 1 : 0,
                paddingHorizontal: 10,
                fontWeight: 'bold'
              }}
            >
              {t(item.header)}
            </Animatable.Text>
          </View>
          }
          <View style={{ top: 3, marginHorizontal: 4, flex: 1, backgroundColor: 'transparent', alignItems: 'center', overflow: 'visible' }}>
            {Platform.OS !== 'web' ? (
              <View style={{ flex: 1, maxHeight: 60, top: 3 }}>{item.mobileIcon}</View>
            ) : (
                  <YStack>
              <View >{LottieIcon}</View>
                    {
                      badges > 0 &&
                      <Badge style={{ shadowRadius: 10, shadowOpacity: .5, fontWeight: "bold", position: "absolute", top: -8, right: -15 }}> <CountUp isCounting end={badges} duration={1} />
                      </Badge>
                    }
                  </YStack>
            )}
          </View>
        </Animatable.View>
        )}
      </Pressable>
    </View>
  );
};

export default PrimaryMenu;
