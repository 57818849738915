import { createThemes, defaultComponentThemes } from '@tamagui/theme-builder';
import * as Colors from '@tamagui/colors';
import { createTokens } from 'tamagui';

const tokens = createTokens({
	color: {
		pinkDark: '#610c62',
		pinkLight: '#f17efc',
	},
	// ... see configuration docs for required tokens
});

const lightShadows = {
	shadow1: 'rgba(0,0,0,0.04)',
	shadow2: 'rgba(0,0,0,0.08)',
	shadow3: 'rgba(0,0,0,0.16)',
	shadow4: 'rgba(0,0,0,0.24)',
	shadow5: 'rgba(0,0,0,0.32)',
	shadow6: 'rgba(0,0,0,0.4)',
};

const darkShadows = {
	shadow1: 'rgba(0,0,0,0.2)',
	shadow2: 'rgba(0,0,0,0.3)',
	shadow3: 'rgba(0,0,0,0.4)',
	shadow4: 'rgba(0,0,0,0.5)',
	shadow5: 'rgba(0,0,0,0.6)',
	shadow6: 'rgba(0,0,0,0.7)',
};

const darkPalette = [
	'hsla(194, 62%, 20%, 1)',
	'hsl(194, 83.80%, 45.90%)',
	'hsl(194, 83.80%, 45.90%)',
	'hsl(194, 83.80%, 45.90%)',
	'hsl(194, 78.20%, 46.90%)',
	'hsl(194, 82.50%, 44.90%)',
	'hsl(194, 83.80%, 45.90%)',
	'hsl(194, 81.80%, 43.10%)',
	'hsl(194, 84.50%, 45.50%)',
	'hsl(194, 80.60%, 46.50%)',
	'hsla(0, 15%, 93%, 1)',
	'hsla(0, 15%, 99%, 1)',
];
const lightPalette = [
	'hsla(194, 62%, 100%, 1)',
	'hsla(194, 62%, 94%, 1)',
	'hsla(194, 62%, 89%, 1)',
	'hsla(194, 62%, 83%, 1)',
	'hsla(194, 62%, 78%, 1)',
	'hsla(194, 62%, 72%, 1)',
	'hsla(194, 62%, 67%, 1)',
	'hsla(194, 62%, 61%, 1)',
	'hsla(194, 62%, 56%, 1)',
	'hsla(194, 62%, 50%, 1)',
	'hsla(0, 15%, 15%, 1)',
	'hsla(0, 15%, 1%, 1)',
];



// we're adding some example sub-themes for you to show how they are done, "success" "warning", "error":

const builtThemes = createThemes({
	base: {
		palette: {
			dark: darkPalette,
			light: lightPalette,
		},

		extra: {
			darkPink: tokens.color.pinkDark,
			light: {
				...Colors.green,
				...Colors.red,
				...Colors.yellow,
				...lightShadows,
				shadowColor: lightShadows.shadow1,
			},
			dark: {
				...Colors.greenDark,
				...Colors.redDark,
				...Colors.yellowDark,
				...darkShadows,
				shadowColor: darkShadows.shadow1,
			},
		},
	},

	accent: {
		palette: {
			dark: [
				'hsla(250, 0%, 35%, 1)',
				'hsla(250, 0%, 38%, 1)',
				'hsla(250, 0%, 41%, 1)',
				'hsla(250, 0%, 43%, 1)',
				'hsla(250, 0%, 46%, 1)',
				'hsla(250, 0%, 49%, 1)',
				'hsla(250, 0%, 52%, 1)',
				'hsla(250, 0%, 54%, 1)',
				'hsla(250, 0%, 57%, 1)',
				'hsla(250, 0%, 60%, 1)',
				'hsla(250, 50%, 90%, 1)',
				'hsla(250, 50%, 95%, 1)',
			],
			light: [
				'hsla(250, 0%, 43%, 1)',
				'hsla(250, 0%, 45%, 1)',
				'hsla(250, 0%, 48%, 1)',
				'hsla(250, 0%, 50%, 1)',
				'hsla(250, 0%, 53%, 1)',
				'hsla(250, 0%, 55%, 1)',
				'hsla(250, 0%, 58%, 1)',
				'hsla(250, 0%, 60%, 1)',
				'hsla(250, 0%, 63%, 1)',
				'hsla(250, 0%, 65%, 1)',
				'hsla(250, 50%, 95%, 1)',
				'hsla(250, 50%, 95%, 1)',
			],
		},
	},

	childrenThemes: {
		warning: {
			palette: {
				dark: Object.values(Colors.yellowDark),
				light: Object.values(Colors.yellow),
			},
			red: {
				palette: {
					dark: Object.values(Colors.redDark),
					light: Object.values(Colors.red),
				},
			},
			yellow: {
				palette: {
					dark: Object.values(Colors.yellowDark),
					light: Object.values(Colors.yellow),
				},
			},
			green: {
				palette: {
					dark: Object.values(Colors.greenDark),
					light: Object.values(Colors.green),
				},
			},
		},

		error: {
			palette: {
				dark: Object.values(Colors.redDark),
				light: Object.values(Colors.red),
			},
		},

		success: {
			palette: {
				dark: Object.values(Colors.greenDark),
				light: Object.values(Colors.green),
			},
		},
	},

	// optionally add more, can pass palette or template

	// grandChildrenThemes: {
	//   alt1: {
	//     template: 'alt1',
	//   },
	//   alt2: {
	//     template: 'alt2',
	//   },
	//   surface1: {
	//     template: 'surface1',
	//   },
	//   surface2: {
	//     template: 'surface2',
	//   },
	//   surface3: {
	//     template: 'surface3',
	//   },
	// },
});

export type Themes = typeof builtThemes;

// this is optional, but saves client-side JS bundle size by leaving out themes on client.
// tamagui automatically hydrates themes from css back into JS for you and the tamagui
// bundler plugins automate setting TAMAGUI_ENVIRONMENT.

export const themes: Themes =
	process.env.TAMAGUI_ENVIRONMENT === 'client' &&
	process.env.NODE_ENV === 'production'
		? ({} as any)
		: (builtThemes as any);
