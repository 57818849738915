import { View, Image, Animated, useWindowDimensions, Pressable, Easing, Platform } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-native-modal';
import { HIDE_HELP_MODAL } from '@reduxLocal/actions/types';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { withAnchorPoint } from '@utils/anchorPoint';
import { AntDesign } from '@expo/vector-icons';
import styles from "@stylesheet";
import * as Animatable from 'react-native-animatable';
import { GiftedChat, InputToolbarProps, SystemMessage } from "react-native-gifted-chat";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import { H2, H3, H4, H5, XStack, YStack } from "tamagui";
import LoadingIndicator from "@components/Global/Spinner";
import { Trash as MobileTrash, HomeIcon as MobileHomeIcon, DataLaptop as MobileDataLaptop, MobileUsersIcon, SearchIcon as MobileSearchIcon, MobileProjectIcon, MobileCalendarIcon, MobileReportIcon, MobileSettingsIcon, MobileIntegrationIcon } from '@assets/--native/index';
import { ProgressBar } from "react-native-paper";
import { useQueryClient } from "react-query";
import UsageStatMonitor from "@navigation/screens/MidLevel/SettingsScreens/Components/UsageStatMonitor";
import { BlurView } from "@constants/Themed";

const AIModal = forwardRef((props, ref) => {
  const theme = themeSelector();
  const rotationValue = useRef(new Animated.Value(0)).current; // Start at 90 degrees
  const rightValue = useRef(new Animated.Value(4)).current;
  const fadeValue = useRef(new Animated.Value(0)).current; // Initial value of the 'right' property
  const [modalDimensions, setModalDimensions] = useState({ width: Platform.select({ web: 500, default: 350 }), height: 700 });
  const { height, width } = useWindowDimensions();
  const [flip, setFlip] = useState(false);
  const [selection, setSelection] = useState();
  const [count, setCount] = useState(0);
  const [chatStarted, setChatStarted] = useState(false);
  const dispatch = useDispatch();
  const isMessageOpen = true;
  const [visibleState, setVisibleState] = useState(false);
  const queryClient = useQueryClient();
  // const statQuery = queryClient.getQueryData('statCount');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => { setCount(count + 1); setLoaded(true) }, [visibleState])

  const handleHelp = useCallback(() => {
    console.log('do something without refreshing');
  }, []);

  useEffect(() => {
    if (visibleState) {
      animateRotation();
      animateRightValue();
      animateFade();
    }
  }, [isMessageOpen, visibleState]);


  useImperativeHandle(ref, () => ({
    open: () => setVisibleState(true),
    close: () => setVisibleState(false),
  }));

  const animateRotation = () => {
    Animated.timing(rotationValue, {
      toValue: 90, // End at 0 degrees
      duration: 300, // Animation duration in milliseconds
      useNativeDriver: true, // Use the native driver for performance
    }).start();
  };

  const animateRotationOut = () => {
    Animated.timing(rotationValue, {
      toValue: Platform.select({ web: 0, default: 90 }), // End at 0 degrees
      duration: 300,
      useNativeDriver: true
    }).start(() => { if (Platform.OS === 'web') { setTimeout(() => setModalDimensions({ width: 400, height: 700 }), 1000) }; setChatStarted(false) });
  }

  const animateFade = () => {
    Animated.timing(fadeValue, {
      toValue: 1,
      duration: 2000,
      useNativeDriver: false
    }).start();
  }

  const animateRightValue = () => {
    const targetTranslateX = -40; // Adjust as needed
    Animated.timing(rightValue, {
      toValue: targetTranslateX,
      duration: 400,
      useNativeDriver: true, // Now we can use the native driver
    }).start();
  };

  const handleClose = useCallback(() => {
    animateRotationOut();
  }, []);

  const getTransform = () => {
    let transform = {
      transform: [{
        rotate: rotationValue.interpolate({
          inputRange: [0, 90],
          outputRange: Platform.select({ web: ['270deg', '360deg'], default: ['180deg', '360deg'] }), // Inverted from 90 to 0 degrees
        }),
      }]
    }
    return withAnchorPoint(transform, { x: 0, y: 1 }, { width: modalDimensions.width, height: modalDimensions.height });
  }

  const handleFlip = useCallback((selection) => { setFlip(!flip); if (selection) { setSelection(selection) } }, [])

  return (
    <Modal
      isVisible={visibleState}
      animationIn={'fadeIn'}
      animationOut={'fadeOutDown'}
      onModalWillShow={() => {
        animateRotation();
        animateRightValue();
      }}
      onModalWillHide={() => { animateRotationOut() }}
      onBackdropPress={() => { handleClose(); setTimeout(() => dispatch({ type: HIDE_HELP_MODAL, payload: null }), 600) }}
      statusBarTranslucent
      deviceHeight={height}
      deviceWidth={width}
      hasBackdrop={false}
      coverScreen={false}
      style={{ left: 5,  top: Platform.select({ android: "9%" }) }}
    >
      <BlurView style={{ flex: 1, minWidth: 400, position: "absolute", left: "-300%", minHeight: "120%" }} blurType="light" />
      <Animatable.View
          useNativeDriver={true}
        duration={!isMessageOpen ? 500 : 200}
        style={[getTransform(), {
          position: 'absolute',
          height: modalDimensions.height * 1.1,
          width: modalDimensions.width * 1.05,
          backgroundColor: theme ? 'white' : "black",
          borderRadius: 30,
          borderBottomLeftRadius: Platform.select({ web: 0, default: undefined }),
          borderBottomRightRadius: Platform.select({ web: undefined, default: 0 }),
          borderWidth: 10,
          borderColor: 'rgb(90,192,234)',
          right: Platform.select({ web: -610, default: 40 }),
          bottom: 15,
          padding: 15,
          overflow: 'visible'
        }]}
      >
        <Pressable style={{ left: Platform.select({ web: -15, default: undefined }), right: Platform.select({ web: undefined, default: -15 }), top: -15, position: 'absolute', backgroundColor: 'red', borderRadius: 100, width: 30, height: 30, zIndex: 1, shadowRadius: 40, shadowOpacity: 0.6 }} onPress={() => { setVisibleState(false) }}>
          <AntDesign name="close" size={24} color="white" style={[styles.centerEverything, { padding: 3 }]} />
        </Pressable>
        <XStack style={{ maxHeight: 40 }} gap={20} alignSelf="stretch" flex={1}>
          <H5 numberOfLines={1} maxWidth={"80%"} adjustsFontSizeToFit flex={3}>Getting your AI suggestions</H5>
          <LoadingIndicator type={"processing"} color={"rgb(90,192,234)"} />
        </XStack>
          <YStack>
            <XStack gap={20} top={10} height={100}>
              <Animatable.View animation={'fadeInDown'} delay={100} style={[styles.centerEverything, { backgroundColor: "grey", padding: 10, borderRadius: 10, flex: 1, borderWidth: 2, borderColor: "rgb(90,192,234)" }]}>
                <MobileDataLaptop height={50} width={"100%"} />
                <ProgressBar indeterminate color="rgb(90,192,234)" style={{ minWidth: "100%", height: 10, borderRadius: 10, marginVertical: 10 }} />
              </Animatable.View>
              <Animatable.View animation={'fadeInDown'} delay={300} style={[styles.centerEverything, { backgroundColor: "grey", padding: 10, borderRadius: 10, flex: 1, borderWidth: 2, borderColor: "rgb(90,192,234)" }]}>
                <MobileProjectIcon height={50} width={"100%"} />
                <ProgressBar indeterminate color="rgb(90,192,234)" style={{ minWidth: "100%", height: 10, borderRadius: 10, marginVertical: 10 }} />
              </Animatable.View>
              <Animatable.View animation={'fadeInDown'} delay={500} style={[styles.centerEverything, { backgroundColor: "grey", padding: 10, borderRadius: 10, flex: 1, borderWidth: 2, borderColor: "rgb(90,192,234)" }]}>
                <MobileCalendarIcon height={50} width={"100%"} />
                <ProgressBar indeterminate color="rgb(90,192,234)" style={{ minWidth: "100%", height: 10, borderRadius: 10, marginVertical: 10 }} />
              </Animatable.View>
            </XStack>
          <Animatable.View transition={"minHeight"} duration={1000} style={{ borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0, paddingTop: 15, paddingBottom: 20, borderColor: "rgb(90,192,234)", minHeight: loaded ? 400 : 0, flex: 10, marginVertical: 25 }}>
              <UsageStatMonitor />
          </Animatable.View>
        </YStack>
      </Animatable.View>
    </Modal >
  );
});


export default AIModal;
