const addCompanyInfo = [
	{
		title: 'company_name',
		type: 'string',
	},
	{
		title: 'company_number',
		content: {
			helpText:
				"Company number is optional, but can help us verify your company's identity.",
		},
		type: 'string',
	},

	//{
	// title: 'organizationNumber',
	// placeHolderValue: 'Company Number',
	// fieldState: 'companyNumber',
	// type: 'organisation',
	// furtherInformation: false,
	//},
	{
		title: 'industry_type',
		type: 'string',
	},
	{
		title: 'role',
		content: {
			helpText: 'What is your role in the company?',
		},
		type: 'string',
	},
	{
		title: 'interests',
		type: 'string',
	},
	{
		title: 'details',
		ref: 'textarea',
	},
	{
		title: 'country',
		type: 'country',
		content: {
			helpText:
				'Please select the country where your company is registered.',
		},
	},
];
export default addCompanyInfo;
