       import { View, Image, Animated, useWindowDimensions, Pressable, Easing } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { Text } from "@constants/Themed";
import Modal from 'react-native-modal';
import { HIDE_HELP_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LinearGradient } from "expo-linear-gradient";
import SSLogo from "@assets/--native/Logo";
import { withAnchorPoint } from '@utils/anchorPoint';
import { AntDesign } from '@expo/vector-icons';
import styles from "@stylesheet";
import { Title } from "@constants/Themed";
import Constants from 'expo-constants';
import ShowHelp from "./Components/SearchTopics";
import FlipCard from "react-native-flip-card";
import HelpChat from "./Components/HelpChat";
import { BlurView } from "expo-blur";
import * as Animatable from 'react-native-animatable';
import AnimatedLottieView from '@utils/lottieAnimation';
import { GiftedChat, InputToolbarProps, SystemMessage } from "react-native-gifted-chat";
import useGlobalUser from "@hooks/getGlobalUser";
import MyInputToolbar from "@navigation/screens/MidLevel/MessageScreen/Components/InputToolbar";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import moment from "moment";
import AnimateMarkup from "@hooks/AnimateMarkup";
import { debounce, uniqueId } from "lodash";
import { AI_CHAT_API_URL } from "@api/constant";

const HelpModal = () => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals.helpModal.modalDisplayed);
  const currentScreen = useSelector((state: DefaultRootState) => state.navigation.page.name);
  const isMessageOpen = true;
  const toolbarVisible = useSelector((state: DefaultRootState) => state.modals.toolbar.toolbarDisplayed);
  const theme = themeSelector();
  const [searchText, setSearchText] = useState("");
  const rotationValue = useRef(new Animated.Value(90)).current; // Start at 90 degrees
  const rightValue = useRef(new Animated.Value(4)).current;
  const fadeValue = useRef(new Animated.Value(0)).current; // Initial value of the 'right' property
    const [modalDimensions, setModalDimensions] = useState({ width: 500, height: 700 });
    const { height, width } = useWindowDimensions();
    const [flip, setFlip] = useState(false);
    const [selection, setSelection] = useState();
    const [count, setCount] = useState(0);
    const [helpSearch, setHelpSearch] = useState("");
    const [AIHelp, setAIHelp] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [chatStarted, setChatStarted] = useState(false);
    const { height: screenHeight, width: screnWidth } = useWindowDimensions();
    const globalUser = useGlobalUser();
  const [inputDisabled, disableInput] = useState(false);
    const userData = useSelector((state: DefaultRootState) => state.promiseData.data.userData);
const dispatch = useDispatch();

    const animateRotation = () => {
      Animated.timing(rotationValue, {
        toValue: 0, // End at 0 degrees
        duration: 300, // Animation duration in milliseconds
        useNativeDriver: true, // Use the native driver for performance
      }).start();
    };

    const animateRotationOut = () => {
      Animated.timing(rotationValue, {
        toValue: 90,
        duration: 300,
        useNativeDriver: true
      }).start(() => { setTimeout(() => setModalDimensions({ width: 500, height: 700 }), 1000); setChatStarted(false) });
    }

    const animateFade = () => {
      Animated.timing(fadeValue, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: false
      }).start();
    }

    const animateRightValue = () => {
      const targetRightValue = isMessageOpen ? -200 : -40;

      Animated.timing(rightValue, {
        toValue: targetRightValue,
        duration: 400,
        useNativeDriver: false, // Don't use native driver for 'right' property
      }).start();
    };



    useEffect(() => { setCount(count + 1) }, [visibleState])

    const handleHelp = useCallback(() => {
      console.log('do something without refreshing');
    }, []);

    useEffect(() => {
      if (visibleState) {
        animateRotation();
        animateRightValue();
        animateFade();
      }
    }, [isMessageOpen, visibleState]);

    const addDisclaimer = () => {
      return new Promise<void>((resolve, reject) => {
        setChatMessages(previous => GiftedChat.append(previous, [{
          _id: Math.random().toString(36).substring(7),
          text: "AI generated responses are not always accurate, please use caution when using this feature.",
          system: true,
          createdAt: new Date(),
          user: { _id: "AI" }
        }]))
        resolve();
      })
    }

  const _handleQuery = useCallback(async (query) => {
    setModalDimensions({ width: 700, height: screenHeight * 0.8 });
    if (!chatMessages.length) {
      addDisclaimer();
    }
      if (!AIHelp) {
      setAIHelp(true);
      }
      if (!chatStarted) {
      setChatStarted(true);
      }
      setChatMessages(previousMessages => GiftedChat.append(previousMessages, [{ _id: Math.random().toString(36).substring(7), text: query, createdAt: new Date(), user: { _id: globalUser, avatar: userData.avatar?.img } }]));
    disableInput(true);
    await fetch(`${AI_CHAT_API_URL}/api/generate`, {
      credentials: __DEV__ ? 'omit' : 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: "llama3",
        prompt: query,
        stream: false,
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedData = '';

        const processStream = async () => {
          const uid = uniqueId();
      console.log('uid', uid);

    // Create a message with the ID
    setChatMessages(prevMessages => GiftedChat.append(prevMessages, [{
      _id: uid,
      text: '',
      createdAt: new Date(),
      system: true
    }]));

    while (true) {
      const { done, value } = await reader.read();
      if (done) break; // Stream is finished

      // Decode and accumulate the data
      accumulatedData += decoder.decode(value, { stream: true });

      // Attempt to parse the accumulated data
      try {
        // Use debounce to limit calls to disableInput
        debounce(() => disableInput(true), 500)();

        // Parse JSON
        let data;
        try {
          data = JSON.parse(accumulatedData);
          accumulatedData = ''; // Reset data once successfully parsed
        } catch (err) {
          // If JSON parsing fails, skip processing and continue accumulating
          continue;
        }

        // Update message text
        setChatMessages(prevMessages => {
          const updatedMessages = prevMessages.map(message => {
            if (message._id === uid) {
              return { ...message, text: message.text + data.response };
            }
            return message;
          });

          // Add the message if it doesn't exist
          if (!updatedMessages.some(message => message._id === uid)) {
            updatedMessages.push({
              _id: uid,
              text: data.response,
              createdAt: new Date(),
              system: true
            });
          }

          return updatedMessages;
        });

      } catch (err) {
        // Log error if needed
        console.error('Error processing data:', err);
      }
    }

    // Finalize any remaining data after stream ends
    if (accumulatedData) {
      try {
        const data = JSON.parse(accumulatedData);
        setChatMessages(prevMessages => {
          const updatedMessages = prevMessages.map(message => {
            if (message._id === data.id) {
              return { ...message, text: message.text + data.response };
            }
            return message;
          });

          if (!updatedMessages.some(message => message._id === data.id)) {
            updatedMessages.push({
              _id: data.id,
              text: data.response,
              createdAt: new Date(),
              system: true
            });
          }

          return updatedMessages;
        });

      } catch (err) {
        console.error('Error parsing final JSON:', err);
        // setChatMessages(prevMessages =>
        //   GiftedChat.append(prevMessages, [{
        //     _id: uniqueId(),
        //     text: 'Sorry, I am not able to answer that right now',
        //     createdAt: new Date(),
        //     system: true
        //   }])
        // );
      }
    }
  };

        processStream().finally(() => {
          disableInput(false);
  });
      })
      .catch(err => {
        console.error(err);
        setChatMessages(previousMessages =>
          GiftedChat.append(previousMessages, [
            {
              _id: Math.random().toString(36).substring(7),
              text: 'Sorry, I am not able to answer that right now',
              createdAt: new Date(),
              system: true
            }
          ])
        );
      });
  }, [chatMessages, GiftedChat, helpSearch, AIHelp, chatStarted, globalUser, userData.avatar?.img]);

    const handleClose = useCallback(() => {
      console.log('requested close');
      animateRotationOut();
    }, []);

    const getTransform = () => {
      let transform = {
        transform: [{ perspective: 0 }, {
          rotate: rotationValue.interpolate({
            inputRange: [0, 90],
            outputRange: ['0deg', '90deg'], // Interpolate between 0 and 90 degrees
          }),
        }]
      }
      return withAnchorPoint(transform, { x: 1, y: 1 }, { width: modalDimensions.width, height: modalDimensions.height })
    }

    const handleFlip = useCallback((selection) => { setFlip(!flip); if (selection) { setSelection(selection) } }, [])

    return (
      <Modal
        isVisible={visibleState}
        animationIn={'fadeIn'}
        animationOut={'fadeOutDown'}
        onModalWillShow={() => {
          animateRotation();
          animateRightValue();
        }}
        onModalWillHide={() => { animateRotationOut() }}
        onBackdropPress={() => { handleClose(); setTimeout(() => dispatch({ type: HIDE_HELP_MODAL, payload: null }), 600) }}
        statusBarTranslucent
        deviceHeight={height}
        deviceWidth={width}
        hasBackdrop={false}
        coverScreen={false}
      >
        <Animated.View style={[styles.blurViewStyle, { opacity: fadeValue.interpolate({ inputRange: [0, 1], outputRange: [0, 1] }) }]}>
          <BlurView style={[styles.blurViewStyle, { opacity: fadeValue.interpolate({ inputRange: [0, 1], outputRange: [0, 1] }) }]} />
        </Animated.View>
        <Animatable.View
          transition={['right', 'width', 'height']}
          duration={!isMessageOpen ? 500 : 200}
          style={[getTransform(), {
            position: 'absolute',
            height: modalDimensions.height,
            width: modalDimensions.width,
            backgroundColor: 'white',
            borderRadius: 30,
            borderBottomRightRadius: 0,
            borderWidth: 10,
            borderColor: 'rgb(90,192,234)',
            right: rightValue + toolbarVisible ? (isMessageOpen ? -50 : -200) : (isMessageOpen ? -200 : -80),
            bottom: 5,
            overflow: 'visible'
          }]}
        >
          <Pressable style={{ left: -15, top: -15, position: 'absolute', backgroundColor: 'red', borderRadius: 100, width: 30, height: 30, zIndex: 1, shadowRadius: 40, shadowOpacity: 0.6 }} onPress={() => { handleClose(); setTimeout(() => dispatch({ type: HIDE_HELP_MODAL, payload: null }), 600) }}>
            <AntDesign name="close" size={24} color="white" style={[styles.centerEverything, { padding: 3 }]} />
          </Pressable>
          <LinearGradient colors={['black', 'transparent']} style={{ height: 150, padding: 20, borderTopStartRadius: 20, borderTopEndRadius: 20, flexDirection: 'row', gap: 10 }}>
            <SSLogo height={'110%'} width={'110%'} viewBox={'0 0 1500 1500'} style={{ flex: 1 }} />
            <View style={{ flex: 3 }}>
              <Title style={{ color: 'white' }}>SmartSaaS Leaf</Title>
              <Text style={{ color: 'white' }}>v{Constants.expoConfig?.version}</Text>
              <Text style={{ color: 'white' }}>Showing topics for {currentScreen}</Text>
            </View>
          </LinearGradient>
          {
            chatStarted ?
              <>
                <Pressable
                  onPress={() => { setChatStarted(false); setModalDimensions({ width: 500, height: 700 }) }} style={{ position: 'absolute', top: 40, right: 30, borderRadius: 40, backgroundColor: 'transparent' }}>
                  <AntDesign name="closecircle" size={20} color="red" />
                </Pressable>
                <GiftedChat
                  messages={chatMessages}
                  key={"helpChat"}
                  showUserAvatar
                  minInputToolbarHeight={0}
                  renderInputToolbar={() => (<View />)}
                  renderSystemMessage={props => {
                    return (
                      <Animatable.View style={{ flexDirection: "row", marginBottom: 10 }} animation={'bounceIn'}>
                        <AnimatedLottieView source={require("@assets/animations/automation_robot.json")} autoPlay loop style={{ left: 10, width: 30, alignSelf: "flex-end", justifyContent: "flex-end" }} />
                        <View
                          style={[props.containerStyle, { marginHorizontal: 25, borderRadius: 10, padding: 10, paddingHorizontal: 10, shadowRadius: 20, shadowOpacity: .2, maxWidth:"90%" }]}
                        >
                          <AnimateMarkup style={{ lineHeight: 30 }} disabledAnimation={props.currentMessage.received} onAnimationComplete={() => {
                            //Mark current message as received
                            setChatMessages(previousMessages => {
                              const index = previousMessages.findIndex((message) => message._id === props.currentMessage._id);
                              const newMessages = [...previousMessages];
                              newMessages[index] = { ...newMessages[index], received: true };
                              return newMessages;
                            })
                            disableInput(false);
                          }} fontSize={15} value={props.currentMessage.text} speed={20} />
                          <Text style={{ fontSize: 10 }}>{moment(props.currentMessage.createdAt).format("HH:MM a")}</Text>
                        </View>
                      </Animatable.View>
                    );
                  }}
                  user={{ _id: globalUser, avatar: userData.avatar?.img }}
                  renderAvatar={(props) => {
                    return (
                      <Image source={{ uri: props.currentMessage.user.avatar }} style={{ height: 30, width: 30, borderRadius: 30 }} />
                    )
                  }
                  }
                />
              </>
              :
              <FlipCard
                clickable={false}
                flipVertical={false}
                flip={flip}
                flipHorizontal={true}
                style={{ flex: 6 }}
              >
                <View style={[styles.face, { flex: 1, alignSelf: 'stretch', minHeight: '100%' }]}>
                  <View style={{ height: '100%', justifyContent: 'center', alignSelf: 'stretch', alignContent: 'center', alignItems: 'center', padding: 15 }}>
                    <ShowHelp searchTerm={helpSearch || currentScreen} handleFlip={handleFlip} />
                  </View>
                </View>
                <View style={[styles.back, { flex: 1, padding: 10, alignSelf: 'stretch', minHeight: '100%' }]}>
                  <HelpChat selection={selection} flip={() => setFlip(!flip)} />
                </View>
              </FlipCard>
          }
          <View style={{ alignSelf: "stretch", alignContent:"flex-end", minWidth: "99%", flex: 1, maxHeight: 70, }}>
            <MyInputToolbar disableEntry={inputDisabled} messageInput removeTools customSendIcon={(<AnimatedLottieView source={require("@assets/animations/automation_robot.json")} style={{ width: 30 }} autoPlay loop />)} containerStyle={{ shadowRadius: 5, shadowOpacity: 0.4, borderRadius: 10, borderBottomEndRadius: 0 }} sendMessageCallback={(query) => _handleQuery(query)} placeHolder={"Search for anything"} />
          </View>
        </Animatable.View>
      </Modal >
    );
  };


export default HelpModal;
