import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1252.98 1254.51"
      width={21}
      height={21}
      {...props}
      {...props}
    >
      <Path
        d="M1131.66 1251.02c.27-.96 1.15-.95 2-1M1251.57 1131.11c.27-1.47-.77-4.14 1-5M1.51 1129.11c.01 2-.01 4 0 6M119.42 1250.02c.7.26 1.52.18 2 1M.51 619.51c.28.78.23 1.24 0 1.53-.01.49-.04.98-.08 1.46H3.5v2.92c.33.01.66.02.99.04.17-6.03-1.09-2.22-4-5.96z"
        fill="#fff"
      />
      <Path
        d="M1252.65 400.12c-3.02-3.5-2.5-9.17-3.76-14.24-15.49-62.43-66.02-105.43-130.68-109.14l-213.39.05V127.41C901.61 63.93 853.8 9.86 790.9.5L461.16 0l-17.98 4.5c-52.21 15.68-90.57 63.4-94.97 117.86l.05 154.43-213.39-.05C65.21 280.33 11.25 330.92.61 399.28l-.1 220.24c2.91 3.74 4.17-.07 4 5.96.17.01.33.03.5.04l1243.01-.07c.06.01.1.05.14.09a26.445 26.445 0 014.2-.05 24.252 24.252 0 01.14-5.52l.15-219.85zM764.93 276.78H487.14V138.89h277.79v137.89zM1251.57 728.29v.14c.02 2.48.99 5.23 1 6v-6.18c-.34.02-.67.04-1 .05z"
        fill="#fff"
      />
      <Path
        d="M1.51 728.43H279.3v182.36c0 32.17 38.73 61.73 69.44 61.54 30.25-.19 68.45-29.92 68.45-61.54V728.43h417.69l-.04 173.41c5.54 92.96 133.43 93.06 138.98 0l-.05-173.41h277.8c.02 2.48.99 5.23 1 6 1.01 130.52-.15 261.16 0 391.69-1.77.86-.73 3.52-1 5-3.88 21.29-11.44 41.83-23.76 59.67-22.1 31.99-56.02 52.22-94.15 59.23-.85.05-1.73.04-2 1-167.01-.2-334.05.2-501.07-.07.08 4.68-8.07 4.81-8.09 0-167.02.25-334.07-.09-501.07.07-.48-.82-1.3-.74-2-1-6.14-2.27-13.33-2.84-19.75-4.73-50.97-15.05-88.08-58.37-98.16-110.18-.01-2 .01-4 0-6-.74-132.35-.66-265.34 0-397.69v-3zm627.53 519.58h-5.01v5.01h5.01v-5.01z"
        fill="#fff"
      />
      <Path d="M624.03 1248.01H629.04V1253.02H624.03z" fill="#fff" />
      <Path
        d="M.44 622.5a23.68 23.68 0 01-.44 3.04c.86-.08 1.73-.14 2.59-.14.31 0 .61.01.92.02v-2.92H.44z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
