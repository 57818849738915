import * as React from 'react';
import { Text as DefaultText, View as DefaultView, TextInput as DefaultTextInput, Easing, FlatList as DefaultFlatList, useWindowDimensions, Platform, TouchableWithoutFeedback, Keyboard, Pressable, PressableProps, VirtualizedList } from 'react-native';
import { Title as DefaultTitle, TitleProps as DefaultTitleProps, ProgressBar } from 'react-native-paper';
import { ScrollView as DefaultScrollView, Animated, StyleSheet, Dimensions } from 'react-native';
import DefaultModal, { ModalProps as DefaultModalProps } from 'react-native-modal';
import Colors from './Colors';
import { useColorScheme } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { BlurViewProps, BlurView as DefaultBlurView } from '@react-native-community/blur';
import styles from '../../stylesheet';
import { useState, useMemo } from 'react';
import * as Animatable from 'react-native-animatable';
import { DropDownPickerProps as DefaultDropDownPickerProps } from 'react-native-dropdown-picker';
import DefaultDropDownPicker from 'react-native-dropdown-picker';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import AnimatedLottieView from '@utils/lottieAnimation';
import { AntDesign } from '@expo/vector-icons';
import AchievementBackground from '@assets/--native/AchievementBackground';
import { use } from 'i18next';
import { HIDE_ALL_MODALS } from '@reduxLocal/actions/types';
import Checkbox, { CheckboxProps as DefaultCheckboxProps } from 'expo-checkbox';
import { SizableText, YStack } from 'tamagui';
import { indicatorColor } from 'react-native-calendars/src/style';

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  //TODO UNDO THIS ON DEV PREVIEW
  const isLight = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const theme = isLight ? 'light' : 'dark';
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
  extraChildren?: React.ReactNode;
  containerStyle?: Object;
};

type CustomFlatListProps = {
  contentHeight?: number;
}

type CustomTitleProps = {
  selectable?: boolean;
  underline?: boolean;
  bold?: boolean;
};

type StyledModalInternalProps = {
  title?: string,
  caption?: string,
  animated?: boolean,
  hideOverflow?: boolean,
  hideCloseButton?: boolean,
  animation?: string,
  infoChildren?: JSX.Element | JSX.Element[],
  customContent?: JSX.Element | JSX.Element[];
  extraChildren?: JSX.Element | JSX.Element[];
}

type ModalOnly = {
  modalRef?: React.RefObject<StyledModalHandle>;
}

type CustomViewProps = {
  withBackground?: boolean;
}

type CustomTextProps = {
  avoidCalc?: boolean;
}

export type TextProps = ThemeProps & CustomTextProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];
export type TitleProps = ThemeProps & DefaultTitleProps & CustomTitleProps;
export type TextInputProps = ThemeProps & DefaultTextInput['props'];
export type ModalProps = ThemeProps & DefaultModalProps & ModalOnly & { containerStyle?: Object } & { customContent?: JSX.Element | JSX.Element[] } & { onModalWillHide?: () => void } & { modalRef?: React.RefObject<StyledModalHandle> } & { animation?: string } & { infoChildren?: JSX.Element | JSX.Element[] } & { title?: string } & { caption?: string };
export type ScrollViewProps = ThemeProps & DefaultScrollView['props'];
export type DropDownPickerProps<T> = ThemeProps & DefaultDropDownPickerProps<T>['props'];
export type FlatListProps = ThemeProps & DefaultFlatList['props'] & CustomFlatListProps;
export type StyledModalProps = Partial<ModalProps & StyledModalInternalProps>;
export type CheckboxProps = ThemeProps & DefaultCheckboxProps;

export function Text(props: TextProps) {
  const { style, lightColor, avoidCalc, numberOfLines, children, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  let lineNum = 1;

  const calcLines = () => {
    const textLength = children?.toString().length;
    if (textLength) {
      if (textLength > 40) {
        return 3;
      } else if (textLength > 20) {
        return 2;
      }
    }
    return 1;
  }

  return Platform.OS != 'web' ?
    <DefaultText selectable={props.selectable ?? false} style={[{ flexWrap: "wrap", color, lineHeight: 30 }, style]} {...otherProps}>{children}</DefaultText>
    : <DefaultText numberOfLines={!avoidCalc && (numberOfLines ?? calcLines())} adjustsFontSizeToFit={props.adjustsFontSizeToFit ?? true} selectable={props.selectable ?? false} style={[{ flexWrap: "wrap", color, lineHeight: 30 }, style]} {...otherProps}>{children}</DefaultText>;
}

export function View(props: ViewProps & CustomViewProps) {
  const { style, lightColor, darkColor, withBackground, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return <Animatable.View transition={'backgroundColor'} style={[withBackground && { backgroundColor }, style]} {...otherProps} />;
}


export function FlatList(props: FlatListProps) {
  const { style, data, children, contentContainerStyle, showsVerticalScrollIndicator } = props;
  const windowHeight = Dimensions.get('window').height;
  const [viewHeight, setViewHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const scrollY = React.useRef(new Animated.Value(0)).current;
  const theme = themeSelector();

  const indicatorHeight = viewHeight / (contentHeight / viewHeight); // Adjust scroll indicator height
  const translateY = scrollY.interpolate({
    inputRange: [0, contentHeight - viewHeight],
    outputRange: [0, viewHeight - indicatorHeight],
    extrapolate: 'clamp',
  });

  const handleScroll = typeof props.onScroll === "function" ? props.onScroll : () => { };

  return (
    <Animated.View style={{ flex: 1, flexDirection: 'row', gap: 20 }}>
      <FlatList
        {...props}
        style={[scrollStyles.scrollView, { flex: 1, backgroundColor: 'transparent', width: '100%', height: '100%' }, style]}
        onContentSizeChange={(_, eventHieght) => { console.log(eventHieght.valueOf()); setViewHeight(eventHieght.valueOf()) }}
        contentContainerStyle={[scrollStyles.contentContainer, contentContainerStyle]}
        // onScroll={Animated.event(
        //   [{ nativeEvent: { contentOffset: { y: scrollY } } }],
        //   { useNativeDriver: true }
        // )}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
      </FlatList>
      {
        data?.length > 0 && viewHeight > (windowHeight) && showsVerticalScrollIndicator &&
        <View
          style={{
            backgroundColor: theme ? '#ececec' : 'grey',
            marginHorizontal: 10,
            minWidth: 10,
            paddingBottom: '20%',
            borderRadius: 999,
            overflow: 'hidden',
            marginVertical: "4%"
          }}
        >
          <Animated.View
              style={{
                width: '100%',
                position: 'absolute',
                height: indicatorHeight,
                transform: [{ translateY }],
                backgroundColor: indicatorColor || 'rgb(90,192,234)', // Adjust as needed
              }}
          />
          </View>
      }
    </Animated.View>
  )
}

export function ScrollView(props: ScrollViewProps & { inverse?: boolean, indicatorColor?: string }) {
  const { inverse } = props;
  const [viewHeight, setViewHeight] = useState(100);
  const [contentHeight, setContentHeight] = useState(200);
  const scrollY = React.useRef(new Animated.Value(0)).current;
  const theme = themeSelector();

  const indicatorHeight = contentHeight <= viewHeight
    ? viewHeight
    : viewHeight / (contentHeight / viewHeight || 1);
  const maxScroll = Math.max(1, contentHeight - viewHeight);  // Ensure positive range
  const translateY = scrollY.interpolate({
    inputRange: [0, maxScroll],
    outputRange: [0, Math.max(0, viewHeight - indicatorHeight)],
    extrapolate: 'clamp',
  });
  // Handle scroll event using e.nativeEvent to capture scroll data
  // Handle scroll event using e.nativeEvent to capture scroll data
  const handleScroll = (e) => {
    const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent;

    // Update the contentHeight and viewHeight
    setContentHeight(contentSize.height);
    setViewHeight(layoutMeasurement.height);

    // Update the scrollY value manually with the contentOffset.y
    scrollY.setValue(contentOffset.y);
  };

  return (
    <View style={[scrollStyles.container, { flexDirection: inverse ? 'row-reverse' : 'row' }]}>
      <DefaultScrollView
        {...props}
        style={[scrollStyles.scrollView, props.style]}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={(_, height) => setContentHeight(Math.max(height, viewHeight))}
        onLayout={(e) => setViewHeight(e.nativeEvent.layout.height)}
        onScroll={handleScroll}
        scrollEventThrottle={16}
      >
        {props.children}
      </DefaultScrollView>
      <View
        style={{
          backgroundColor: theme ? '#ececec' : 'grey',
          marginHorizontal: 10,
          minWidth: 10,
          paddingBottom: '20%',
          borderRadius: 999,
          overflow: 'hidden',
          marginVertical: "4%"
        }}
      >
        <Animated.View
          style={{
            width: '100%',
            position: 'absolute',
            height: indicatorHeight,
            transform: [{ translateY }],
            backgroundColor: indicatorColor || 'rgb(90,192,234)', // Adjust as needed
          }}
        />
      </View>
    </View>
  );
};

const scrollStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  contentContainer: {
    paddingVertical: 20,
    flex: 1,
  },
  scrollIndicator: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 3, // Adjusted width of the scroll indicator
    height: '100%', // Adjusted height of the scroll indicator
    backgroundColor: 'rgb(90,192,234)', // Change to your desired color
    borderRadius: 999,
  },
});



export function Title(props: TitleProps) {
  const { style, lightColor, darkColor, bold, underline, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const theme = themeSelector();

  return <DefaultTitle selectable={props.selectable || false} style={[{ color: theme ? "black" : "white", borderBottomWidth: underline ? 1 : 0, fontWeight: bold ? 'bold' : undefined }, style]} {...otherProps} />;
}

const DismissKeyboard: React.FC<PressableProps> = ({ children, hitSlop }) => {
  const handlePress = () => Keyboard.dismiss();

  return (
    <Pressable
      onPress={handlePress}
      style={{ flex: 1 }}
      hitSlop={{ top: hitSlop, bottom: hitSlop, left: hitSlop, right: hitSlop }}
    >
      {children}
    </Pressable>
  );
};

export const TextInput: React.FC<TextInputProps> = React.memo((props: TextInputProps) => {
  const { style, lightColor = 'black', darkColor = 'white', multiline, hitSlop, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const [isFocused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const textInputProps = {
    style: [styles.inputOutline, { marginHorizontal: 0, color, style }],
    cursorColor: color,
    onFocus,
    onBlur,
    ...otherProps,
  };

  return multiline ? useMemo(() => (
    <DismissKeyboard hitSlop={isFocused ? 1000 : 0}>
      <DefaultTextInput {...textInputProps} />
    </DismissKeyboard>
  ), []) : useMemo(() => (
    <DefaultTextInput {...textInputProps} />
  ), []);
});

export function CheckBox(props: CheckboxProps) {
  return (
    <Checkbox
      style={{ backgroundColor: 'transparent' }}
      color={useThemeColor({ light: 'black', dark: 'white' }, 'text')}
      {...props}
    />
  )
}

export function BlurView(props: BlurViewProps) {
  const isLight = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const theme = isLight ? 'light' : 'dark';
  const { style, ...otherProps } = props;

  return (<DefaultBlurView blurRadius={20} blurType={props.blurType || theme} style={[{ backgroundColor: Platform.select({ android: "transparent", default: 'transparent' }) }, style]} {...otherProps} />)
}

export function DropDownPicker<T>(props: DropDownPickerProps<T> & DropDownPickerMultipleProps<T>) {
  const theme = themeSelector();
  const { style, ...otherProps } = props;

  return <DefaultDropDownPicker
    style={[style, { backgroundColor: theme ? 'white' : '#333333' }]}
    dropDownContainerStyle={[otherProps.dropDownContainerStyle, { backgroundColor: theme ? 'white' : '#333333' }]}
    textStyle={[otherProps.textStyle, { color: theme ? 'black' : 'white' }]}
    selectedItemContainerStyle={[otherProps.selectedItemContainerStyle, { backgroundColor: theme ? 'white' : '#333333' }]}
    theme={theme ? "LIGHT" : "DARK"}
    {...otherProps}
  />;
}

export function Modal(props: ModalProps & { setVisible: (visible: boolean) => void }) {
  const { extraChildren, containerStyle, style, animationIn, animationOut, hasBackdrop, coverScreen, backdropColor, backdropOpacity, deviceHeight, deviceWidth, children, ...otherProps } = props;
  const theme = themeSelector();
  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    //Add listenered for escape key to close modal
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (otherProps.isVisible) {
          otherProps.setVisible?.(false);
        } else {
          props.modalRef.current?.closeModal();
        }
      }
    }
    if (Platform.OS === 'web') {
    window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (Platform.OS === 'web') {
      window.removeEventListener('keydown', handleKeyDown);
      }
    }
  })

  return <DefaultModal
    animationIn={'fadeIn'}
    animationOut={'zoomOutUp'}
    deviceWidth={width * 2}
    deviceHeight={height}
    coverScreen={true}
    style={[styles.centerEverything, { zIndex: 300, flex: 1, gap: 15, maxWidth: Platform.OS === 'web' ? "60%" : undefined, alignItems: "center" }, style]}
    {...otherProps}>
    {
      Platform.OS != 'android' &&
      <BlurView
        style={{ flex: 1, alignSelf: "center", minHeight: height * 1.4, minWidth: width * 1.4, paddingVertical: 50, padding: 20, position: "absolute" }}
        blurAmount={5}
        reducedTransparencyFallbackColor="white"
      />
    }
    <View style={[{ backgroundColor: theme ? "white" : "#333333", borderRadius: 20, margin: 20, alignSelf: "center", justifyContent: "center", alignContent: "center", alignItems: "center", padding: 0, flex: 1, minHeight: '80%', minWidth: "100%", overflow: "visible" }, containerStyle]}>
      {children}
    </View>
    {
      extraChildren &&
      <View style={{ maxWidth: "100%", backgroundColor: 'transparent', flex: 1, minHeight: 70 }}>
        {extraChildren}
      </View>
    }
  </DefaultModal>;
}


export type LoadingModalHandle = {
  openModal: () => void;
  closeModal: () => void;
}

export const LoadingModal = React.forwardRef((props: ModalProps, ref) => {
  const { extraChildren, containerStyle, style, ...otherProps } = props;
  const { height, width } = useWindowDimensions();
  const [isVisible, setVisible] = useState(false);

  React.useImperativeHandle(ref, () => ({
    openModal: () => {
      setVisible(true);
    },
    closeModal: () => {
      setVisible(false);
    }
  }));

  return <DefaultModal
    animationIn={'fadeIn'}
    animationOut={'zoomOutUp'}
    deviceWidth={width}
    isVisible={isVisible}
    deviceHeight={height}
    coverScreen={true}
    hasBackdrop={true}
    style={[styles.centerEverything, { flex: 1, gap: 15, maxWidth: Platform.OS === 'web' ? "60%" : undefined, alignItems: "center" }]}
    {...otherProps}>
    {
      Platform.OS != 'android' &&
      <BlurView
        style={{ flex: 1, alignSelf: "center", minHeight: height, minWidth: width * 1.2, paddingVertical: 50, padding: 20, position: "absolute" }}
        blurAmount={5}
        reducedTransparencyFallbackColor="white"
      />
    }
    <View style={[{ borderRadius: 20, maxWidth: 350, maxHeight: 400, margin: 20, marginVertical: 70, alignSelf: "center", justifyContent: "center", alignContent: "center", alignItems: "center", padding: 0, flex: 1, minHeight: '80%', minWidth: "100%", overflow: "visible" }, containerStyle]}>
      <View style={styles.centerEverything}>
        <AnimatedLottieView source={require("@assets/animations/leaf_Loader.json")} style={{ flex: 3, height: 300, width: 300 }} loop autoPlay />
        <ProgressBar indeterminate style={{ flex: 1, borderRadius: 10, maxHeight: 15, }} />
      </View>
    </View>
    {
      extraChildren &&
      <View style={{ backgroundColor: 'transparent', flex: 1, minHeight: 70 }}>
        {extraChildren}
      </View>
    }
  </DefaultModal>;
})

export type StyledModalHandle = {
  openModal: (item?: any) => void;
  closeModal: () => void;
  animateView: () => void;
  openAnimated: () => Promise<void>;
}

export const StyledModal = React.forwardRef<StyledModalHandle, StyledModalProps>((props: StyledModalProps, ref) => {
  const { title, children, animated, caption, animation, infoChildren, customContent, ...otherProps } = props;
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const theme = themeSelector();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [animate, setAnimate] = useState(false);
  const animationRef = React.useRef<Animatable>(null);

  React.useEffect(() => {
    //Add listenered for escape key to close modal
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (otherProps.isVisible) {
          otherProps.setVisible?.(false);
        } else {
          try{
          props.modalRef.current?.closeModal?.();
          } catch {
            setVisible(false);
          }
        }
      }
    }
    if(Platform.OS === 'web') {
    window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if(Platform.OS === 'web') {
      window.removeEventListener('keydown', handleKeyDown);
      }
    }
  })


  React.useEffect(() => {
    //Add key listener event for escape to close modal
    if (props.animated) {
      animationRef.current?.slideInRight(200);
    }
  }, [visible])

  React.useImperativeHandle(ref, () => ({
    openModal: (item) => {
      setVisible(true);
      if (item) {
        setItem?.(item);
      }
    },
    closeModal: () => {
      setVisible(false);
    },
    animateView: () => {
      animationRef.current?.slideOutLeft(200).then(() => {
        animationRef.current?.slideInRight(200);
      })
    },
    openAnimated: async () => {
      return new Promise<void>((resolve) => {
        console.log('opening animated modal');
        setTimeout(() => {
          setAnimate(true);
          resolve();
        }, 200);
      });
    }
  }));

  if(!visible) {
    return null;
  }

  return (
    <Modal style={{ paddingVertical: Platform.select({ default: "5%", web: undefined }), zIndex: 100 }} {...otherProps} onModalWillHide={() => dispatch({ type: HIDE_ALL_MODALS })} isVisible={visible} containerStyle={{ minWidth: Platform.select({ web: "70%", default: "95%" }), zIndex: 100, paddingHorizontal: 0, marginVertical: Platform.select({ web: "5%", default: "0%" }) }}>
      <View style={{ flexDirection: "row", minHeight: Platform.select({ web: "130%", default: undefined }), backgroundColor: theme ? "white" : "grey", flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: 20, minWidth: Platform.OS === 'web' ? "60%" : "115%", zIndex: 0 }}>
        {
          !props.hideCloseButton &&
          <Pressable onPress={() => setVisible(false)} style={{ zIndex: 1, position: 'absolute', top: -15, right: -15, borderRadius: 40 }}>
            <AntDesign name="closecircle" size={40} color="red" />
          </Pressable>
        }
        {
          Platform.OS === 'web' &&
          <View style={{ flex: 1, zIndex: 5, maxWidth: 600, maxHeight: "90%", backgroundColor: "transparent", minHeight: "110%", shadowRadius: 20, shadowOpacity: .5, borderTopStartRadius: 20, borderBottomStartRadius: 20, left: "-4%" }}>
            <View style={{ overflow: "hidden", flex: 1, borderTopStartRadius: 20, borderBottomStartRadius: 20 }}>
              <AchievementBackground style={{
                flex: 1,
                resizeMode: "cover",
                opacity: 1,
                minWidth: 600,
                transform: [{ scale: 2.5 }],
                zIndex: -1,
                minHeight: "150%"
              }}>
              </AchievementBackground>
            </View>
              <View style={{ zIndex: 4, top: 30, backgroundColor: '#00000066', maxHeight: "80%", minHeight: "92%", justifyContent: "space-between", marginRight: Platform.select({ web: 30, default: 0 }), borderRadius: 20, borderTopStartRadius: 0, borderBottomStartRadius: 0, flex: 1, position: "absolute", gap: 20, padding: 20, paddingVertical: 0, alignSelf: "stretch", minWidth: "100%", overflow: props.hideOverflow ? "hidden" : undefined }}>
              {
                customContent ?
                  customContent :
                  <View style={{ flex: 1, padding: 20 }}>
                      <Title style={{ flex: 1, textAlign: "center", lineHeight: 24, fontSize: 24, color: "white" }}>{title || "Enter title"}</Title>
                      <SizableText style={{ textAlign: 'center', flex: 1, color: "white" }}>{caption || "Enter Caption here"}</SizableText>
                    <AnimatedLottieView source={animation || require('@assets/animations/91620-jumping-lottie-animation.json')} autoPlay loop style={{ width: "100%", flex: 3, borderRadius: 20 }} />
                    {
                      infoChildren
                    }
                  </View>
              }
            </View>
          </View>
        }
        <Animatable.View transition={['maxWidth', 'flex', 'width']} duration={1500} style={{ padding: Platform.select({ web: 20, default: 5 }), left: Platform.select({ web: !animate && animated ? "-10%" : "-3%", default: "0.2%" }), borderTopEndRadius: 20, borderBottomEndRadius: 20, zIndex: -1, flex: !animate && animated ? 0.0001 : 1, minHeight: "75%", maxHeight: "90%", alignSelf: "center", minWidth: Platform.select({ web: undefined, default: "90%" }), width: !animate && animated ? 0 : 400, maxWidth: !animate && animated ? 0 : 600 }}>
          {
            Platform.OS != 'web' &&
            <YStack style={{ flex: .2, paddingLeft: Platform.select({ web: 20, default: undefined }), paddingHorizontal: 10, top: -50, gap: 10 }}>
              <SizableText style={{ minHeight: 20, flex: 1, textAlign: "center", lineHeight: 24, fontSize: 24 }}>{title || "Enter title"}</SizableText>
              <SizableText textAlign='center' wordWrap='normal' textWrap='wrap' numberOfLines={5} >{caption || "Enter Caption here"}</SizableText>
            </YStack>
          }
          {
            children
          }
        </Animatable.View>
      </View>
    </Modal>
  )
});
