import React, { useMemo } from 'react';
import { View, Pressable, Keyboard, Platform } from 'react-native';
import { FastField, Field, FieldProps } from 'formik';
import AnimatedLottieView from '@utils/lottieAnimation';
import HandleFieldType from './handleFieldType';
import ErrorBoundary from '@utils/ErrorBoundary';
import { Separator } from 'tamagui';
import { uniqueId } from 'lodash';

export const GetField = ({ blackText, otherVals, item, field, allItems, index, nextFieldFunction, setInputRef }) => {
  return (
    <HandleFieldType
      blackText={blackText}
      setRef={(fieldName, ref) => setInputRef?.(fieldName, ref)}
      nextFieldFunction={(index, length) => { nextFieldFunction(allItems[index + 1]?.type, index, length) }}
      otherVals={otherVals}
      index={index}
      fieldProps={field}
      schemaItem={item}
      length={allItems?.length}
    />
  )
}

const Row = ({ blackText, otherVals, items, allItems, index, nextFieldFunction, setInputRef, column }) => {
  return (
    <View style={{ flex: 1, flexDirection: column ? 'column' : 'row', minWidth: "100%", alignSelf: "stretch", gap: 15, padding: 5, backgroundColor: "transparent", zIndex: index * -1 }}>
      {items.map((item, index) => {
        if (item.content?.reliesOn) {
          return (
            <Field key={index} id={uniqueId(item.title)} name={item.title}>
              {(fieldProps: FieldProps) => {
                if (fieldProps.form.values[item.content.reliesOn] === item.content.reliesOnValue) {
                  return (
                    <Pressable style={{ zIndex: index * -1, alignSelf: "stretch", flexDirection: "row", flex: 1, gap: 10 }} disabled={Platform.OS === 'web'} onPress={() => Keyboard.dismiss()} hitSlop={10}>
                      <View style={{ position: "absolute", backgroundColor: "transparent", minWidth: "10%", maxWidth: 60, left: -10, top: 50 }}>
                        {
                          item.content?.validation?.required &&
                          <View style={{ maxWidth: 40, backgroundColor: "transparent" }}>
                            {
                              fieldProps.meta.touched && !fieldProps.meta.error ?
                                <AnimatedLottieView source={require("@assets/animations/registerSuccess.json")} style={{ flex: 1 }} autoPlay />
                                :
                                <AnimatedLottieView colorFilters={[{
                                  keypath: "layers.1.shapes.0.it.1.c.k", color: "red"
                                }]} source={require("@assets/animations/exclamation-mark.json")} style={{ flex: 1 }} autoPlay />
                            }
                          </View>
                        }
                      </View>
                      <View style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent" }}>
                        <ErrorBoundary>
                          <GetField otherVals={otherVals} item={item} field={fieldProps.field} allItems={allItems} index={index} nextFieldFunction={nextFieldFunction} setInputRef={setInputRef} />
                          {
                            item.content?.parent && <Separator />
                          }
                        </ErrorBoundary>
                      </View>
                    </Pressable>
                  )

                }
              }
              }
            </Field>
          )
        } else {
          return (
        <FastField key={index} id={item.title} name={item.title}>
          {(fieldProps: FieldProps) => {
            
            return (
              <Pressable style={{ zIndex: index * -1, alignSelf: "stretch", flexDirection: "row", flex: 1, gap: 10 }} disabled={Platform.OS === 'web'} onPress={() => Keyboard.dismiss()} hitSlop={10}>
                <View style={{ position: "absolute", backgroundColor: "transparent", minWidth: "10%", maxWidth: 60, left: -10, top: 50 }}>
                  {
                    item.content?.validation?.required &&
                    <View style={{ maxWidth: 40, backgroundColor: "transparent" }}>
                      {
                        fieldProps.meta.touched && !fieldProps.meta.error ?
                          <AnimatedLottieView source={require("@assets/animations/registerSuccess.json")} style={{ flex: 1 }} autoPlay />
                          :
                          <AnimatedLottieView colorFilters={[{
                            keypath: "layers.1.shapes.0.it.1.c.k", color: "red"
                          }]} source={require("@assets/animations/exclamation-mark.json")} style={{ flex: 1 }} autoPlay />
                      }
                    </View>
                  }
                </View>
                <View style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent" }}>
                  <ErrorBoundary>
                    <GetField blackText={blackText} otherVals={otherVals} item={item} field={fieldProps.field} allItems={allItems} index={index} nextFieldFunction={nextFieldFunction} setInputRef={setInputRef} />
                    {
                      item.content?.parent && <Separator />
                    }
                  </ErrorBoundary>
                </View>
              </Pressable>
            )

          }}
        </FastField>
          )
        }
      })}
    </View>
  )
};

export default Row;