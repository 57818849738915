import { Dimensions, Platform, ScrollView, View, useWindowDimensions, Pressable } from 'react-native';
import React, { useEffect, useRef, useState, memo, useCallback, Suspense, useContext } from "react";
import styles from "@stylesheet";
import Menu from "../Menu/Menu";
import Messages from "../screens/MidLevel/MessageScreen/Messages";
import LangModal from "../../components/modals/LanguageModal/LangModal";
import HelpModal from "../../components/modals/HelpModal/HelpModal";
import AchievementModal from "../../components/modals/AchievementModal/AchievementModal";
import MessageModal from "../../components/modals/ErrorModal/MessageModal";
import AddCompanyModal from "../../components/modals/AddCompanyModal/AddCompanyModal";
import CommModal from "../../components/modals/CommunityModal/CommModal";
import FooterBar from "../FooterBar/FooterBar";
// import AIHandler from "../AIHandler/AIHandler";
import FeedbackModal from "../../components/modals/FeedbackModal/FeedBackModal";
import NotificationModal from '../../components/modals/NotificationModal/NotificationModal';
import { ToolbarContext } from '@hooks/toolbarContext';
import MailLoginModal from '../screens/MidLevel/MailScreen/Components/MailLoginModal';
import UpdateMessageModal from './Components/UpdateMessageModal';
import apis from '@api/api';
import { useQuery, useQueryClient } from 'react-query';
import HeaderBar from '@navigation/HeaderBar/HeaderBar';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_PAYMENT_REQUIRED, SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import LogViewer from '@navigation/logViewer';
import Terminal from 'react-console-emulator'
import { useNavigation } from '@react-navigation/native';
import NetInfo from '@react-native-community/netinfo';
import LottieView from '@utils/lottieAnimation';
import PackageModal from '../../navigation/NavigationStacks/Components/PackageModal';
import { Paragraph, XStack } from 'tamagui';
import { ColorSchemeContext } from '../../../App';
import { Text } from '@constants/Themed';
import * as Animatable from 'react-native-animatable';
import { Button } from 'tamagui';
import { AntDesign } from '@expo/vector-icons';
import { googleAuths } from '@reduxLocal/selectors/userSelectors';

const TutorialModal = React.lazy(() => import('@components/modals/TutorialModal/TutorialModal'));
const DashboardStack = React.lazy(() => import('./DashboardStack'));

const DashboardNavigator = ({ navigation }) => {
  const dispatch = useDispatch();
  const paymentRequired = useSelector((state: any) => state.app?.paymentRequiredVisible);

  console.log("called dashboard");

  const user = useSelector((state: any) => state.promiseData.data.userData);
  const { billingId, subscriptionId } = user?.billing ?? {};
  const isConnected = NetInfo.fetch().then(state => state.isConnected);
  const packageModalRef = useRef(null);
  const googleObject = googleAuths();
  const googleId = googleObject?.googleId;
  const googleToken = googleObject?.accessToken;

    useQuery({
      queryKey: ['integrationData'],
      queryFn: async () => {
        const responseData = await apis.getIntegrations().then((res) => res);
        return responseData;
      }
    });

  useQuery({
    queryKey: ['userMailCount'],
    queryFn: async () => {
      await apis.getGmailCount(googleId, googleToken);
    }
  })

  useQuery({
    queryKey: ['invitations'],
    queryFn: async () => {
      const res = await apis.getInvitations();
      return res;
    },
    staleTime: 60 * 1000 * 5,  // Optional: control how long the data is fresh
    refetchOnWindowFocus: false, // Prevent refetching when the window regains focus
    cacheTime: 60 * 1000 * 5,  // Optional: control how long the data is cached
    retry: 1
  });

    const { status, data: subscriptionData, error, isFetching, isLoading: currentPlanLoading } = useQuery({
      queryKey: ['subscriptionPlan'],
      queryFn: async () => {
        const data = await apis.getSubscriptions({ billingId: billingId, subscriptionId: subscriptionId })
        return data
      },
    });

    if (!user.id && !__DEV__) {
      navigation.navigate('Login');
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: "Please login to continue", type: "warning" } });
      return;
    }

    if (!isConnected && !__DEV__) {
      navigation.navigate('Login');
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: "No internet connection", type: "warning" } });
      return;
    }

    //Determine width of overall app
    const [ref, setRef] = useState(null);
    const updatePending = false;
    const [optionsRef, setOptionsRef] = useState(null);
    const [reportRef, setReportRef] = useState(null);
    const [calendarContext, setCalendarContext] = useState(null);
    const [hierarchySocket, setHierarchySocket] = useState(null);
    const [projectContext, setProjectContext] = useState(null);
    const [calendarHeader, setCalendarHeader] = useState(null);
    const [draggedItem, setDraggedItem] = useState(null);
    const toolbar = { calendarHeader, setCalendarHeader, calendarContext, setCalendarContext, hierarchySocket, setHierarchySocket, draggedItem, setDraggedItem, ref, setRef, optionsRef, setOptionsRef, reportRef, setReportRef, projectContext, setProjectContext };
    const theme = themeSelector();
    const logRef = useRef(null);
    const [logCount, setLogCount] = useState(0); //Initialise log count
    const logVisible = useSelector((state: any) => state.UI.advancedMode);
    const { colorScheme } = useContext(ColorSchemeContext);
    //Initialise app with no notifications
    // dispatch({ type: CLEAR_NOTIFICATIONS });
    useEffect(() => {
      if (logRef.current) {
        logRef.current?.refresh();
        setLogCount(logRef.current?.getLogCount());
      }
    }, [])

    return (
      <ToolbarContext.Provider value={toolbar}>
        <View style={{ flexDirection: "row", flexGrow: 1, maxHeight: "100%" }}>
          {
            logVisible && Platform.OS === 'web' &&
            <Animatable.View transition={"minWidth"} style={{
              minWidth: logVisible ? 450 : 0
              , flex: logVisible ? 0.1 : 0.000001
            }}>
              <View style={{ flex: 8, maxHeight: Dimensions.get('window').height * 0.7, padding: 10 }}>
                <LogViewer ref={logRef} />
              </View>
              <View style={{ height: 40, flexDirection: "row" }}>
                <Pressable onPressIn={() => logRef.current!.clear()} style={{ flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }} >
                  <Text selectable={false} style={{ color: 'white' }}>Clear</Text>
                </Pressable>
                <Pressable onPress={() => logRef.current!.refresh()} style={{ flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }}>
                  <Text selectable={false} style={{ color: 'white' }}>Refresh</Text>
                </Pressable>
              </View>
              <View style={{ flex: 2 }}>
                <Terminal
                  commands={{
                    echo: {
                      description: 'Echo a passed string',
                      usage: 'echo <string>',
                      fn: function () {
                        return `${Array.from(arguments).join(' ')}`
                      }
                    },
                    help: {
                      description: 'List available commands',
                      usage: 'help',
                      fn: function () {
                        return Object.keys(this.commands).join(', ')
                      }
                    },
                    list: {
                      description: 'List all logs',
                      usage: 'list',
                      fn: async function () {
                        logRef.current!.refresh();
                        return `Log list refreshed`
                      }
                    }
                  }}
                  noDefaults
                  welcomeMessage={'Connected to SmartSaaS terminal'}
                  promptLabel={'> '}
                  style={{
                    flex: 1,
                    height: '70%',
                    width: '100%'
                  }} />
              </View>
            </Animatable.View>
          }
          <View style={{ flex: 1, backgroundColor: "transparent", flexDirection: "row" }}>
            <Menu />
            <View style={{ zIndex: -1, flexDirection: 'column', marginLeft: -30, paddingLeft: 30, flex: 10, overflow: 'hidden', width: '100%' }}>
              <View style={{ height: 70, backgroundColor: theme ? 'transparent' : '#333333', minWidth: '100%', zIndex: -1, position: "absolute", top: 0, right: 0 }} />
              <NotificationModal />
              <HeaderBar />
              {/*Modals go here */}
              <MessageModal />
              {/* Replace logic to set update pending in redux state */}
              <UpdateMessageModal hasUpdatePending={updatePending} callback={() => console.log('hi')} />
              <Suspense fallback={<Text>Loading Tutorial...</Text>}>
                <TutorialModal />
              </Suspense>
              <AchievementModal />
              <PackageModal ref={packageModalRef} />
              <MailLoginModal />
              <View style={[styles.appContainer]}>
                {
                  subscriptionData && paymentRequired && (
                    <Animatable.View animation={'slideInDown'} style={{
                      marginHorizontal: -40,
                      marginLeft: -50, paddingHorizontal: "5%", top: 70, borderWidth: 2, borderColor: 'red', zIndex: 100, minHeight: 40, maxHeight: 40, backgroundColor: theme ? "pink" : "rgba(255, 192, 203, 0.8)", alignSelf: "stretch", minWidth: "100%"
                    }}>
                      <XStack alignSelf='stretch' alignContent='space-between' overflow='hidden' paddingTop={3} flex={1}>
                        <Pressable style={{ width: 40, top: -2 }} onPress={() => dispatch({
                          type: HIDE_PAYMENT_REQUIRED
                        })}>
                          <AntDesign name="close" size={24} color="black" style={{ position: 'absolute', right: 10, top: 5 }} />
                        </Pressable>
                        <LottieView source={require('@assets/animations/exclamation-mark.json')} autoPlay style={{ flex: 1, maxHeight: 30, maxWidth: 40 }} />
                        <Text style={{ flex: 1, fontWeight: "bold" }}>You have exceeded the limits of your current plan, you will not be able to add any more data until you upgrade.</Text>
                        <Button borderRadius={0} style={{ borderRadius: 0, top: -8 }} onPress={() => packageModalRef.current!.openModal()}>Upgrade</Button>
                      </XStack>
                    </Animatable.View>
                  )
                }
                <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: "center" }}>
                  <View style={{ flex: 2, paddingTop: 70, justifyContent: 'center', alignSelf: 'stretch', alignContent: 'center', flexGrow: 5, marginRight: -50, flexShrink: 1, paddingHorizontal: 80 }} >
                    <Suspense fallback={
                      <View style={[styles.centerEverything, { justifyContent: 'center', minHeight: "100%", flex: 1 }]}>
                        <LottieView source={require('@assets/animations/spin-leaf-loader.json')} autoPlay loop style={{ flex: 1, minHeight: 150 }} />
                        <Paragraph style={{ color: theme ? "black" : "white" }}>Loading Dashboard...</Paragraph>
                      </View>
                    }>
                      <DashboardStack />
                    </Suspense>
                  </View>
                </View>
                <HelpModal />
              </View>
              <View style={{ flexDirection: 'column-reverse', }}>
                <View style={[styles.centerEverything, { maxHeight: 0, backgroundColor: "transparent", alignSelf: 'stretch', flex: 1, flexDirection: 'row', minWidth: '100%', paddingHorizontal: '20%', top: 40 }]}>
                  <FooterBar />
                  <AddCompanyModal />
                  <LangModal />
                  <CommModal />
                  <FeedbackModal />
                </View>
              </View>
            </View >
            <Messages />
          </View>
        </View>
      </ToolbarContext.Provider>
    );
  };

export default DashboardNavigator;