import {
	APP_SETTINGS,
	HISTORY_RETRIEVED,
	WEATHER_RETRIEVED,
	NEWS_UPDATED,
	CHANGE_THEME,
	QUOTE_UPDATED,
	ADD_STICKY_NOTE,
	CHANGE_STICKY_NOTE,
	REMOVE_STICKY_NOTE,
	NEXT_STICKY_NOTE,
	PREVIOUS_STICKY_NOTE,
	SET_STICKY_NOTES,
	PIN_NOTE,
	NOTE_DRAGGED,
	NOTE_DROPPED,
	CLEAR_ALL_NOTES,
	MAIL_AUTHENTICATED,
	GOOGLE_LOGOUT,
	UPDATE_MAIL_COUNT,
	MAIL_ITEM_SELECTED,
	CLEAR_SELECTED_MAIL,
	SUCCESS,
	CSV_DATA_HELD,
	CSV_DATA_RELEASED,
	CHANGE_LANGUAGE,
	ANALYTICS_COMPONENT_CHANGED,
	GLOBAL_LOADING,
	SET_NOTIFICATION_TOKEN,
	ADDED_DATA_TO_STORE,
	SWITCH_COMPANY,
	PENDING_REFRESH,
	REFRESHED,
	MARK_NOTE_COMPLETE,
	UNMARK_NOTE,
	CLEAR_COMPANY,
	RESET_APP,
	LOGGED_IN,
	LOGGED_OUT,
	CHANGE_AVAILABILITY,
	UPDATE_LOCATION,
	CLEAR_LOCATION,
	LOCATION_ALLOWED,
	LOCATION_DENIED,
	SET_REPORT,
	CHANGE_THEME_ACCENT,
	SET_ACTIVE_ITEM,
	HIDE_PAYMENT_REQUIRED,
} from '../actions/types';
import { updateObject } from './utils/globalStateFunctions';
import Constants from 'expo-constants';

const date = new Date().toDateString();
const accessTime = new Date().getTime();
const initialState = {
	appVersion: process.env.REACT_APP_VERSION,
	accessDate: { date },
	accessTime: { accessTime },
	availability: 'available',
	weather: { localWeather: { temp: null }, localWeatherIcon: {} },
	location: {},
	locationAllowed: false,
	language: { value: 'English' },
	theme: {
		lightTheme: true,
		themeColour: 'light',
		themeAccent: '',
	},
	deviceToken: '',
	news: {
		items: [],
	},
	quote: {},
	activeItem: undefined,
	stickyNotes: {
		completedNotes: [],
		activeNotes: [],
		activeNote: {},
		pinnedNotes: [],
		noteDragged: false,
	},
	socialAnalytics: {
		activeElement: 'website',
	},
	mail: {
		mailAuthenticated: false,
		mailCount: null,
		selectedMailItem: null,
	},
	data: {
		userData: {},
		companyData: {},
		dataImporter: {
			dataHeld: false,
		},
	},
	globalLoading: false,
	notificationToken: {},
	pendingRefresh: false,
	paymentRequired: false,
	paymentRequiredVisible: false,
	selectedProfile: null,
	loggedOut: true,
};
//TODO Bug on user change, history is retrieved from previous user logged in
export default function (state = initialState, action) {
	switch (action.type) {
		case 'UPDATE_DEVICE_TOKEN':
			return updateObject(state, {
				deviceToken: action.payload,
			});
		case 'PAYMENT_REQUIRED':
			return updateObject(state, {
				paymentRequired: true,
				paymentRequiredVisible: true,
			});
		case CHANGE_THEME_ACCENT:
			return updateObject(state, {
				themeAccent: action.payload.themeColour,
			});
		case HIDE_PAYMENT_REQUIRED:
			return updateObject(state, {
				paymentRequiredVisible: false,
			});
		case SET_ACTIVE_ITEM:
			return updateObject(state, {
				activeItem: action.payload,
			});
		case CLEAR_LOCATION:
			return updateObject(state, {
				location: {},
			});
		case LOCATION_ALLOWED:
			return updateObject(state, {
				locationAllowed: true,
			});
		case LOCATION_DENIED:
			return updateObject(state, {
				locationAllowed: false,
			});
		case UPDATE_LOCATION:
			return updateObject(state, {
				location: action.payload,
			});
		case RESET_APP:
			return updateObject(initialState);
		case SUCCESS:
			return updateObject(state, { loggedOut: false });
		case LOGGED_OUT:
			return updateObject(initialState, { loggedOut: true });
		case GLOBAL_LOADING:
			return updateObject(state, {
				globalLoading: action.payload,
			});
		case SWITCH_COMPANY:
			console.log(action.payload);
			return updateObject(state, {
				selectedProfile: action.payload,
			});
		case CLEAR_COMPANY:
			return updateObject(state, { selectedProfile: null });
		case PENDING_REFRESH:
			return updateObject(state, {
				pendingRefresh: true,
			});
		case CHANGE_AVAILABILITY:
			return updateObject(state, {
				...state,
				availability: action.payload,
			});
		case REFRESHED:
			return updateObject(state, {
				pendingRefresh: false,
			});
		case ADDED_DATA_TO_STORE:
			return updateObject(state, {
				...state,
			});
		case MAIL_AUTHENTICATED:
			return updateObject(state, {
				mail: { mailAuthenticated: true },
			});
		case SET_NOTIFICATION_TOKEN:
			return updateObject(state, {
				notificationToken: action.payload,
			});
		case ANALYTICS_COMPONENT_CHANGED:
			return updateObject(state, {
				socialAnalytics: {
					activeElement: action.payload,
				},
			});
		case CHANGE_LANGUAGE:
			return updateObject(state, {
				language: { value: action.payload },
			});
		case CSV_DATA_HELD:
			return updateObject(state, {
				data: {
					dataImporter: {
						dataHeld: true,
					},
				},
			});
		case CSV_DATA_RELEASED:
			return updateObject(state, {
				data: {
					dataImporter: {
						dataHeld: false,
					},
				},
			});
		case UPDATE_MAIL_COUNT:
			return updateObject(state, {
				mail: { ...state.mail, mailCount: action.payload },
			});
		case MAIL_ITEM_SELECTED:
			return updateObject(state, {
				mail: {
					...state.mail,
					selectedMailItem: action.payload,
				},
			});
		case CLEAR_SELECTED_MAIL:
			return updateObject(state, {
				mail: { ...state.mail, selectedMailItem: null },
			});
		case SUCCESS:
			return updateObject(state, {
				mail: { ...state.mail, selectedMailItem: null },
			});
		case GOOGLE_LOGOUT:
			return updateObject(state, {
				mail: { mailAuthenticated: false },
			});
		case ADD_STICKY_NOTE:
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNotes: [
						...state.stickyNotes.activeNotes,
						action.payload,
					],
					activeNote: action.payload,
				},
			});
		case SET_STICKY_NOTES:
			let activeNoteList = [];
			let completedNoteList = [];
			action.payload.map((notes) => {
				if (notes.completed) {
					completedNoteList.push(notes);
				} else activeNoteList.push(notes);
			});
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNotes: activeNoteList,
					completedNotes: completedNoteList,
				},
			});
		case CLEAR_ALL_NOTES:
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					pinnedNotes: [],
				},
			});
		case CHANGE_STICKY_NOTE:
			const activeNoteID = action.payload._id;
			const activeNoteContent = action.payload.content;
			const matchedNote = state.stickyNotes.activeNotes.filter(
				(id) => id === action.payload._id
			);
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNote: {
						id: activeNoteID,
						content: activeNoteContent,
					},
				},
			});
		case PREVIOUS_STICKY_NOTE:
			const findPrevNote = () => {
				if (action.payload._id) {
					return state.stickyNotes.activeNotes.filter(
						(id) => id === action.payload._id
					);
				} else return 0;
			};
			return updateObject(state, {
				...state,
			});
		case REMOVE_STICKY_NOTE:
			const newNotes = state.stickyNotes.activeNotes.filter(
				(id) => id != action.payload._id
			);
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNotes: newNotes,
					activeNote: {},
				},
			});
		case PIN_NOTE:
			const newPinnedNote = {
				id: action.payload._id,
				content: action.payload.content,
				color: action.payload.color,
			};
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					noteDragged: false,
					pinnedNotes: [
						...state.stickyNotes.pinnedNotes,
						newPinnedNote,
					],
				},
			});
		case MARK_NOTE_COMPLETE:
			const amendedNotes = state.stickyNotes.activeNotes.filter(
				(note) => note._id === action.payload._id
			);
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNotes: amendedNotes,
					completedNotes: [
						...state.stickyNotes.completedNotes,
						action.payload,
					],
				},
			});
		case UNMARK_NOTE:
			const appendedNotes = state.stickyNotes.completedNotes.filter(
				(note) => note._id != action.payload._id
			);

			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					activeNotes: [...appendedNotes, action.payload],
					completedNotes: appendedNotes,
				},
			});
		case NOTE_DRAGGED:
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					noteDragged: true,
				},
			});
		case NOTE_DROPPED:
			return updateObject(state, {
				stickyNotes: {
					...state.stickyNotes,
					noteDragged: false,
				},
			});
		case HISTORY_RETRIEVED:
			return updateObject(state, {
				queryHistory: action.payload,
			});
		case WEATHER_RETRIEVED:
			return updateObject(state, {
				weather: {
					localWeather: action.payload.main,
					localWeatherIcon: action.payload.weather[0],
				},
			});
		case NEWS_UPDATED:
			return updateObject(state, {
				news: {
					items: action.payload,
				},
			});
		case QUOTE_UPDATED:
			return updateObject(state, {
				quote: action.payload,
			});
		case CHANGE_THEME:
			return updateObject(state, {
				theme: {
					lightTheme: action.payload.lightTheme,
					themeColour: action.payload.theme,
				},
			});
		default:
			return state;
	}
}
