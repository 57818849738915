import React, { useEffect, useState } from 'react';
import {
  Keyboard,
  Platform,
  Text,
  Pressable,
  StyleSheet,
  View,
  Image,
  TextInput,
  ViewStyle
} from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  Easing,
} from 'react-native-reanimated';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { fontValue } from './Dimensions';
import { COLORS } from './Misc';
import * as Animatable from 'react-native-animatable';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { use } from 'i18next';
import DataSearch from './DataSearch';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Adapt, Avatar, Popover } from 'tamagui';
import { Picker } from 'emoji-mart-native';
import { selectUser } from '@reduxLocal/selectors/userSelectors';
import { IMessage, InputToolbarProps } from 'react-native-gifted-chat';
import handleImage from "@utils/handleImage";


export type MyInputToolbarProps = {
  /**
   * @param boolean A function to get the message from the input.
   */
  staticPress?: boolean;
  /**
   * A function to get the message from the input.
   */
  messageGetter?: boolean;
  /**
   * 
   * @param value a function to set the message from the input.
   * @returns  void
   */
  messageSetter?: (value: string) => void;
  /** 
   * A function to get the emoji state.
   * */
  emojiGetter?: boolean;
  /**
   * 
   * @param value a function to set the emoji state.
   * @returns void
   */
  emojiSetter?: () => void;
  /**
   *  A function to send the message.
   * */
  sendMessageCallback?: (message: any) => void;
  /**
   * @param boolean Removes the attachment tool
   */
  removeAttach?: boolean;
  /**
   * @param boolean Force items to be displayed in a column
   */
  forceColumn?: boolean;
  /**
   * @param JSX.Element A custom icon for the send button
   */
  customSendIcon?: JSX.Element;
  /**
   * 
   * @param boolean border bardius from top
   */
  removeBorderRadius?: boolean;
  /**
   * @param boolean Personify the input toolbar
   */
  personify?: boolean;
  /**
   * 
   * @param boolean Inverses the tools
   */
  inverse?: boolean;
  cameraPressCallback?: () => void;
  attachPressCallback?: () => void;
  userUID?: string;
  removeTools?: boolean;
  emptyPlaceHolder?: string;
  messageInput?: boolean;
  placeHolder?: string;
  maxHeight?: number;
  backgroundColor?: string;
  /**
   * @param boolean Multiline
   *  */
  multiline?: boolean;
  /**
   * @param boolean Disables the entry
   */
  disableEntry?: boolean;
  /**
   * @param Object Styles for the container
   */
  containerStyle?: ViewStyle;
  onSend?: (message: IMessage) => void;
  user?: any;
};

const MyInputToolbar = ({
  messageGetter = undefined,
  personify = false,
  emptyPlaceHolder = "",
  messageInput = false,
  messageSetter = (text) => { },
  forceColumn = false,
  sendMessageCallback = (message: any) => { },
  cameraPressCallback = () => { },
  attachPressCallback = () => { },
  userUID = "",
  removeTools = false,
  removeAttach = false,
  placeHolder = "",
  disableEntry = false,
  customSendIcon,
  maxHeight,
  multiline = false,
  inverse = false,
  removeBorderRadius = false,
  backgroundColor = 'white',
  staticPress = false,
  onSend,
  user,
  //Apply TextInputProps to the containerStyle
  containerStyle = {},
}: MyInputToolbarProps & InputToolbarProps<IMessage>) => {
  const height = useSharedValue(removeTools && !forceColumn ? 60 : (maxHeight || 140));
  const maxWidth = useSharedValue('100%');
  const opacity = useSharedValue(0);
  const [text, setText] = React.useState('');
  const [folder, showFolder] = React.useState(false);
  const [folderHiding, setFolderHiding] = React.useState(false);
  const theme = themeSelector();
  const [emojiState, setEmojiState] = useState(null);
  const [pendingImage, setPendingImage] = useState({});
  const [prevImage, setPrevImage] = useState(null);

  const heightAnimatedStyle = useAnimatedStyle(() => {
    return {
      height: withSpring(height?.value),
    };
  });


  const widthAnimatedStyle = useAnimatedStyle(() => {
    return {
      maxWidth: withTiming(maxWidth?.value, {
        duration: 250,
        easing: Easing.linear,
      }),
    };
  });

  const opacityAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(opacity?.value, {
        duration: 250,
        easing: Easing.circle,
      }),
    };
  });

  const handleEmojiOpen = (event) => {
    setEmojiState(event);
  }

          /**
          const keyboard = useAnimatedKeyboard();

          const messageInputTranslate = useAnimatedStyle(() => {
            return {
              transform: [{translateY: -keyboard?.height?.value}],
            };
          });
          */

  const [lastLength, setLastLength] = React.useState(0);

  useEffect(() => {
    if (text?.trim()?.length > 0) {
      maxWidth.value = '85%';
      opacity.value = 1;
    } else {
      maxWidth.value = '100%';
      opacity.value = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  /**
   * Some styles are tested and not shipped, we will use them soon.
   */


  const handleToggleFolder = () => {
    if (folder) {
      setFolderHiding(true)
      setTimeout(() => {
        showFolder(false)
      }, 200);
    } else {
      showFolder(true)
      setTimeout(() => setFolderHiding(false), 200)
    }
  }

  return (
    <View style={[{ top: multiline ? 22 : undefined, overflow: "hidden", maxHeight: 250, flex: 1, justifyContent: "center", borderBottomStartRadius: 15, borderBottomEndRadius: 15, borderTopStartRadius: removeBorderRadius ? 0 : 15, borderTopEndRadius: removeBorderRadius ? 0 : 15 }, containerStyle]}>
      {
        !removeTools &&
        <Animatable.View transition={'top'} duration={100} direction={'alternate'} style={{ top: !folderHiding ? -80 : 0 }}>
          {
            folder &&
            <Animatable.View animation={'fadeIn'} style={{ flexDirection: 'row', position: "absolute", alignSelf: 'stretch', minHeight: 70 }}>
              <DataSearch callback={(value) => console.log(value)} />
            </Animatable.View>
          }
        </Animatable.View>
      }
      <Animated.View
        style={[
          styles.container,
          heightAnimatedStyle /*messageInputTranslate*/,
        ]}>
        <View style={[styles.innerContainer, { alignSelf: "stretch", flexDirection: forceColumn ? (inverse ? "column" : "column-reverse") : "row", maxHeight: 40 }]}>
          <Animated.View
            style={[styles.inputAndMicrophone, widthAnimatedStyle, { borderRadius: removeBorderRadius && 0, overflow: "visible", top: -10, minWidth: "100%", paddingHorizontal: 10, backgroundColor: backgroundColor || 'white' }]}>
            <View style={{ overflow: "visible", gap: 5, flexDirection: forceColumn ? (inverse ? "column" : "column-reverse") : "row-reverse", minWidth: "110%", left: -20, flex: 1 }}>
              {
                !removeTools &&
                <View style={{ paddingHorizontal: 20, flexDirection: "column", gap: 15, justifyContent: "space-between" }}>
                  <View style={{ flex: 1, flexDirection: "row", gap: 5 }}>
                      <Popover
                        allowFlip
                        stayInFrame
                        offset={{ mainAxis: -10, crossAxis: -10 }}
                      >

                        <Popover.Content
                          borderWidth={0}
                          unstyled
                          enterStyle={{ y: 10, opacity: 0 }}
                          exitStyle={{ y: 10, opacity: 0 }}
                          elevate
                          style={{ borderRadius: 20, borderWidth: 0, margin: -10 }}
                          animation={[
                            'quick',
                            {
                              opacity: {
                                overshootClamping: true,
                              },
                            },
                          ]}
                        >
                          <Picker
                            onSelect={(emoji) => { let coll = text + emoji.native; setText(coll); messageSetter?.(coll); setLastLength(coll.length) }}
                          />
                        </Popover.Content>
                        <Popover.Trigger>
                          <FontAwesome
                            name={emojiState ? 'close' : 'smile-o'}
                            size={forceColumn ? 18 : 23}
                            color={theme ? COLORS.lightGrey : 'black'}
                            style={{ top: !multiline && 13 }}
                          />
                        </Popover.Trigger>
                      </Popover>

                      {/* <Pressable
                          hitSlop={10}
                          android_ripple={{
                            color: COLORS.controlHighlight,
                            borderless: true,
                            radius: 30 - 0.1 * 30,
                          }}
                          style={styles.rightIconButtonStyle}
                          onPress={() => handleToggleFolder()}>
                          <FontAwesome
                            name="folder"
                            size={23}
                            color={COLORS.darkGrey}
                            style={{ left: 7 }}
                          />
                        </Pressable> */}
                      <>
                        {
                          !removeAttach &&
                          <Pressable
                            android_ripple={{
                              color: COLORS.controlHighlight,
                              borderless: true,
                              radius: 30 - 0.1 * 30,
                            }}
                            style={styles.rightIconButtonStyle}
                            onPress={attachPressCallback}>
                            <FontAwesome
                              name="paperclip"
                              size={forceColumn ? 18 : 23}
                                color={theme ? COLORS.darkGrey : 'black'}
                            />
                          </Pressable>
                        }
                      </>
                      <Pressable
                        hitSlop={10}
                        android_ripple={{
                          color: COLORS.controlHighlight,
                          borderless: true,
                          radius: 30 - 0.1 * 30,
                        }}
                        style={styles.rightIconButtonStyle}
                        onPress={async () => {
                          await handleImage(true).then(res => {
                            setPendingImage(res);
                          })
                          cameraPressCallback?.()
                        }}>
                        <FontAwesome
                          name="image"
                          size={forceColumn ? 18 : 23}
                          color={theme ? COLORS.darkGrey : 'black'}
                        />
                      </Pressable>
                    </View>
                </View>
              }
              <View style={{ backgroundColor: "#ececec", flexDirection: "row", flex: 1.5, paddingLeft: 25 }}>
                {
                  pendingImage?.uri &&
                  <>
                    <Pressable onPress={(e) => setPrevImage(e.currentTarget)}>
                      <Animatable.Image animation={'slideInLeft'} style={{ width: 50, height: 50, resizeMode: 'contain' }} source={{ uri: pendingImage?.uri }} />
                    </Pressable>
                    <Popover
                      open={!!prevImage}
                      onClose={() => setPrevImage(null)}
                      anchorEl={prevImage}
                      anchorOrigin={{
                        vertical: -60,
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: -60,
                        horizontal: 'center',
                      }}
                    >
                      <Pressable onPress={() => setPendingImage({})} style={{ flexDirection: "row" }}>
                        <Text>Remove</Text>
                        <AntDesign name="close" size={24} color="red" />
                      </Pressable>
                      <Image source={{ uri: pendingImage?.uri }} style={{ width: 200, height: 200, resizeMode: 'contain' }} />
                    </Popover>
                  </>
                }
                {
                  personify &&
                  <Avatar circular size={40} style={{ top: 4, left: -5 }}>
                    <Avatar.Image alt={`userAvatar`} src={user?.avatar || "https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png"} />
                    <Avatar.Fallback backgroundColor="$blue10" />
                  </Avatar>
                }
                <TextInput
                  multiline
                  numberOfLines={3}
                  editable={!disableEntry}
                  scrollEnabled={false}
                  placeholderTextColor={theme ? "black" : "black"}
                  placeholder={placeHolder ? (disableEntry ? "Awaiting response" : placeHolder) : (disableEntry ? "Awaiting response" : 'Aa')}
                  onBlur={() => Keyboard.dismiss()}
                  onFocus={() => {
                    if (emojiState) {
                      setEmojiState(false);
                    }
                  }}
                  style={[styles.input, { fontSize: 14, borderColor: "#00000030", top: 4, backgroundColor: "#ececec", minHeight: multiline ? 200 : undefined, minWidth: removeTools ? "90%" : undefined }]}
                  value={text}
                  onKeyPress={(e) => {
                    //Detect cmd + enter
                    if (e.nativeEvent.key === 'Enter' && e.nativeEvent.metaKey) {
                      if (text?.trim()?.length > 0 || pendingImage?.base64) {
                        if (onSend) {
                          onSend?.({
                            text: text,
                            image: pendingImage,
                            createdAt: new Date(),
                            user: {
                              _id: user._id,
                              avatar: user.avatar,
                            }
                          });
                        } else {
                          sendMessageCallback?.(text);
                          //Lose focus on the input
                          Keyboard.dismiss();
                        }
                        setText('');
                        //Lose focus
                      }
                    }
                  }}
                  onChangeText={async text => {
                    setText(text);
                    messageSetter?.(text);
                    setLastLength(text?.length);
                  }}
                />
              </View>
            </View>
            {(text?.trim()?.length > 0 || pendingImage?.base64) ? (
              <Animatable.View animation={'slideInUp'} style={{ left: forceColumn ? -30 : (messageInput ? -80 : -160), top: (staticPress || messageInput) ? undefined : Platform.select({ web: 35 }), flex: 1, maxWidth: 30 }}>
                <Pressable
                  hitSlop={10}
                  android_ripple={{
                    color: COLORS.controlHighlight,
                    borderless: true,
                    radius: forceColumn ? 20 - 0.1 ^ 20 : 30 - 0.1 * 30,
                  }}
                  style={forceColumn ? styles.sendButtonSmall : styles.sendButton}
                  onPress={() => {
                    if (text?.trim()?.length > 0 || pendingImage.base64) {
                      if (onSend) {
                        onSend?.({
                          text: text,
                          image: pendingImage,
                          createdAt: new Date(),
                          user: {
                            _id: user._id,
                            avatar: user.avatar,
                          }
                        });
                      } else {
                        sendMessageCallback?.(text);
                      }
                      setText('');
                    } else {
                      // TODO: implement send voice message.
                    }
                  }}>
                  <Animated.View style={[opacityAnimatedStyle]}>
                    {
                      customSendIcon ?
                        (customSendIcon) :
                        <MaterialCommunityIcons
                          adjustsFontSizeToFit
                          allowFontScaling
                          name={'send'}
                          size={forceColumn ? 12 : 18}
                          color={COLORS.white}
                        />
                    }
                  </Animated.View>
                </Pressable>
              </Animatable.View>
            ) : (
              <></>
            )}
          </Animated.View>
        </View>

      </Animated.View>
    </View>
  );
};

export default MyInputToolbar;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    borderBottomStartRadius: 15,
    borderBottomEndRadius: 15,
  },
  replyContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderBottomStartRadius: 15,
    borderBottomEndRadius: 15,
  },
  title: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  closeReply: {
    position: 'absolute',
    right: 10,
    top: 5,
  },
  reply: {
    marginTop: 5,
  },
  innerContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10 - 0.1 * 10,
    minHeight: 60
  },
  inputAndMicrophone: {
    flexDirection: 'row',
    backgroundColor: COLORS.dimmed,
    flex: 3,
    marginRight: 10 - 0.1 * 10,
    paddingVertical: Platform.OS === 'ios' ? 10 : 0,
    borderRadius: 30 - 0.1 * 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 50
  },
  input: {
    backgroundColor: 'transparent',
    marginLeft: 12.5 - 0.1 * 12.5,
    color: COLORS.black,
    flex: 5,
    fontSize: fontValue(16),
    height: 55 - 0.1 * 55,
    alignSelf: 'stretch',
    borderRadius: 10,
    minWidth: "75%",
    padding: 5,
    borderWidth: 0,
    paddingVertical: 12,
    outlineStyle: "none",
    paddingRight: 30
  },
  rightIconButtonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15 - 0.1 * 15,
    marginLeft: 5 - 0.1 * 5,
    borderLeftWidth: 0,
    borderLeftColor: COLORS.dimmed, // #fff
  },
  swipeToCancelView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 30,
  },
  swipeText: {
    color: COLORS.description,
    fontSize: 15,
  },
  emoticonButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15 - 0.1 * 15,
  },
  recordingActive: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
  },
  recordingTime: {
    color: COLORS.darkGrey,
    fontSize: 20,
    marginLeft: 5,
  },
  microphoneAndLock: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  lockView: {
    backgroundColor: '#eee',
    width: 60,
    alignItems: 'center',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    height: 130,
    paddingTop: 20,
  },
  sendButton: {
    borderRadius: 45 - 0.1 * 45,
    backgroundColor: COLORS.accentLight,
    height: 45 - 0.1 * 45,
    width: 45 - 0.1 * 45,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonSmall: {
    borderRadius: 23 - 0.1 * 23,
    backgroundColor: COLORS.accentLight,
    height: 23 - 0.1 * 23,
    width: 23 - 0.1 * 23,
    alignItems: 'center',
    justifyContent: 'center',
  },
});