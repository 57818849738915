import { Platform } from 'react-native';
import Config from '../Config.js';
//TODO Change for production
export const HOST = Platform.OS != 'web' ? Config.apiUrl : Config.webApi;

export const AI_API_URL = `${Config.aiAPI}`;
export const AI_CHAT_API_URL = `${Config.aiChatAPI}`;
export const AI_MODEL = "llama3:latest";

export const USER_API_BASE_URL = `${HOST}/api`;

export const STRIPE_URI = `${HOST}/stripe`

export const LOGIN_API_URL = `${USER_API_BASE_URL}/login`;

export const PCO_SYSTEM_API_URL = `${USER_API_BASE_URL}/systems`;

export const USER_ACTION_HISTORY = `${USER_API_BASE_URL}/userActionHistory`;

export const AVATAR_UPLOAD = `${USER_API_BASE_URL}/upload/`;

export const GET_AVATAR_URL = `${USER_API_BASE_URL}/image`;

export const NEW_USER_ADDED = `${USER_API_BASE_URL}/registeruser`;

export const SESSION_API = `${USER_API_BASE_URL}/session`;

export const WEATHER_API_KEY = `26d18df2b88c8184fbc6d80c0ae2da66`;

export const NEWS_API_KEY = `GfdrfLQWB5WAkb6DaYEnWT6Or9Zx62547BrDl3_XBh4`;

export const LINKEDIN_CLIENT_ID = '78zyjbrtiha5t3';

export const COMPANIESHOUSE_API_KEY = '4d57ab9a-b311-4f16-b71b-a811ced9e95c';
