import React, { memo, useEffect, useState, useRef } from 'react';
import { View, FlatList, Platform } from 'react-native';
import PrimaryMenu from './PrimaryMenu';
import { useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import superAdmin from './dynamicMenuList';

const PrimaryList = () => {
  const menuType = useSelector((state: DefaultRootState) => state.navigation.menuType);
  const defaultMenuArray = superAdmin;
  const filteredMenu = defaultMenuArray.filter(menu => menu.menuType === menuType);
  const returnMenu = filteredMenu[0].menu;
  const [newMenuArray, setNewMenuArray] = useState(returnMenu);
  const menuRef = useRef(null);

  useEffect(() => {
    setNewMenuArray(returnMenu);
    menuRef.current?.fadeIn(500);
  }, [menuType])

  const _renderPrimaryMenu = ({ item, index }) => {
    return (
      <View style={{ elevation: 1, gap: 15, minHeight: 40 }}>
        <PrimaryMenu item={item} />
      </View>
    )
  }

  return (
    <View style={{ flex: 2, flexGrow: 4, marginBottom: 30, gap: 20, alignContent: 'flex-start', justifyContent: 'flex-start' }}>
    <FlatList
      data={newMenuArray}
      showsVerticalScrollIndicator={false}
      nestedScrollEnabled={true}
      centerContent
      contentContainerStyle={{marginBottom: 20}}
        scrollEnabled={false}
      renderItem={_renderPrimaryMenu}
      keyExtractor={(item) => item.id}
    />
    </View>
  )
}
export default memo(PrimaryList);