import PercentageBar from "@components/Global/PercentageBar";
import { useQueryClient } from "react-query";
import { YStack, Text } from "tamagui";
import LottieView from "lottie-react-native";
import { useMemo } from "react";

const UsageStatMonitor = () => {
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState("statCount");
  const companyMembers = queryClient.getQueryData("companyMembers") || [];

  const isFetching = queryState?.isFetching || false;
  const data = queryState?.data || {};

  const stats = useMemo(() => {
    if (!data || Object.keys(data).length === 0) return [];

    const getPercentage = (value, limit) =>
      typeof value === "number" && value >= 0 ? `${Math.min(100, Math.round((value / limit) * 100))}%` : "N/A";

    return [
      { title: "Data", dataCount: data.data ?? 0, limit: 300, backgroundColor: "#1E90FF" },
      { title: "Data Models", dataCount: data.models ?? 0, limit: 3, backgroundColor: "red" },
      { title: "Projects", dataCount: data.projects ?? 0, limit: 1, backgroundColor: "red" },
      { title: "Users", dataCount: companyMembers.length, limit: 3, backgroundColor: "green" },
      { title: "Storage", dataCount: `${data.storage ?? 0}MB`, limit: "300MB", backgroundColor: "#1E90FF" },
      { title: "Monthly AI Calls", dataCount: "N/A", limit: 100, backgroundColor: "#1E90FF", percentage: "50%" },
    ].map((item) => ({
      ...item,
      percentage: item.percentage || getPercentage(Number(item.dataCount), Number(item.limit)),
    }));
  }, [data, companyMembers]);

  return useMemo(() => (
    <YStack flex={1}>
      {isFetching ? (
        <LottieView
          style={{ minHeight: 50, paddingVertical: "50%", marginVertical: "30%", height: 50 }}
          source={require("@assets/animations/spin-leaf-loader.json")}
          autoPlay
          loop
        />
      ) : stats.length === 0 ? (
        <Text style={{ textAlign: "center", marginTop: 20 }}>No data available</Text>
      ) : (
        stats.map((stat, index) => (
          <PercentageBar
            key={index}
            title={stat.title}
            dataCount={stat.dataCount}
            limit={stat.limit}
            percentage={stat.percentage}
            backgroundColor={stat.backgroundColor}
            completedColor="red"
            height={20}
            style={{ flex: 1 }}
          />
        ))
      )}
    </YStack>
  ), [stats, isFetching]);
};

export default UsageStatMonitor;
