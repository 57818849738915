import React, { useEffect, useState } from 'react';
import { Platform, View, ScrollView, ViewProps, Pressable } from 'react-native';
import { ScrollView as GestureScrollView } from 'react-native-gesture-handler';
import styles from '../../../stylesheet';
import { Ionicons } from '@expo/vector-icons';

import SearchComponent from './SearchComponent/SearchBar';
import { SearchComponentTypes } from './SearchComponent/Types';
import { debounce } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { useDeviceType } from '@hooks/useDeviceType';

type ScreenViewProps = {
  navigation?: any;
  screenTitle?: String;
  children?: JSX.Element[] | JSX.Element;
  route?: any;
  upperLevelScreen?: boolean;
  backButton?: boolean;
  lightningActions?: boolean;
  noMargin?: boolean;
  scrollDisabled?: boolean;
  searchBar?: SearchComponentTypes;
  removeMargin?: boolean;
  searchBarStyle?: ViewProps['style'];
  aiCallback?: (text: string) => void;
  style?: ViewProps['style'];
  fetchingState?: boolean;
  inverseSearch?: boolean;
  containerStyle?: ViewProps['style'];
  awaitMenuClose?: boolean;
  aiSearch?: boolean;
  layoutCallback?: ({ height, width }: { height: number, width: number }) => void;
};

const DefaultScreen = ({
  layoutCallback,
  aiSearch,
  style,
  aiCallback,
  inverseSearch,
  upperLevelScreen,
  noMargin,
  screenTitle,
  children,
  route,
  backButton,
  lightningActions,
  scrollDisabled,
  containerStyle,
  searchBarStyle,
  searchBar,
  awaitMenuClose,
  fetchingState = false,
  removeMargin,
  ...props
}: ScreenViewProps) => {
  const [lastDimensions, setLastDimensions] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });
  const { isTablet } = useDeviceType();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const backAvailable = navigation.canGoBack();

  useEffect(() => {
    if (awaitMenuClose) {
      //Add listener to close menu
    }
  }, [])

  const handleLayout = debounce((layout: { height: number; width: number }) => {
    if (layoutCallback) {
      const { height, width } = layout;
      if (height !== lastDimensions.height || width !== lastDimensions.width) {
        console.log('calling layout callback')
        layoutCallback?.({ height, width });
        setLastDimensions({ height, width });
      }
     }
   }, 100); // Adjust the delay as needed

  return (
    <View
      style={[
        styles.pageContainer,
        {
          backgroundColor: 'transparent',
          elevation: 20,
          maxHeight: '100%',
          flexGrow: 1,
          alignSelf: "stretch",
          minWidth: "100%",
          paddingTop: isTablet ? 20 : undefined
        },
        style,
      ]}
      onLayout={(e) => handleLayout({ height: e.nativeEvent.layout.height, width: e.nativeEvent.layout.width })}
    >
      {searchBar && (
        <View style={{ marginVertical: removeMargin ? 0 : (isTablet ? 0 : 10), zIndex: 2, marginBottom: removeMargin || isTablet ? 30 : 30, marginRight: !removeMargin && -20 }}>
          <SearchComponent
            aiSearch={aiSearch || false}
            placeHolder={aiSearch && !searchBar.placeHolder ? "Use AI to help you" : searchBar.placeHolder}
            inverse={inverseSearch}
            backButton={backButton}
            fetchingState={fetchingState}
            customStyle={searchBarStyle}
            aiCallback={searchBar.aiCallback}
            showSearch={searchBar.showSearch}
            key={searchBar.key}
            callback={searchBar.callback}
            searchState={searchBar.searchState}
            setSearchState={searchBar.setSearchState}
            customIcons={searchBar.customIcons}
            extraComponents={searchBar.extraComponents}
            otherComponents={searchBar.otherComponents}
          />
        </View>
      )}
      {
        backButton && Platform.OS !== 'web' && (
          <Pressable onPress={() => {
            if (upperLevelScreen || !backAvailable) {
              dispatch({ type: "OPEN_MENU" })
            } else {
              navigation.goBack()
            }
          }} style={{ zIndex: 10, position: "absolute", padding: 5, top: 33, left: 20, borderRadius: 20, backgroundColor: "rgb(90,192,234)" }}>
            <Ionicons name="arrow-back" size={24} color="white" />
          </Pressable>
        )
      }
      {scrollDisabled ? (
        <View
          style={[
            styles.screen,
            { alignContent: 'center', alignSelf: 'stretch', overflow: 'hidden', flex: 1, padding: noMargin ? 0 : 5, paddingTop: (removeMargin && !searchBar) ? 0 : 40 },
          ]}
          {...props}
        >
          {children}
        </View>
      ) : (
        <GestureScrollView
          showsVerticalScrollIndicator={false}
          style={[
            containerStyle,
            styles.screen,
            { flex: 10, minHeight: 40, padding: noMargin ? 0 : 5, paddingTop: removeMargin ? 0 : 20 },
          ]}
          contentContainerStyle={{ paddingHorizontal: 10, alignSelf: 'stretch', gap: 20, minWidth: '100%', overflow: 'visible', alignContent: 'center', alignItems: 'center' }}
        >
          {children}
        </GestureScrollView>
      )}
    </View>
  );
};

export default DefaultScreen;
