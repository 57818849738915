import undoable from 'redux-undo';
import PreviewData from '../../navigation/screens/MidLevel/DataScreen/components/previewData';
import { updateObject } from './utils/globalStateFunctions';
import {
	CLEAR_UPLOADER_HISTORY,
	UPDATE_PRESENT,
	CHANGE_DATA_TITLE,
	CHANGE_DATA_CATEGORY,
	SET_DEFAULT_PREVIEWER,
	REMOVE_DEFAULT_DATA_MARKER,
	CHANGE_ITEM_CATEGORY,
	ADD_DATA_CATEGORY,
	REMOVE_DATA_CATEGORY,
	ASSIGN_DATA_CATEGORY,
	UNASSIGN_DATA_CATEGORY,
	CHANGE_ITEM_DATA_TYPE,
	PREVIEW_DATA_REQUESTED,
	CHANGE_DATA,
	ADD_DATA_ITEM,
	REMOVE_DATA_ITEM,
	CHANGE_SELECTED_ITEM,
	CHANGE_ITEM_DATA_TITLE,
	CHANGE_DATA_TAGS,
	MODIFY_DATA,
	CHANGE_OPTION,
	ADD_OPTION,
	REMOVE_OPTION,
	APPLY_VALIDATION,
	MODIFY_VALIDATION,
	REMOVE_VALIDATION,
	REPLACE_HANDLER_SCHEMA,
	CLEAR_HANDLER_DATA
} from '../actions/types';

const initialState = {
	schemaId: '',
	dataHeld: [],
	previewRequested: false,
	dataCategory: 'upperManagementMenu',
	dataTitle: 'Example',
	defaultData: true,
	dataTags: [],
	selectedItem: 0,
	isUpload: false,
	storedFile: null,
	mods: { remove: [] },
	categoryList: [
		{
			value: 'Example',
			label: 'Example',
			key: '34445',
			categoryColor: 'rgb(90, 192, 234)',
			count: 0,
		},
	],
};

const uploadPreviewer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_HANDLER_DATA: {
			return updateObject(state, {
				...state,
				dataHeld: [],
				dataTitle: 'Example',
				dataTags: [],
				defaultData: true,
				storedFile: null,
			});
		}
		case REPLACE_HANDLER_SCHEMA: {
			return updateObject(state, {
				...state,
				...action.payload,
			});
		}
		case PREVIEW_DATA_REQUESTED: {
			return updateObject(state, {
				...state,
				previewRequested: !state.previewRequested,
			});
		}
		case CHANGE_DATA_TAGS: {
			return updateObject(state, {
				...state,
				dataTags: action.payload,
			});
		}
		case ADD_DATA_ITEM: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					action.payload,
					...state.dataHeld.slice(action.index),
				],
			});
		}
		case REMOVE_DATA_ITEM: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					...state.dataHeld.slice(action.index + 1),
				],
				mods: {
					...state.mods,
					remove: [
						...state.mods.remove,
						action.index + state.mods.remove.length,
					],
				},
			});
		}
		case CLEAR_UPLOADER_HISTORY: {
			return initialState;
		}
		case UPDATE_PRESENT: {
			return updateObject(state, {
				...state,
				dataHeld: action.payload,
			});
		}
		case CHANGE_DATA_TITLE: {
			return updateObject(state, {
				...state,
				dataTitle: action.payload,
			});
		}
		case CHANGE_ITEM_DATA_TITLE: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						title: action.payload,
					},
					...state.dataHeld.slice(action.index + 1),
				],
			});
		}
		case CHANGE_DATA_CATEGORY: {
			return updateObject(state, {
				...state,
				dataCategory: action.payload,
			});
		}
		case SET_DEFAULT_PREVIEWER: {
			return updateObject(state, {
				dataHeld: PreviewData,
				dataCategory: 'upperManagementMenu',
				dataTitle: 'Example Data',
				defaultData: true,
				categoryList: initialState.categoryList,
			});
		}
		case REMOVE_DEFAULT_DATA_MARKER: {
			return updateObject(state, {
				...state,
				defaultData: false,
			});
		}
		case CHANGE_ITEM_CATEGORY: {
			const removeCount = 1;
			state.dataHeld.splice(
				action.payload.index,
				removeCount,
				action.payload.newValue
			);
			return updateObject(state, {
				...state,
				dataHeld: state.dataHeld,
			});
		}
		case CHANGE_ITEM_DATA_TYPE: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						type: action.payload.newValue.value,
						validation:{} // Reset validation
					},
					...state.dataHeld.slice(action.index + 1),
				],
			});
		}
		case ADD_OPTION: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						options: {
							...state.dataHeld[action.index].options,
							[action.payload.key]: action.payload.value, // Assuming payload contains a key and a value
						},
					},
					...state.dataHeld.slice(action.index + 1),
				],
			});
		}
		case REMOVE_OPTION: {
			return updateObject(state, {
				...state,
				dataHeld: state.dataHeld.map((item, index) => {
					if (index === action.index) {
						// Create a copy of the dataHeld item without the specified option
						const updatedOptions = { ...item.options };
						delete updatedOptions[action.optionKey]; // Assuming action.optionKey is the key to be removed
						return {
							...item,
							options: updatedOptions,
						};
					}
					return item;
				}),
			});
		}
		case ADD_DATA_CATEGORY: {
			state.categoryList.push(action.payload);
			return updateObject(state, {
				...state,
				categoryList: state.categoryList,
			});
		}
		case CHANGE_DATA: {
			//Only update the changes coming from action.payload
			
			return updateObject(state, {
				...state,
				dataTitle: action.payload.schemaTitle,
				dataHeld: action.payload.schemaContent,
				dataTags: action.payload.dataTags,
				isUpload: action.payload.isUpload || false,
				defaultData: false,
				storedFile: action.payload.storedFile,
				dataLength: action.payload.dataLength || 0,
			});
		}
		case MODIFY_DATA: {
			return updateObject(state, {
				...state,
				mods: {
					...state.mods,
					remove: [...state.mods.remove, action.payload.remove],
				},
			});
		}
		case APPLY_VALIDATION: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						validation: action.payload,
					},
					...state.dataHeld.slice(action.index + 1),
				],
			});
		}

		case MODIFY_VALIDATION: {
			return {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						validation: {
							...state.dataHeld[action.index].validation,
							[action.payload.key]: action.payload.value,
						},
					},
					...state.dataHeld.slice(action.index + 1),
				],
			};
		}
		case REMOVE_VALIDATION: {
			return updateObject(state, {
				...state,
				dataHeld: [
					...state.dataHeld.slice(0, action.index),
					{
						...state.dataHeld[action.index],
						validation: {
							...state.dataHeld[action.index].validation,
							[action.payload]: undefined,
						},
					},
					...state.dataHeld.slice(action.index + 1),
				],
			});
		}
		default:
			return state;
	}
};

const undoablePreviewer = undoable(uploadPreviewer, {
	limit: 50,
	clearHistoryType: CLEAR_UPLOADER_HISTORY,
});

export default undoablePreviewer;
