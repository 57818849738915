import React, { useCallback, useEffect, useRef, useState } from "react";
import { Animated, ImageBackground, TouchableOpacity, Modal as SmallModal, Platform, TextInput, Dimensions } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { View, Text } from '@constants/Themed';
import styles from "@stylesheet";
import LottieView from '@utils/lottieAnimation';
import { ProgressBar } from 'react-native-paper';
import { HIDE_LOADING_MODAL, INITIALISE_MENU, RESET_APP, SHOW_LOADING_MODAL } from "@reduxLocal/actions/types";
import { DefaultRootState, store } from "@reduxLocal/persistState";
import apis from "@api/api";
import { PURGE } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation } from "@react-navigation/native";


const LoadingScreen = ({ route }) => {
  const [progress, setProgress] = useState(0);
  const [text, setText] = useState("");
  //TODO animate progress state, shared Value?
  const user = useSelector((state: DefaultRootState) => state.promiseData.data?.userData);
  const { width, height } = Dimensions.get('window');
const dispatch = useDispatch();
  const navigation = useNavigation();
  
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (store.getState().lastAction?.type === "USER COMPANY CHANGED" || store.getState().lastAction?.type === "CLEARED COMPANY") {
        navigation.replace('Dashboard', { user: null });
        unsubscribe();
      }
    });
    return () => {
      unsubscribe();
    }
  }, []);

  const updateProgress = useCallback((progress, stateText) => {
    return new Promise<void>((resolve, reject) => {
      setProgress(progress);
      setText(stateText);
      setTimeout(() => { }, 500);
      resolve();
    });
  }, []);

  useEffect(() => {
    async function fetchSession() {
      await apis.getSession()
        .then(async (session) => {
          await updateProgress(0.3, 'Loading session').then(async () => {
            const { isAuthenticated } = session.data;
            await updateProgress(0.7, 'Loading user').then(async () => {
              if (isAuthenticated && user) {
                await updateProgress(1, 'Loading Dashboard').then(() => {
                  // dispatch({ type: INITIALISE_MENU })
                  navigation.replace('Dashboard', { user: null });
                })
              } else {
                dispatch({ type: PURGE })
                dispatch({ type: RESET_APP });
                navigation.replace('Login');
              }
            })
          })
        })
        .catch(async error => {
          await updateProgress(1, "No updates found");
          navigation.navigate('Login');
          //TODO Check cookie patch
          if(Platform.OS ==='web'){
          document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          }
          dispatch({ type: RESET_APP })
        })
    };
    if (!route.params?.params?.flagHold) {
      console.log("fetching new session");
    fetchSession();
    }
  }, []);

  return (
    <View style={{ flex: 1, overflow: 'visible', bottom: -30, top: -50 }}>
      {Platform.OS != 'web' ?
        <View style={[styles.background, { position: 'absolute', minWidth: '250%', }]}>
          <LottieView resizeMode="cover" style={{ alignSelf: 'stretch', overflow: 'visible', width: width * 2.4, height: height * 1.1, }} source={require('@assets/animations/backgroundLottie.json')} autoPlay loop={true}></LottieView>
        </View>
        :
        <View style={[styles.background, { position: 'absolute', width: '150%', overflow: 'hidden' }]}>
          <LottieView resizeMode="cover" source={require('@assets/animations/backgroundLottie.json')} autoPlay loop={true}></LottieView>
        </View>
      }
      <Animated.View style={{ flex: 1, opacity: 1, margin: 150, top: Dimensions.get('window').height / 4, position: 'absolute', maxWidth: Platform.OS === 'web' ? 400 : '50%', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
        <LottieView style={{ flex: 1, width: Platform.OS === 'web' ? '70%' : 250, }} source={require('@assets/animations/landing_Animation_dark.json')} autoPlay loop={false}></LottieView>
        <Text style={{ minWidth: 150, backgroundColor: 'transparent', fontWeight: '600', textAlign: 'center' }}>{text}</Text>
        <View style={{ width: '50%', margin: 50 }}>
          <ProgressBar progress={progress} color={'rgb(90,192,234)'} style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />
        </View>
      </Animated.View>
    </View>
  )
}

export default LoadingScreen;