import { Platform } from 'react-native';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import App from './App';
import { enableLegacyWebImplementation } from 'react-native-gesture-handler';
import * as WebBrowser from "expo-web-browser";

if(Platform.OS === 'web'){
  require("./css/style.css");
}

WebBrowser.maybeCompleteAuthSession();

if (Platform.OS === 'web') { 
  enableLegacyWebImplementation(true) 
};

registerRootComponent(App);