import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 340.15 339.69"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="a"
          x1={92.99}
          y1={-916.62}
          x2={90.62}
          y2={-150.62}
          gradientTransform="matrix(.43 0 0 .43 130.34 400.55)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#c5e8f7" />
          <Stop offset={0.42} stopColor="#83c7ed" />
          <Stop offset={0.56} stopColor="#49b6e8" />
          <Stop offset={0.65} stopColor="#24a8e0" />
          <Stop offset={1} stopColor="#2b74b8" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={105.98}
          y1={-881.86}
          x2={77.96}
          y2={-187.43}
          gradientTransform="matrix(.43 0 0 .43 130.34 400.55)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#2e73b6" />
          <Stop offset={0.47} stopColor="#50a1d3" />
          <Stop offset={0.77} stopColor="#3f98ca" />
          <Stop offset={1} stopColor="#7fc0df" />
        </LinearGradient>
      </Defs>
      <Path
        d="M166.74.02c130.99-2.17 216.35 139.75 150.87 254.33-64.17 112.27-226.15 114.09-293.07 3.46C-43.03 146.13 36.96 2.18 166.74.02z"
        fill="url(#a)"
      />
      <Path
        d="M164.58 15.18c125.64-4.22 204.5 138.1 132.68 242.21-61.44 89.07-192.48 88.92-254.11.43-69.56-99.88.59-238.58 121.43-242.64z"
        fill="url(#b)"
      />
      <Path
        d="M263.5 235.09c4.4.18 9.92 1.23 6.93 6.93l-201.3-.43c-.17-6.62 2.7-6.31 8.66-6.49 3.66-.11 7.51.09 11.26 0 14.83-.35 29.75.07 44.59 0h3.46c5.19-.02 10.4.02 15.58 0 11.39-.03 22.81-.03 34.2 0 5.33.02 10.69-.02 16.02 0h3.46c14.65.06 29.57-.76 44.16-.43 3.42.08 8.94.27 12.99.43z"
        fill="#7fba40"
      />
      <Path
        d="M270.42 242.02c-.65 1.24-4.39 5.24-3.25 1.73H70.64l-2.38-1.08c.25-.33.88-.65.87-1.08l201.3.43z"
        fill="#1472a3"
      />
      <Path
        d="M263.5 235.09c-4.05-.17-9.56-.36-12.99-.43-.47-2.44.45-5.61.43-7.79 4.06-2.66 6.12 3.65 6.71 3.9 1.88.79 6-.91 5.84 4.33zM89.04 235.09c-3.74.09-7.6-.11-11.26 0 .73-1.87.42-4.34 2.38-5.63 3.65-2.39 5.41 1.95 8.44-2.6 0 2.61.89 5.53.43 8.23z"
        fill="#469643"
      />
      <Path
        d="M206.35 159.76c-.06 5.19.02 10.4 0 15.58-2.23 2.42.07 15.77-.87 17.1-.33.47-1.95.11-2.6.22-.02-12.11.08-24.28 0-36.36 4.05-.21 1.5 2.72 3.46 3.46z"
        fill="#83a5b9"
      />
      <Path
        d="M206.35 175.35c-.09 19.91.07 39.83 0 59.74h-3.46c-.02-14.13.02-28.29 0-42.42.65-.11 2.26.26 2.6-.22.94-1.32-1.36-14.68.87-17.1z"
        fill="#a8b0b4"
      />
      <Path
        d="M250.51 220.37c-.06-2.27-.83-5.31-.87-7.58-.26-18.4 1.09-36.98.43-55.41l-43.72.22c.01-.72-.02-1.45 0-2.16l43.29-.22-43.29-.65c.04-1.43-.08-2.9 0-4.33l43.07 1.73 1.08-3.03v71.43z"
        fill="#e8e6e2"
      />
      <Path
        d="M250.51 148.94l-1.08 3.03-43.07-1.73c.05-.84-.23-4.45.22-4.76l43.94 3.46z"
        fill="#fff"
      />
      <Path d="M206.35 155.44v-.87l43.29.65-43.29.22z" fill="#b2aea9" />
      <Path
        d="M250.51 220.37c.06 2.19.42 4.33.43 6.49s-.9 5.36-.43 7.79c-14.59-.33-29.51.5-44.16.43.07-19.91-.09-39.83 0-59.74.02-5.19-.06-10.4 0-15.58 0-.72-.01-1.45 0-2.16l43.72-.22c.66 18.44-.69 37.01-.43 55.41.03 2.27.81 5.31.87 7.58z"
        fill="#fff"
      />
      <Path
        d="M214.8 173.18c1.27.26 6.15-.62 6.71.65.27.62.19 8.5-.65 8.87-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87z"
        fill="#66afdc"
      />
      <Path
        d="M214.8 185.74c1.4.26 5.8-.45 6.71.22.38.27.12 8.97-.65 9.31-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87zM214.8 198.29c1.3.24 5.47-.42 6.28.22.61.48.86 8.83-.22 9.31-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M215.23 160.63c7.59-2.37 7.13 4.1 5.63 9.52l-6.71-.65c.97-1.35-.15-8.49 1.08-8.87zM238.17 160.63c8.97-1.4 6.01 2.83 6.06 9.52-9.22 1.5-6.67-3.06-6.06-9.52zM238.17 173.18l6.28.22-.22 9.31c-9.27 1.44-6.09-2.8-6.06-9.52z"
        fill="#66afdc"
      />
      <Path
        d="M238.17 185.74L244.45 185.96 244.23 195.26 237.96 195.05 238.17 185.74z"
        fill="#66afdc"
      />
      <Path
        d="M226.48 173.18L232.76 173.4 232.54 182.71 226.27 182.49 226.48 173.18z"
        fill="#66afdc"
      />
      <Path
        d="M238.17 198.29L244.45 198.51 244.23 207.82 237.96 207.6 238.17 198.29z"
        fill="#66afdc"
      />
      <Path
        d="M238.17 210.85L244.45 211.06 244.23 220.37 237.96 220.15 238.17 210.85z"
        fill="#2d8fcd"
      />
      <Path
        d="M226.48 160.63L232.76 160.85 232.54 170.15 226.27 169.94 226.48 160.63z"
        fill="#66afdc"
      />
      <Path
        d="M226.48 198.29l6.28.22-.22 9.31c-8.85 1.42-6.06-3.15-6.06-9.52zM215.23 210.85c.39-.17 5.67-.03 5.84.22l-.22 9.31c-1.23-.25-5.72.6-6.28-.65-.27-.62-.19-8.5.65-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M226.48 185.74L232.76 185.96 232.54 194.83 226.27 194.61 226.48 185.74z"
        fill="#66afdc"
      />
      <Path
        d="M226.27 212.58c1.18-1.6 3.85-.19 5.63-1.3.04.01.55-1.12.87-.22.19.55.16 9.04-.22 9.31l-6.28-.22c.29-2.28-.4-5.44 0-7.58z"
        fill="#2d8fcd"
      />
      <Path
        d="M231.9 211.28c-1.78 1.1-4.45-.3-5.63 1.3.09-.48-.22-1.47.22-1.73 1.92.02 3.54-.23 5.41.43z"
        fill="#227ebe"
      />
      <Path
        d="M158.3 82.28c.84-.1 1.75.05 2.6 0 4.65-.27 15.71-.68 19.91 0s2.07 1.82 3.03 2.81c3.87 4.03 5.22 4.49 10.82 5.19-.17 11.69 1.4 6.42 7.79 8.01l-.65 2.6-66.02.43c.05-.66-.22-2.72.22-3.03 13.01 2.93 6.67-6.94 9.09-8.66.5-.35 5.18.38 6.06 0 1.67-.72.96-6.62 7.14-7.36z"
        fill="#fff"
      />
      <Path
        d="M202.03 105.65c-2.3 1.44-4.2.73-6.93.87v-.43c-.02-.44.88-.74.22-1.3-3.03 1.23-6.8.02-9.74 0-4.25-.03-8.67.45-12.99.43-1.87 0-3.76-.03-5.63 0-2.73.04-5.49-.06-8.23 0-1.87.04-3.76-.07-5.63 0-2.13.08-6.28-.41-8.01 0-.4.09-.15 1.29-.22 1.3-2.32.42-5.03 1.1-7.36.43-.55-2.61-2.1-.91-1.73-5.63l66.02-.43.65-2.6c.37.09 1.07-.21 1.3.22-.34 3.24 1.22 5.3-1.73 7.14z"
        fill="#e8e6e2"
      />
      <Path
        d="M178.65 79.68c-2.5-1.49-.81.89-1.95 1.3-1.4.5-12.33-.88-14.5-.87.98-1.46 3.17-3.92 3.03-5.63 1.34-1.67 3.12-2.88 3.9-4.98l.65-11.47c.98 3.39-.58 9.28.22 12.34.49 1.89 7.45 7.1 8.66 9.31z"
        fill="#fff"
      />
      <Path
        d="M178.65 79.68c1.06.63 1.73 1.45 2.16 2.6-4.2-.68-15.27-.27-19.91 0-.25-1.05 1-1.72 1.3-2.16 2.18-.02 13.1 1.37 14.5.87 1.14-.41-.55-2.78 1.95-1.3z"
        fill="#e8e6e2"
      />
      <Path
        d="M162.2 80.11c-.3.44-1.55 1.11-1.3 2.16-.85.05-1.76-.1-2.6 0 1.82-2.98 4.79-5.12 6.93-7.79.14 1.7-2.05 4.17-3.03 5.63z"
        fill="#bfc4c6"
      />
      <Path
        d="M185.57 104.79v.87c-.72 0-4.03-.2-4.33.22.47 8.54.79 10.6-8.66 8.23v-8.87c4.31.01 8.74-.47 12.99-.43zM166.96 105.22c.05.41-.08.9 0 1.3.55 2.6.49 4.95.43 7.58-10.34 2.31-8.66.29-8.66-8.01v-.87c2.73-.06 5.49.04 8.23 0z"
        fill="#66afdc"
      />
      <Path
        d="M158.74 106.09c0 8.3-1.68 10.32 8.66 8.01.06-2.63.12-4.98-.43-7.58l5.63-.43v8.01c9.45 2.37 9.13.32 8.66-8.23.29-.41 3.6-.21 4.33-.22 1.08 0 .82.43.87.43-.55 8.54-.55 10.29 8.66 8.01-.02-2.52.03-5.06 0-7.58 2.72-.13 4.62.57 6.93-.87.41 16.84.76 33.81.87 50.65.08 12.09-.02 24.25 0 36.36.02 14.13-.02 28.29 0 42.42-5.33-.02-10.69.02-16.02 0l-.65-37.66-33.12.65-.43 37.01c-5.19.02-10.4-.02-15.58 0l.43-128.14c2.33.67 5.04-.01 7.36-.43-.35 8.64-.93 8.75 8.01 8.23.41-.29.22-7.44.22-8.66h5.63z"
        fill="#fff"
      />
      <Path
        d="M173.24 150.24c.59-.26 7.63-.2 8.01.65.23 9.1 1.44 10.41-8.66 9.09.31-1.53-.69-9.15.65-9.74zM187.09 135.09c.59-.26 7.63-.2 8.01.65.31 8.53 1.25 11.2-8.66 8.66.31-1.49-.68-8.72.65-9.31zM173.24 135.09c.59-.26 7.63-.2 8.01.65.31 8.53 1.25 11.2-8.66 8.66.31-1.49-.68-8.72.65-9.31zM187.09 150.24c.59-.26 7.63-.2 8.01.65.31 8.53 1.25 11.2-8.66 8.66.31-1.49-.68-8.72.65-9.31zM159.38 135.09c.38-.17 7.36-.09 7.58.22.28.4.92 8.7-.22 9.74l-8.01-.65c.31-1.49-.68-8.72.65-9.31z"
        fill="#2d8fcd"
      />
      <Path
        d="M186.66 165.83L195.1 166.04 194.88 175.35 186.44 175.13 186.66 165.83z"
        fill="#2d8fcd"
      />
      <Path
        d="M186.66 119.94L195.1 120.15 194.88 129.46 186.44 129.25 186.66 119.94z"
        fill="#66afdc"
      />
      <Path
        d="M172.8 119.94L181.25 120.15 181.03 129.46 172.59 129.25 172.8 119.94z"
        fill="#66afdc"
      />
      <Path
        d="M186.66 181.41l8.44.22c.23 9.1 1.44 10.41-8.66 9.09l.22-9.31zM172.8 165.83l8.44.65c.39 9.12.97 9.8-8.66 8.66l.22-9.31z"
        fill="#2d8fcd"
      />
      <Path
        d="M145.1 150.24L153.11 150.46 152.89 160.2 144.88 159.98 145.1 150.24z"
        fill="#2d8fcd"
      />
      <Path
        d="M145.1 135.09L153.11 135.31 152.89 145.05 144.88 144.83 145.1 135.09z"
        fill="#2d8fcd"
      />
      <Path
        d="M159.38 150.24c.61-.19 7.36-.09 7.58.22l-.22 9.74-8.01-.22c.18-1.43-.48-9.39.65-9.74zM172.8 181.41c.33-.23 7.69-.03 8.01.22.88 1.17.27 8.96-.22 9.31-.33.23-6.93-.02-8.01-.22.2-1.35-.36-8.9.22-9.31z"
        fill="#2d8fcd"
      />
      <Path
        d="M158.95 119.94c1.22.19 7.61-.35 8.01.22.27.38.24 8.02.22 9.31l-8.44-.22.22-9.31z"
        fill="#66afdc"
      />
      <Path
        d="M145.1 165.83l8.44.22c-1.02 1.44.08 8.79-.65 9.31-.46.33-6.66-.3-8.01.22l.22-9.74z"
        fill="#2d8fcd"
      />
      <Path
        d="M144.67 119.94L153.11 120.15 152.89 129.46 144.88 129.25 144.67 119.94z"
        fill="#66afdc"
      />
      <Path
        d="M158.95 165.83L166.96 166.04 166.74 175.35 158.74 175.13 158.95 165.83z"
        fill="#2d8fcd"
      />
      <Path
        d="M145.1 181.41L153.11 181.63 152.89 190.93 144.88 190.72 145.1 181.41z"
        fill="#2d8fcd"
      />
      <Path
        d="M158.95 181.41L166.96 181.63 166.74 190.93 158.74 190.72 158.95 181.41z"
        fill="#2d8fcd"
      />
      <Path
        d="M153.11 105.22v.87c0 1.22.2 8.37-.22 8.66-8.94.53-8.36.42-8.01-8.23.06-.01-.18-1.2.22-1.3 1.73-.41 5.88.08 8.01 0zM195.1 106.09v.43c.03 2.52-.02 5.05 0 7.58-9.2 2.28-9.21.53-8.66-8.01 2.88.01 5.8-.18 8.66 0z"
        fill="#66afdc"
      />
      <Path
        d="M195.1 106.09c-2.85-.18-5.78.01-8.66 0-.05 0 .21-.44-.87-.43v-.87c2.94.02 6.71 1.23 9.74 0 .66.56-.23.86-.22 1.3z"
        fill="#527f9b"
      />
      <Path
        d="M172.59 105.22v.87l-5.63.43c-.08-.4.05-.88 0-1.3 1.87-.03 3.76 0 5.63 0zM158.74 105.22v.87h-5.63v-.87c1.87-.07 3.76.04 5.63 0z"
        fill="#b2aea9"
      />
      <Path
        d="M186.87 235.09c-11.39-.03-22.81-.03-34.2 0l.43-37.01 33.12-.65.65 37.66z"
        fill="#fff"
      />
      <Path
        d="M155.7 222.1c.81-.53 1.49-.25 2.16-1.08s9.62-15.55 9.96-16.45c.22-.6-.13-2.32.43-3.25l.87 31.39-13.2.65c-.46-.33-.19-9.73-.22-11.26zM170.86 218.64l1.73 4.11c1.94-5.95 7.98-12.06 10.39-17.32.71-1.55 1.13-2.49.43-4.11l.87 31.82-13.42-.43c-.06-4.69.08-9.39 0-14.07z"
        fill="#2d8fcd"
      />
      <Path
        d="M183.41 201.32c.7 1.62.28 2.56-.43 4.11-2.41 5.26-8.45 11.37-10.39 17.32l-1.73-4.11c-.1-5.69.07-11.4 0-17.1l12.55-.22zM168.26 201.32c-.57.93-.21 2.64-.43 3.25-.33.9-9.26 15.6-9.96 16.45s-1.35.55-2.16 1.08c-.13-6.84.1-13.71 0-20.56l12.55-.22z"
        fill="#2d8fcd"
      />
      <Path
        d="M133.19 154.57l-43.29.65 43.29.22c0 .3.42.76.43 1.73l-44.59.43c.05-1.85-.15-3.79 0-5.63 5.58-.7 41.32-.18 43.51-1.73.53-.38.06-3.86.22-4.76l.87.22c.51 2.84-.51 6.18-.43 8.87z"
        fill="#e8e6e2"
      />
      <Path
        d="M132.76 145.48c-.16.9.31 4.39-.22 4.76-2.18 1.55-37.92 1.03-43.51 1.73.06-.71-.23-3.15.22-3.46l43.51-3.03z"
        fill="#fff"
      />
      <Path d="M133.19 154.57v.87l-43.29-.22 43.29-.65z" fill="#b2aea9" />
      <Path
        d="M133.63 157.17c.37 25.96-.27 51.96 0 77.92-14.84.07-29.76-.35-44.59 0 .46-2.7-.43-5.61-.43-8.23 0-22.98-.16-46.3.43-69.26l44.59-.43z"
        fill="#fff"
      />
      <Path
        d="M95.75 160.63c.39-.17 6.1-.04 6.28.22l-.22 9.31-6.71-.22c.31-1.49-.68-8.72.65-9.31z"
        fill="#66afdc"
      />
      <Path
        d="M118.69 185.74l6.71.22c-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87zM95.75 210.85c.39-.17 6.1-.04 6.28.22l-.22 9.31c-1.27-.26-6.15.62-6.71-.65-.27-.62-.19-8.5.65-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M95.75 173.18c.25-.19 6.1-.04 6.28.22l-.22 9.31-6.71-.22c.35-1.81-.74-8.25.65-9.31zM95.75 198.29c.39-.17 6.1-.04 6.28.22l-.22 9.31c-1.27-.26-6.15.62-6.71-.65-.27-.62-.19-8.5.65-8.87zM119.12 160.63c.52-.23 5.9-.21 6.28.65.18.41.15 8.62-.22 8.87l-6.71-.22c.31-1.49-.68-8.72.65-9.31zM107.44 160.63c.52-.23 5.9-.21 6.28.65.18.41.15 8.62-.22 8.87l-6.71-.22c.31-1.49-.68-8.72.65-9.31z"
        fill="#66afdc"
      />
      <Path
        d="M107 185.74l6.71.22c-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87z"
        fill="#3f9ed5"
      />
      <Path
        d="M119.12 198.29c.39-.17 6.1-.04 6.28.22-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.27-.62-.19-8.5.65-8.87zM107.44 198.29c.39-.17 6.1-.04 6.28.22-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.27-.62-.19-8.5.65-8.87zM119.12 210.85c.52-.23 5.9-.21 6.28.65.27.62.19 8.5-.65 8.87-.52.23-5.9.21-6.28-.65-.27-.62-.19-8.5.65-8.87zM107.44 210.85c.42-.22 5.89-.22 6.28.65.27.62.19 8.5-.65 8.87-.52.23-5.9.21-6.28-.65.21-1.47-.54-8.26.65-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M95.32 185.74l6.71.22c-.04 6.38 1.82 11.37-6.93 8.66l.22-8.87z"
        fill="#66afdc"
      />
      <Path
        d="M118.69 173.18l6.71.22c-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.18-.41-.15-8.62.22-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M119.99 174.05c5.58-2.49 4.91 4.4 4.33 7.79l-4.98-.22c.28-1.36-.64-7 .65-7.58z"
        fill="#66afdc"
      />
      <Path
        d="M107.44 173.18c.39-.17 6.1-.04 6.28.22-.31 1.49.68 8.72-.65 9.31-.52.23-5.9.21-6.28-.65-.27-.62-.19-8.5.65-8.87z"
        fill="#2d8fcd"
      />
      <Path
        d="M108.3 174.05c5.58-2.49 4.91 4.4 4.33 7.79l-4.98-.22c.28-1.36-.64-7 .65-7.58z"
        fill="#66afdc"
      />
    </Svg>
  )
}

export default SvgComponent
