import {
	CURRENT_PAGE,
	SWITCH_TAB,
	ADD_TAB,
	ACTIVE_TAB,
	CLOSE_TAB,
	NAVIGATE_TAB,
	LOGIN_FLIP,
	BACK_TO_LOGIN,
	CHANGE_MENU,
	OPEN_DATA,
	OPEN_PROJECT,
	USER_HIERARCHY_SOCKET
} from '../actions/types';
import {
	updateObject,
	updateImmutableArray,
	updateItemInArray,
} from './utils/globalStateFunctions';

const initialState = {
	tabs: [],
	page: { route: { key: {}, name: {}, params: {} } },
	loginFlip: false,
	menuType: 'default',
	dataBrowserPage: {dataId: undefined},
	projectPage: {projectId:undefined},
	userHierarchySocket: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case USER_HIERARCHY_SOCKET:
			return updateObject(state, {
				...state,
				userHierarchySocket: action.payload,
			});
		case OPEN_DATA:
			return updateObject(state, {
				...state,
			});
		case CURRENT_PAGE:
			return updateObject(state, {
				...state,
				page: {
					route: {
						...state.page.route,
						key: action.payload.route.key,
						name: action.payload.route.name,
						params: action.payload.route.params,
					},
				},
			});
		case CHANGE_MENU:
			return updateObject(state, {
				...state,
				menuType: action.payload,
			});
		case SWITCH_TAB:
			const switchTab = state.tabs.map((tab) => ({
				...tab,
				active: tab.id === action.payload ? true : false
			}));
			return updateObject(state, {
				...state,
				tabs: switchTab,
			});
		case NAVIGATE_TAB:
			// Replace active tab page with new page without mutating state
			const updateNavigateTabs = state.tabs.map((tab) => {
				if (tab.active) {
					return {
						...tab,
						page: action.payload.name,
						route: action.payload.routes,
					};
				}
				return { ...tab };
			});

			return updateObject(state, {
				...state,
				page: { ...action.payload },
				tabs: [...updateNavigateTabs],
			});
		case ADD_TAB:
			const deactiveOldTabs = state.tabs.map((tab) => ({
				...tab,
				active: false
			}));
			return updateObject(state, {
				...state,
				tabs: [...deactiveOldTabs, action.payload],
			});
		case ACTIVE_TAB:
			return updateObject(state, {
				...state,
				tabs: state.tabs,
			});
		case CLOSE_TAB:
			const findIndex = state.tabs.findIndex(
				(element) => element.id === action.payload
			);
			const returnIndex = findIndex - 1;

			const updatedTabs = state.tabs.map((element, i) => {
				if (i === returnIndex) {
					return {
						...element,
						active: true,
					};
				}
				return element;
			});
			const newTabs = state.tabs.filter(
				(tabs) => tabs.id !== action.payload
			);
			return updateObject(state, {
				...state,
				tabs: newTabs,
			});
		case LOGIN_FLIP:
			return updateObject(state, {
				...state,
				loginFlip: !state.loginFlip,
			});
		case BACK_TO_LOGIN:
			return updateObject(state, {
				...state,
				loginFlip: false,
			});
		default:
			return state;
	}
}
