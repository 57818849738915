import DropDownPicker from 'react-native-dropdown-picker';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import styles from '@stylesheet';
import { CHANGE_MENU, CLOSE_MENU_WARPER } from '@reduxLocal/actions/types';
import menuStates from '../Components/menuStates';
import { useDispatch, useSelector } from 'react-redux';
import { View, Pressable, Text, FlatList, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';

export type MenuWarperHandle = {
  toggleVisibility: () => void;
  animateOut: () => void;
};

export const MenuWarper = forwardRef<MenuWarperHandle>((props, ref) => {
  const [items, setItems] = useState(menuStates);
  const [showSelector, setShowSelector] = useState(false);
  const animRef = useRef<Animatable>(null);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => {
    return {
      toggleVisibility: () => {
        if(showSelector) {
          animRef.current?.fadeOutLeft?.();
          setTimeout(() => { setShowSelector(false) }, 350)
        } else {
          setShowSelector(true);
          animRef.current?.fadeInLeft?.();
        }
      },
      animateOut: () => {
        animRef.current?.fadeOutLeft?.();
      }
    }
  }, [showSelector]);


  return showSelector && (
    <Animatable.View ref={animRef} animation={'fadeInLeft'} duration={500} style={{ position: 'relative', borderBottomEndRadius: 20, borderTopEndRadius: 20, shadowRadius: 20, shadowOpacity: .2, flex: Platform.OS === 'web' ? 0.0000001 : 0.00001, minWidth: 250, alignSelf: 'stretch', paddingHorizontal: 10, minHeight: 300, zIndex: -1, backgroundColor: 'rgb(90,192,234)', overflow: 'visible', }}>
      <FlatList
        data={menuStates}
        style={{ flex: 1, alignSelf: "stretch", minHeight: 100 }}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flex: 1, paddingTop: 45, paddingBottom: 100, alignSelf: 'stretch', gap: 10, marginVertical: 10}}
        ItemSeparatorComponent={() => <View style={{ flex: 1, minHeight: 1, backgroundColor:'#00000033' }} />}
        renderItem={({ item }) => { return (<Pressable style={{ minHeight: 30, maxHeight: 30, maxWidth: "100%" }} onPress={() => { setTimeout(() => { setShowSelector(false); dispatch({ type: CHANGE_MENU, payload: item.value }); setShowSelector(false) }, 350) }}><Text style={{ color: 'white', textAlign: 'left', fontSize: 14 }}>{item.label}</Text></Pressable>) }} />
    </Animatable.View>
  )
})

export default MenuWarper;