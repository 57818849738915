import { Platform, Text, View, useWindowDimensions } from 'react-native';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from '@stylesheet';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';
import LottieView from '@utils/lottieAnimation';
import { useLottieAnim } from '@hooks/lottieHook';
import { Pressable } from 'react-native';
import { Entypo, Ionicons } from "@expo/vector-icons";
import FlipCard from 'react-native-flip-card';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import GoogleButtonMail from '../../../TopLevel/Components/Authorisation/GoogleButton';
import MicrosoftButtonMail from '../../../TopLevel/Components/Authorisation/MicrosoftButtonMail';
import { DefaultRootState } from '@reduxLocal/persistState';
import { HIDE_ALL_MODALS, HIDE_MAIL_MODAL, SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { LoadingModal, LoadingModalHandle, StyledModal, StyledModalHandle, Title } from '@constants/Themed';
import GenerateSchemaForm from '@hooks/generateSchemaForm';
import apis from '@api/api';
import { XStack } from 'tamagui';

const MailLoginModal = () => {
  const mailVisible = useSelector((state:DefaultRootState)=> state.modals.mailModal);
  const [visibleState, setVisibleState] = useState(mailVisible);
  const [flip, setFlip] = useState(false);
  const dispatch = useDispatch();
  const animation = useLottieAnim();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const mailModalRef = useRef<StyledModalHandle>(null);
  const loadingModalRef = useRef<LoadingModalHandle>(null);
  const manMailRef = useRef<any>(null);

  const _handleLoading = useCallback((loading: boolean) => {
    if (loading) {
      loadingModalRef.current?.openModal();
    } else {
      loadingModalRef.current?.closeModal();
    }
  }, []);

  useEffect(()=>{
    if(mailVisible){
      mailModalRef.current?.openModal();
    } else {
      mailModalRef.current?.closeModal();
    }
  },[mailVisible])

  return (
    <StyledModal
    title="Add your email account"
    caption='Sign in to your email account to enable our AI intelligence to personalize your experience and provide you with tailored recommendations'
     ref={mailModalRef} customContent={(
      <View style={{ flex: 2, gap: 30 }}>
        <Title style={{ maxHeight: 60, color: "white" }}>Sign in to your email</Title>
        <LottieView ref={animation} style={{ width: '100%' }} source={require('@assets/animations/95559-send-mail.json')} autoPlay loop={true}></LottieView>
        <Text style={{ color: "white" }}>Want a personal assistant to help with your customers? You got it!</Text>
        </View>
    )}>
      <LoadingModal ref={loadingModalRef} />
        <View style={{ flex: 3, flexDirection: 'column' }}>
          <FlipCard
            flipHorizontal={true}
            flipVertical={false}
            flip={flip}
            clickable={false}
          >
          <View style={[styles.face, { flex: 1, maxWidth: 600, gap: 20, paddingVertical: "20%" }]}>
              <View style={{ flex: 1, }}>
              <Text adjustsFontSizeToFit style={{ textAlign: 'center', lineHeight: 20 }}>Please sign in to your email account to enable our AI intelligence to personalize your experience and provide you with tailored recommendations</Text>
              </View>
              <View style={{ flex: 3, alignSelf: 'center' }}>
                <MicrosoftButtonMail />
              <GoogleButtonMail scopes={['email', 'profile', 'https://www.googleapis.com/auth/gmail.modify']} loading={_handleLoading} callback={() => {
                setTimeout(() => dispatch({ type: HIDE_ALL_MODALS }), 500)
                  setVisibleState(false);
                }} />
              </View>
              <Text adjustsFontSizeToFit style={{ textAlign: 'center' }}>Or sign-in manually with SMTP</Text>
              <View style={{ minHeight: 1, backgroundColor: 'gray', margin: 10, opacity: 0.6 }} />
              <Pressable
                onPress={() => {
                  setFlip(true)
                }}
                style={[styles.BackToLogin, { flexDirection: 'row', flex: 1, maxHeight: 50, alignContent: 'center', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-evenly', width: 170 }]}
              >
                <View />
                <Text adjustsFontSizeToFit style={{ color: 'white', fontWeight: '600' }}>Manual Configuration</Text>
                <View />
              </Pressable>
            </View>
            <View style={[styles.back, { flex: 1, maxWidth: 600 }]}>
            <GenerateSchemaForm
              submitFunction={apis.authManMail}
              ref={manMailRef}
              hideButton
              extraCallBack={() => {
                dispatch({ type: HIDE_ALL_MODALS });
                setVisibleState(false);
              }}
              schema={{
                email: {
                  type: 'string',
                  title: 'email',
                  format: 'email'
                },
                password: {
                  type: 'string',
                  title: 'password',
                  format: 'password'
                },
                port: {
                  type: 'number',
                  title: 'port'
                },
                host: {
                  type: 'string',
                  title: 'host'
                },
                smtpPort: {
                  type: 'number',
                  title: 'smtp_port'
                },
                smtpSecure: {
                  type: 'boolean',
                  title: 'secure'
                },
                visible_to_company: {
                  type: 'boolean',
                  title: 'visible_to_company',
                  content: {helpText: "Should this email account be visible to the company?"}
                }
              }}
            />
            <XStack>
              {/* <LottieView style={{ flex: 1, maxHeight: 110 }} source={require('@assets/animations/113096-coming-soon.json')} autoPlay loop={false} /> */}
              <Pressable
                onPress={() => {
                  setFlip(false)
                }}
                style={[styles.BackToLogin, { flexDirection: 'row', maxHeight: 40, minWidth: 200, flex: 1, justifyContent: 'space-evenly' }]}
              >
                <View />
                <Ionicons name={"arrow-back-outline"} size={20} color={'white'} ></Ionicons>
                <Text adjustsFontSizeToFit style={{ color: 'white' }}>Back</Text>
                <View />
              </Pressable>
              <Pressable
                onPress={() => {
                 manMailRef.current?.submitForm?.();
                }}
                style={[styles.BackToLogin, { flexDirection: 'row', maxHeight: 40, minWidth: 200, flex: 1, justifyContent: 'space-evenly' }]}
              >
                <View />
                <Text adjustsFontSizeToFit style={{ color: 'white' }}>Submit</Text>
                <Ionicons name={"arrow-forward-outline"} size={20} color={'white'} ></Ionicons>
                <View />
              </Pressable>
            </XStack>
            </View>
          </FlipCard>
        </View>
    </StyledModal>
  )
}

export default MailLoginModal;