import React, { useEffect, useState } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Text } from 'react-native';
import { View } from "tamagui";
import apis from '../../../api/api';
import { useDispatch, useSelector } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";

const stripePromise = loadStripe(__DEV__ ? "pk_test_51JdGitJpKdabcCSwVeVUOXC4QX1sA5RTCM5oTX4LB4HqSioyZVtPUbiSPUCC9nSwh7eDC8YfL5j2Tv2J4d8Sh9WB0064QtKTAZ" : "pk_live_51JdGitJpKdabcCSwhQYRT5BOXrxF6CIhvcU3AMRfxZAonNHXAyeUtTwa9ho4xePZgpcy8EjiLfYqK2S9ZPu5eoAe00Ueyddh9q");


const CheckoutForm = ({ product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const options = { clientSecret };
  const billingId = useSelector((state: DefaultRootState) => state.promiseData.data.userData.billing.billingId);
  
  const getCheckoutSession = async () => {
    setIsLoading(true);
    await apis.getCheckoutSession({ billingId: billingId, productId: product }).then((res) => {
      setClientSecret(res.clientSecret);
    })
    setIsLoading(false);
  }

  useEffect(() => {
    getCheckoutSession();
  }, []);

  return (
    <View style={{ flex: 1, gap: 20, alignSelf: "stretch", minWidth: "100%", overflowY: "scroll" }}>
      {
        clientSecret &&
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      }
    </View>
  );
}

export default CheckoutForm;