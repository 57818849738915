import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

let Config = {
  apiUrl: 'http://192.168.1.133:4000',
  webApi: __DEV__ ? 'https://localhost:4000' : "https://api.smartsaas.pro",
  aiAPI: __DEV__ ? (Platform.OS === 'web' ? "https://localhost:11435" : "http://192.168.1.133:11435") : "https://ai.smartsaas.pro:11437",
  aiChatAPI: __DEV__ ? (Platform.OS === 'web' ? "http://localhost:11434" : "http://192.168.1.133:11434") : "https://ai.smartsaas.pro:11436",
  enableHiddenFeatures: false,
  appStoreSecret: '35322a6bc5274befa6ca622ff79be5db'
};

if (Updates.channel === 'production') {
  Config.apiUrl = 'https://api.smartsaas.pro';
  Config.webApi = 'https://api.smartsaas.pro';
  Config.enableHiddenFeatures = false;
} else if (Updates.channel === 'staging') {
  Config.apiUrl = 'https://api.staging.com';
  Config.enableHiddenFeatures = true;
}

export default Config;