import { View, Platform, useWindowDimensions } from 'react-native';
import Modal from 'react-native-modal';
import styles from '@stylesheet';
import AddCompanyForm from '../../../navigation/HeaderBar/components/AddCompanyForm';
import React, { useEffect } from 'react';
import { HIDE_ADD_COMPANY_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import { BlurView } from 'expo-blur';
import { BlurView as MobileBlur } from 'expo-blur';
import * as Animatable from 'react-native-animatable';
import getGlobalUser from '@hooks/getGlobalUser';
import { StyledModal, Title, Text } from '@constants/Themed';
import Button from '@components/UI/Button/Button';
import LottieView from '@utils/lottieAnimation';
import { AntDesign } from '@expo/vector-icons';

const AddCompanyModal = () => {
  const userId = getGlobalUser();
  const addCompanyVisible = useSelector((state: DefaultRootState) => state.modals.formModal.modalDisplayed);
  const dispatch = useDispatch();
  const addCompanyRef = React.useRef(null);

  useEffect(() => {
    if (addCompanyVisible) {
      addCompanyRef.current.openModal?.();
    }
  }, [addCompanyVisible])

  return (
    <StyledModal
      ref={addCompanyRef}
      title='Add a company'
      extraChildren={<Button icon={(<AntDesign name="exclamation" color={"red"} size={24} />)} buttonText="Create Company" customStyle={{ minWidth: 200 }} onPress={() => dispatch({ type: HIDE_ADD_COMPANY_MODAL })} />}
      caption={`Adding a company or organisation to your account is easy with SmartSaaS, just bear in mind that additional companies are billed seperately. ${'\n'} Companies require approval by admin.`}
      customContent={(
        <Animatable.View animation="fadeIn" style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)', padding: 30, alignSelf: "stretch", minHeight: "100%", gap: 30 }}>
          <Title style={{ fontSize: 32, color: "white", flex: 2, textAlign: "center", marginBottom: 30 }}>Adding a company?</Title>
          <Text style={{ lineHeight: 25, color: "white", flex: 1, fontSize: 18 }}>Adding a company or organisation to your account is easy with SmartSaaS, just bear in mind that additional companies are billed seperately.</Text>
          <LottieView source={require("@assets/animations/company.json")} autoPlay loop style={{ flex: 5 }} />
        </Animatable.View>
      )}
      isVisible={addCompanyVisible}
    >
      <View style={{ flex: 1, paddingHorizontal: 20, top: -20 }}>
        <AddCompanyForm ref={addCompanyRef} />
      </View>
    </StyledModal>
  )
}

export default AddCompanyModal;