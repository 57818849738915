import React from 'react';
import { Text, View, Dimensions, Pressable } from 'react-native';
import { Image } from 'expo-image';
import { Title } from '@constants/Themed';
import * as SVG from 'react-native-svg';
import * as Animatable from 'react-native-animatable';
import moment from 'moment';
import * as WebBrowser from 'expo-web-browser';
import RenderHtml from 'react-native-render-html';
import styles from '@stylesheet';
import { uniqueId } from 'lodash';
import { H3, Separator, SizableText } from 'tamagui';

const decodeHtmlEntities = (text) => {
  return text
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
};




const BlogItem = ({item, index}) => {

  const id = uniqueId();
  const getImage = () => {
    try {
      const image = item.content.rendered?.match(/src=\"([^\"]*)/)[1];
      return image
    } catch (e) {
    }
  }
  return (
    <View key={item.id || id} style={{ flexDirection: 'row', minHeight: 200, maxHeight: 200, paddingVertical: 30, flexWrap: "wrap", flex: 1, minWidth: '100%', alignSelf: 'stretch', overflow: "visible" }}>
      <View style={{ flex: 1, top: 50, left: 20 }} >
        <SVG.Svg width={100} height={100} style={{ flex: 1, marginLeft: -25 }}>
          <SVG.Circle cx={10} cy={10} r={5} scale={3} stroke={'rgba(90,192,234,33)'} strokeWidth={2} fill={'rgb(90,192,234)'} />
        </SVG.Svg>
        <SVG.Svg width={100} height={'140%'} style={{ flex: 1, position: 'absolute', top: 40, zIndex: 0 }}>
          <SVG.Defs>
            <SVG.LinearGradient id="uniqueLineGradient" x1="0" x2="0" y1="0" y2="1">
              <SVG.Stop offset={'0%'} stopColor={'rgb(90,192,234)'}></SVG.Stop>
              <SVG.Stop offset={'30%'} stopColor={'rgba(90,123,234,0)'} stopOpacity={0}></SVG.Stop>
            </SVG.LinearGradient>
          </SVG.Defs>
          <SVG.Rect id={'Somenewid'} width={10} height={Dimensions.get('window').height} fill={"url(#uniqueLineGradient)"} />
        </SVG.Svg >
      </View>
      <Animatable.View animation={'slideInRight'} delay={index * 300} style={{ flex: 10, flexDirection: 'row', gap: 20, maxHeight: 200 }} >
        <View style={{ flex: 1, borderRadius: 20, overflow: 'hidden', backgroundColor: 'white', shadowRadius: 20, shadowOpacity: .2 }} onPress={() => WebBrowser.openBrowserAsync(item?.link)}>
          <Image source={{ uri: getImage() }} style={{ flex: 1, height: 120, }} contentFit='cover' />
        </View>
        <Pressable style={{ flex: 3, zIndex: -1, left: -60, paddingLeft: 80, gap: 10, padding: 15, borderRadius: 20, backgroundColor: 'white', shadowRadius: 20, shadowOpacity: .2 }} onPress={() => WebBrowser.openBrowserAsync(item?.link)}>
          <View style={{ paddingLeft: 60, flexDirection: 'row', alignContent: 'space-between', alignSelf: 'stretch', borderColor: 'grey' }}>
            <H3 style={{ fontSize: 16, flex: 3 }}>{decodeHtmlEntities(item?.title.rendered)}</H3>
            <H3 style={{ textAlign: 'right', fontSize: 16, flex: 2, justifyContent: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', top: -5 }}>{moment(item.date).format('dddd DD MMM YY')}</H3>
          </View>
          <Separator />
          {/* <SizableText style={{ paddingLeft: 60, flex: 1, top: -15 }} numberOfLines={7} adjustsFontSizeToFit><RenderHtml source={{ html: item?.excerpt.rendered }} /></SizableText> */}
        </Pressable>
      </Animatable.View>
    </View>
  )
}
export default BlogItem;