import { useCallback, useEffect, useState } from 'react';
import { View, Pressable, Platform } from 'react-native';
import { Text } from 'tamagui';
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import { HIDE_LOADING_MODAL, INITIALISE_MENU, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL, SHOW_OTP, SHOW_TC_MODAL, SUCCESS } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { createURL } from 'expo-linking';
import { GoogleIcon } from '@assets/--native';
import { navigationRef } from '../../../../rootNavigation';
import apis from '@api/api';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import getTCConfirm from '@components/modals/TCModal/utils/getTCConfirm';
import DeviceInfo from 'react-native-device-info';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import { useNavigation } from '@react-navigation/native';

WebBrowser.maybeCompleteAuthSession();

const GoogleButton = () => {
  const dispatch = useDispatch();
  //Google Authentication Vars
  const [token, setToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const navigation = useNavigation();
  const notificationToken: string = useSelector(
    (state: DefaultRootState) => state.app.notificationToken
  );

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: "530616870665-mt8uulmtk3r41m5ga22sfuc66j632gik.apps.googleusercontent.com",
    iosClientId: "530616870665-sn6qm81jg6eh4pr6dm2ov2n6guovrgna.apps.googleusercontent.com",
    expoClientId: '530616870665-5e22kgtr0e146vutn8veh5shoqnkis5j.apps.googleusercontent.com',
    webClientId: '530616870665-5e22kgtr0e146vutn8veh5shoqnkis5j.apps.googleusercontent.com',
    redirectUri: createURL(''),
    scopes: ['email', 'profile']
  });



  const handleClick = async () => {
    await promptAsync()
      .then((res) => {
      })
  }

  useEffect(() => {
    if (response?.type === "success") {
      setToken(response.authentication.accessToken);
      getUserInfo(token)
    }
  }, [token, response]);

  // Centralized error handler
  function handleError(message) {
    console.error(message);
    setTimeout(() => dispatch({
      type: SHOW_AUTH_MODAL,
      data: { content: message, type: 'error' }
    }), 1000);
  }

  // Helper function to process user data and handle notification tokens
  async function handleUserData(res) {
    let amendedData = res.data.data;

    if (Platform.OS !== 'web') {
      let activeTokens = amendedData.notification_info?.map(item => item.notification_token) || [];

      if (!activeTokens.length || activeTokens.includes(notificationToken)) {
        await apis.updateNotificationToken(notificationToken, amendedData.userData.id);
      }

      amendedData.expireAt = Date.now() + 1000 * 60 * 60 * 24 * 7;
    }

    return amendedData;
  }

  // Helper function to handle Terms & Conditions
  async function handleTermsAndConditions(res) {
    if (res.data.terms) {
      console.log("Terms and Conditions need acceptance");
      dispatch({ type: HIDE_LOADING_MODAL });
      setTimeout(() => dispatch({ type: SHOW_TC_MODAL, payload: res.data.data.userData.id }), 1000);

      try {
        await getTCConfirm();
      } catch (e) {
        console.error('Error awaiting T&C confirmation', e);
        handleError("Could not fetch terms and conditions, please try again");
      }
    }
  }



  async function getUserInfo(token) {
    if (response?.type != 'success') {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to authenticate Google Account, please try again', type: 'error' } })
    }
    if (!token) {
      return
    }
    try {
      dispatch({ type: SHOW_LOADING_MODAL });
      // Fetch user info from Google API
      const response = await fetch("https://www.googleapis.com/userinfo/v2/me", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Failed to fetch user info');

      const user = await response.json();
      const newOAuthUser = {
        surname: user.family_name,
        name: user.given_name,
        id: user.id,
        email: user.email,
        oauthToken: token,
        type: 'google',
        notificationToken,
        device: {
          brand: DeviceInfo.getBrand(),
          model: DeviceInfo.getModel(),
          os: DeviceInfo.getSystemName(),
          osVersion: DeviceInfo.getSystemVersion(),
        },
      };

      // Show loading modal before dispatching OAuth handling
      dispatch({ type: SHOW_LOADING_MODAL });

      const res = await apis.handleOAuth(newOAuthUser);

      // MFA check and Terms & Conditions handling
      if (res.status === 202) {
        dispatch({ type: HIDE_LOADING_MODAL });
        if (res.data.terms) {
          console.log("user has terms and conditions to accept");
          dispatch({ type: SHOW_TC_MODAL, payload: res.data.userId });

          try {
            const confirm = await getTCConfirm();
            console.log('received a response from the TC Confirmation', confirm);
            dispatch({ type: SHOW_OTP, payload: res.data });
          } catch (e) {
            handleError('Please accept our terms of service to continue');
          }
        } else {
          dispatch({ type: SHOW_OTP, payload: res.data });
        }
      } else {
        await handleTermsAndConditions(res);
        const amendedData = await handleUserData(res);
        dispatch({ type: SUCCESS, data: amendedData });
        navigation.navigate('Dashboard');
      }
    } catch (e) {
      console.error(e, 'error fetching user info');
      handleError('Failed to authenticate Google Account, please try again');
    } finally {
      dispatch({ type: HIDE_LOADING_MODAL });
    }
  };


  return (
    <Pressable
      onPress={handleClick} style={{
        minHeight: Platform.select({
          web: 50, default: undefined
        }), height: 50, maxHeight: 50, flex: 1, elevation: 1, shadowRadius: 10, shadowOpacity: .2, backgroundColor: 'white', overflow: "hidden", borderRadius: 30, flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'stretch',
      }}>
      {({ hovered }) => (
        <Animatable.View transition={'backgroundColor'} style={{ paddingHorizontal: "10%", flex: 1, flexDirection: "row", backgroundColor: hovered ? "rgba(90,192,234,0.5)" : "rgba(90,192,234,0)" }}>
          <View style={[styles.centerEverything, { flex: 1, maxWidth: 40 }]}>
            <GoogleIcon />
          </View>
          <View style={{ flex: 4, borderTopRightRadius: 10, borderBottomRightRadius: 10, justifyContent: 'center', alignItems: 'center', minWidth: 220 }}><Text adjustsFontSizeToFit allowFontScaling={false} style={{ fontSize: 15, color: 'black', fontWeight: '600' }}>Sign in with Google</Text>
          </View>
        </Animatable.View>
      )}
    </Pressable >
  );
}

export default GoogleButton;