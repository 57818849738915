import React, { useCallback, useEffect, useRef, useState } from "react";
import { Animated, FlatList, View, TextInput, Dimensions, Platform, Alert, useWindowDimensions, TouchableOpacity, ImageBackground } from "react-native";
import { Text, Title } from "@constants/Themed";
import SwitchWithIcons from 'react-native-switch-with-icons';
import { Image } from 'expo-image';
import { Checkbox } from "tamagui";
import { Pressable } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import styles from "@stylesheet";
import * as WebBrowser from 'expo-web-browser';
import useForm from '@hooks/useForm';
import loginUser from '@api/login';
import { BACK_TO_LOGIN, CURRENT_PAGE, DISMISSED_APP_INFO, FAILED, HIDE_ALL_MODALS, HIDE_LOADING_MODAL, HIDE_PASSWORD_RESET_MODAL, HIDE_REGISTER_MODAL, HOLD_REGISTER_STATE_VALUES, INITIALISE_MENU, LOGIN_FLIP, RESET_APP, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL, SHOW_PASSWORD_RESET_MODAL, SHOW_REGISTER_MODAL, TOGGLE_OTP } from '@reduxLocal/actions/types';
import { DefaultRootState, store } from "@reduxLocal/persistState";
import LottieView from 'lottie-react-native';
import FlipCard from 'react-native-flip-card';
import { Entypo, Ionicons, EvilIcons, FontAwesome6, FontAwesome } from "@expo/vector-icons";
import apis, { registerUser, companyCheck, passwordReset } from '@api/api';
import DynamicContent from "../../../components/login/DynamicLoginContent";
import DropSelect from "../../../components/Global/DropSelect";
import { PanGestureHandler, ScrollView } from "react-native-gesture-handler";
import { useLottieAnim } from "@hooks/lottieHook";
import registrationInfo from "./Components/RegistrationInfo";
import { useTranslation } from "react-i18next";
import OTPInput from "./Components/OTPInput";
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from "expo-linear-gradient";
import CityScape from "@assets/--native/CityScape";
import PasswordResetModal from "./Components/PasswordResetModal";
import { Formik } from "formik";
import AnimatedLottieView from "lottie-react-native";
import { GetAppStore, SmastSaaSWhite } from "@assets/--native";
import GoogleButton from "./Components/Authorisation/GoogleButtonLogin";
import MicrosoftButton from "./Components/Authorisation/MicrosoftButton";
import AppleButton from "./Components/Authorisation/AppleButton";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import HandleIcon from "@hooks/handlePlanIcon";
import { USER_API_BASE_URL } from "@api/constant";
import RegisterModal from "../../../components/modals/RegisterModal/RegisterModal";
import TCModal from "../../../components/modals/TCModal/TCModal";
import { useFocusEffect } from "@react-navigation/native";
import { Button, XStack, YStack } from 'tamagui';
import ChangeLogFetcher from '../../logFetch';
import { H1, H2, H3, H4, H5, Input, SizableText } from "tamagui";
import { backgroundColor } from "react-native-calendars/src/style";
import { useNetInfo } from "@react-native-community/netinfo";
import { motion } from 'framer-motion';

const PageTurnCorner = ({ href, size = 150 }) => {
  return (
    <motion.div
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: size,
        height: size,
        backgroundColor: '#f0f0f0',
        clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%)',
        cursor: 'pointer',
        zIndex: 1000,
        overflow: 'hidden',
      }}
      initial={{
        clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%)',
      }}
      whileHover={{
        clipPath: [
          'polygon(100% 0%, 100% 100%, 0% 100%)',
          'polygon(80% 0%, 100% 100%, 0% 80%)',
          'polygon(0% 0%, 100% 0%, 100% 100%)',
        ],
        transition: { duration: 1, ease: 'easeInOut' },
      }}
      onClick={() => window.location.href = href}
    >
      <motion.div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: '#ff8c00',
          width: '100%',
          height: '100%',
          transformOrigin: 'bottom right',
        }}
        animate={{
          rotate: [0, -10, -20],
          scale: [1, 1.1, 1.2],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      >
        <motion.div
          style={{
            position: 'absolute',
            top: 30,
            left: 30,
            fontSize: 14,
            color: '#fff',
            transform: 'rotate(-45deg)',
            fontWeight: 'bold',
          }}
        >
          Turn Page →
        </motion.div>
      </motion.div>
    </motion.div>
  );
};


/*TODO implement complete Google and Facebook authentication requests and tokens, remaining documentation can be found here https://github.com/expo/expo/pull/9361
import * as Google from 'expo-auth-session/providers/Google';
import * as Facebook from 'expo-auth-session/providers/Facebook';
Login Screen begins
*/

const LoginScreen = ({ navigation }) => {
  const { t } = useTranslation();
  const [loginHovered, setLoginHovered] = useState(false);
  const [registerHovered, setRegisterHovered] = useState(false);
  const [renderingView, setRenderingView] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const { products, modalDisplayed, selectedPlan } = useSelector((state: DefaultRootState) => state.modals.registerModal);
  const flip = useSelector((state: DefaultRootState) => state.navigation?.loginFlip);
  const userState = useSelector((state: DefaultRootState) => state.promiseData);
  const registerModalState = useSelector((state: DefaultRootState) => state.modals.registerModal.modalDisplayed);
  const selectedPlanState = useSelector((state: DefaultRootState) => state.modals.registerModal.selectedPlan);
  const passwordResetModalState = useSelector((state: DefaultRootState) => state.modals?.passwordModalDisplayed);
  const appVersion = useSelector((state: DefaultRootState) => state.app.appVersion)
  const theme = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const [open, setOpen] = useState(false);
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [accessState, setAccessState] = useState(null);
  const [departmentState, setDepartmentState] = useState(null);
  const [otpCode, setOTPCode] = useState("");
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const [isPinReady, setIsPinReady] = useState(false);
  const maximumCodeLength = 6;
  const [awaitingPasscode, setAwaitingPasscode] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const showOtp = useSelector((state: DefaultRootState) => state.UI.showOTP);
  const loginForm = useRef(null);
  const [xyValue, setXYValue] = useState(0);
  const [inverseXYValue, setInverseXYValue] = useState(0);
  const [screenBreakPoint, setScreenBreakPoint] = useState(false);
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [inHovered, setInHovered] = useState(false);
  const [orgHovered, setOrgHovered] = useState(false);
  const isConnected = useNetInfo().isConnected;
  
  ChangeLogFetcher();

  const translateX = new Animated.Value(xyValue);
  const inverseTranslateX = new Animated.Value(inverseXYValue);

  //TODO Create custom scrollbar into hook
  const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
  const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);
  const scrollIndicatorSize =
    completeScrollBarHeight > visibleScrollBarHeight
      ? (visibleScrollBarHeight * visibleScrollBarHeight) /
      completeScrollBarHeight
      : visibleScrollBarHeight;

  const scrollIndicator = useRef(new Animated.Value(0)).current;
  const difference =
    visibleScrollBarHeight > scrollIndicatorSize
      ? visibleScrollBarHeight - scrollIndicatorSize
      : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarHeight / completeScrollBarHeight
  ).interpolate({
    inputRange: [0, difference],
    outputRange: [0, difference],
    extrapolate: 'clamp'
  });
  //Switch Variables
  const [isUserOrganisation, setIsUserOrganisation] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const toggleSwitch = () => setIsUserOrganisation(!isUserOrganisation);
  //TODO #43 Create dynamic state for access state that will be picked up from the component
  const [items, setItems] = useState([{ label: 'SuperAdmin', value: 'SuperAdmin' }, { label: 'Admin', value: 'Admin' }]);
  const [departmentItems, setDepartmentItems] = useState([{ label: 'Management', value: 'Management' }, { label: 'Sales', value: 'Sales' }, { label: 'Finance', value: 'Finance' }, { label: 'Administration', value: 'Administration' }]);
  const animation = useLottieAnim();
  const [showTypeSelection, setShowTypeSelection] = useState(true);
  const loggedOut = useSelector((state:DefaultRootState)=> state.app.loggedOut);

  const { width, height } = useWindowDimensions();

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const registerForm = useRef(null);

  const [isChecked, setChecked] = useState(false);
  const [submitHovered, setSubmitHovered] = useState(false);
  const currentPage = useSelector((state: DefaultRootState) => state.navigation?.page.name);
  const appInfo = useSelector((state: DefaultRootState) => state.UI.appInfo);
  const [switchLogin, setSwitchLogin] = useState(true);
  const [showSwipeHelp, setShowSwipeHelp] = useState(true);
  const [cityKey, setCityKey] = useState(0);

  useEffect(() => { setCityKey(cityKey + 1) },[]);

  //TODO Store on user data
  const [mobilePrompt, setMobilePrompt] = useState(appInfo);

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false
    }).start();
  };

  const breakPoint = () => {
    if (Dimensions.get('window').width > 1200) {
      setScreenBreakPoint(true)
    } else {
      setScreenBreakPoint(false)
    }
  }

  //TODO remove looping state store !
  /* Redirecting user once data is fetched and data is not null and isAuthenticated = true */
  /* Redirect on Session retrieval */
  useEffect(() => {
    async function fetchSession() {
      dispatch({ type: SHOW_LOADING_MODAL })
      await apis.getSession()
      .then((session) => {
        const { isAuthenticated } = session.data;
        
        dispatch({ type: HIDE_LOADING_MODAL });
        if (isAuthenticated) {
          dispatch({ type: INITIALISE_MENU })
          navigation.replace('Dashboard', { screen: 'Welcome', params: { session: session.data } });
        } else {
          dispatch({ type: RESET_APP })
        }
        setRenderingView(false);
        setXYValue(1)
      })
      .catch(error => {
        dispatch({ type: HIDE_LOADING_MODAL });;
        setRenderingView(false);
        setXYValue(1);
      })
    };
  if(!loggedOut)
    fetchSession();
  }, []);

  /* use custom hook to handle form values and handle form submission value */
  const formLoginContext = useForm({
    initialValues: {
      email: null,
      password: null,
    },
    onSubmit: async (formValues) => {
      setTimeout(() => { setShowForgotPassword(true); }, 1000);
      dispatch(loginUser(formValues, (data: { id: undefined; success: boolean }) => {
        if (data?.id !== undefined) {
          console.log('no data stored in id')
        }
      }));
    },
  })

  const handleLoginKeyDown = async (e: { nativeEvent: { key: string; }; }, formValues: any, data) => {
    if (e.nativeEvent.key == "Enter") {
      formLoginContext.handleSubmit;
    }
  }

  const checkedStyle = () => {
    if (!isChecked) {
      return [styles.LoginButtonDisabled, { minWidth: 200, alignSelf: 'stretch' }]
    } else if (submitHovered) {
      return [styles.LoginButtonHovered, { minWidth: 200 }]
    } else { return [styles.LoginButton, { minWidth: 200, backgroundColor: 'grey' }] }
  }


  const handleRegisterKeyDown = async (e: { nativeEvent: { key: string; }; }, formValues: any) => {
    if (e.nativeEvent.key == "Enter") {
      await dispatch(registerUser(formValues));
    }
  }

  function handleResetPress() {
    dispatch({ type: SHOW_PASSWORD_RESET_MODAL })
  }

  function privacyPolicy() {
    WebBrowser.openBrowserAsync(
      'https://www.smartsaas.co.uk/privacy-policy/'
    )
  }

  function termsAndConditions() {
    WebBrowser.openBrowserAsync(
      'https://www.smartsaas.co.uk/terms-and-conditions/'
    )
  }

  function handleAndroidPlayStore() {
    WebBrowser.openBrowserAsync(
      'https://play.google.com/store/apps/details?id=com.anonymous.smartsaas&gl=GB'
    )
  }

  function handleAppleAppStore() {
    Alert.alert('iOS coming soon!', 'Not to worry you can request earlier access by dropping us a message')
  }

  const DropDowns = () => {
    if (isUserOrganisation) {
      return (
        <View style={{ flex: 1 }}>
          <View style={{ minHeight: open ? 150 : 90, alignSelf: 'center' }}>
            <DropSelect zIndex={3000}
              zIndexInverse={1000} value={accessState} name={'access'} setValue={setAccessState} open={open}
              items={items} setOpen={setOpen} setItems={setItems} placeholder={`${t('accessLevel')}`} formContext={undefined} />
          </View>
          <View style={{ minHeight: departmentOpen ? 300 : 90, alignSelf: 'center' }}>
            <DropSelect zIndex={2000}
              zIndexInverse={2000} formContext={undefined} value={departmentState} name={'department'} setValue={setDepartmentState} open={departmentOpen}
              items={departmentItems} setOpen={setDepartmentOpen} setItems={setDepartmentItems} placeholder={'Department'} />
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  const inverseCheckedStyle = () => {
    if (!isChecked) {
      return [styles.BackToLogin, { flex: 1, minWidth: 200 }]
    } else if (submitHovered) {
      return [styles.LoginButton, { minWidth: 200 }]
    } else { return [styles.LoginButtonHovered, { minWidth: 200 }] }
  }

  const handleLoginSwitch = useCallback((value) => {
    if (switchLogin) {
      Animated.timing(translateX, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false
      }).start();
    } else {
      Animated.timing(inverseTranslateX, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false
      }).start(({ finished }) => {
        setSwitchLogin(value)
      });
    }
    setSwitchLogin(value);
  }, [loaded])

  const gesture = (e) => {
    const { nativeEvent } = e;
    if (nativeEvent.translationX < -1) {
      setInverseXYValue(Math.abs(nativeEvent.translationX / 12));
    }
    if (nativeEvent.translationX > 1) {
      setXYValue(nativeEvent.translationX / 12);
    }
    if (nativeEvent.translationX < -150) {
      setXYValue(1);
      handleLoginSwitch(true);
    }
    else if (nativeEvent.translationX > 150) {
      setInverseXYValue(1);
      handleLoginSwitch(false);
    }
  };

  const ShowValidationOtp = () => {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, alignSelf: 'center', top: 50 }}>
          <LottieView style={{ height: '100%' }} duration={5000} source={require('@assets/animations/105919-password-security.json')} autoPlay loop={false}></LottieView>
          <Text adjustsFontSizeToFit style={{ fontSize: 14, fontWeight: '400', textAlign: 'center' }}>Enter your One Time Password</Text>
        </View>
        <>
          <OTPInput />
        </>
      </View >
    )
  }


  if ( mobilePrompt) {
    return (
      <View style={{ flex: 1, flexWrap:'wrap', overflow: 'hidden', alignContent: 'center', justifyContent: 'center', alignItems: 'center', gap: 30, maxHeight: '100%', maxWidth: '100%' }}>
        <View style={[styles.background, { position: 'absolute', width: '100%', overflow: 'hidden', minHeight: windowDimensions.height }]}>
          <LottieView source={require('@assets/animations/backgroundLottie.json')} style={{ minHeight: windowDimensions.height, overflow: 'visible', minWidth: '100%' }} autoPlay loop={true}></LottieView>
        </View>
        <View style={{
          flex: 1, gap: 30, maxWidth: 1000, backgroundColor: theme ? 'white' : '#333333', shadowRadius: 20, shadowOpacity: .2, borderRadius: 20, width: '60%', padding: 50, maxHeight: '60%'
        }}>
          < Animatable.View animation={'fadeIn'} style={{ flex: 1, maxWidth: 1000, flexDirection: 'column', alignSelf: 'center', gap: 30 }}>
            <Title style={{ flex: 1, textAlign: 'center', fontSize: 36, fontWeight: 'bold' }}>Good news!</Title>
            <Text adjustsFontSizeToFit numberOfLines={1} style={{ textAlign: 'center', fontSize: 20 }}>We are now testing on Android and iOS</Text>
            {
              Platform.OS === 'ios' ?
                <Pressable onPress={() => handleAppleAppStore()} style={{ flex: 1 }}>
                  <GetAppStore height={150} width={400} />
                </Pressable>
                :
                <View style={{ flex: 10 }}>
                  {
                    Platform.OS === 'android' ?
                      <Pressable onPress={() => handleAndroidPlayStore()} style={{ flex: 1 }}>
                        <Image source={require('@assets/images/google-play-badge.png')} style={{ flex: 1, width: 400, height: 150 }} />
                      </Pressable>
                      :
                      <View style={{ flexDirection: 'row', gap: 30, alignItems: 'center', alignContent: 'center', flex: 1 }}>
                        <Pressable onPress={() => handleAndroidPlayStore()} style={{ flex: 1 }}>
                          <Image source={require('@assets/images/google-play-badge.png')} style={{ resizeMode: 'contain', flex: 1, minHeight: 90 }} />
                        </Pressable>
                        <Pressable onPress={() => handleAppleAppStore()} style={{ flex: 1 }}>
                          <Image source={require('@assets/images/apple-store-badge.png')} style={{ resizeMode: 'contain', flex: 1, minHeight: 90 }} />
                        </Pressable>
                      </View>
                  }
                  <Text style={{ textAlign: 'left', fontSize: 20, lineHeight: 35, flex: 1 }}>Revolutionize your business with AI-powered insights and optimization! Download our B2B AI SaaS app now on iOS and Android and unlock endless possibilities for growth and success. Start making data-driven decisions today and stay ahead of the competition.</Text>
                </View>
            }
          </Animatable.View>
        </View >
        <View>
          <Pressable onPress={() => { setMobilePrompt(false); dispatch({ type: DISMISSED_APP_INFO }) }} style={{ backgroundColor: theme ? '#ececec' : 'black', shadowRadius: 20, shadowOpacity: .2, width: 300, borderRadius: 20, alignSelf: 'center' }}>
            <Text adjustsFontSizeToFit style={{ fontSize: 16, textAlign: 'center', padding: 20 }}>Continue</Text>
          </Pressable>
        </View>
      </View >
    )
  }

  return (
    <View style={{ flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
      <PasswordResetModal emailForward={formLoginContext.values.email} />
      <View style={{ flex: 1, minWidth: 450, width: 700, zIndex: 10, shadowRadius: 20, shadowOpacity: .2, gap: 20 }}>
        <ImageBackground style={{ zIndex: -10, position: "absolute", width: "100%", height: "100%" }} source={require("@assets/backgrounds/psl.png")} />
        <FlipCard
          style={[styles.credentialInputContainer, { flex: 5, paddingHorizontal: "5%", paddingVertical: "5%" }]}
          flipHorizontal={true}
          flipVertical={false}
          flip={flip}
          clickable={false}
        >
          <View style={[styles.face, { paddingHorizontal: 20, maxWidth: "95%" }]}>
            <YStack gap={20} flexGrow={1} paddingVertical={"5%"}>
              <View style={{ flex: 2, gap: 5 }}>
                <LottieView ref={animation} style={{ flex: 1, alignSelf: 'center', width: 300, }} source={require('@assets/animations/landing_Animation_dark.json')} autoPlay loop={false} ></LottieView>
                <H3 style={{ textAlign: "center" }}>Sign In to your corporate account</H3>
              </View>
              <Formik
                innerRef={loginForm}
                style={{ flex: 3 }}
                initialValues={{
                  email: null,
                  password: null,
                }}
                onSubmit={async values => {
                  console.log('attempting login from loginscreen', values)
                  setTimeout(() => { setShowForgotPassword(true); }, 1000);
                  dispatch(loginUser(values, (data: { id: undefined; success: boolean }) => {
                    console.log(data);
                    if (data.success) {
                      navigation.replace('Dashboard')
                    }
                    if (data?.id === undefined) {
                      console.log('no data stored in id')
                    }
                  }))
                }}
              >{({ handleChange, handleBlur, handleSubmit, values }) => (
                  <YStack gap={30}>
                    <XStack style={[styles.centerEverything, { flex: 1.2, borderColor: 'rgb(90,192,234)', verticalAlign: "middle", flexDirection: "row", gap: 30, alignSelf: "stretch" }]}>
                      <FontAwesome6 name="envelope" size={24} />
                      <Input
                        style={{ flex: 1, alignSelf: "stretch" }}
                        placeholder="Email"
                        keyboardType="email-address"
                        autoCapitalize="none"
                        onChange={(e) => handleChange('email')(e.nativeEvent.text)}
                        componentName="email"
                      />
                    </XStack>
                    <XStack style={[styles.centerEverything, { flex: 1.2, gap: 30, borderColor: 'rgb(90,192,234)', flexDirection: "row", alignSelf: "stretch", verticalAlign: "middle" }]}>
                      <FontAwesome6 name="lock" size={24} />
                      <Input
                        style={{ flex: 1, alignSelf: "stretch" }}
                        placeholder="Password"
                        secureTextEntry={passwordHidden}
                        onChange={(e) => handleChange('password')(e.nativeEvent.text)}
                        componentName="password"
                        autoCapitalize="none"
                      />
                      <TouchableOpacity onPress={() => setPasswordHidden(!passwordHidden)} style={{ position: 'absolute', right: -35 }}>
                        <FontAwesome6 name="eye" size={24} />
                      </TouchableOpacity>
                    </XStack>
                    <View style={{ flexDirection: "row", alignContent: 'space-around', marginVertical: 10 }}>
                      <SizableText userSelect="none" pointerEvents="box-only" style={{ color: "blue", flex: 1, textAlign: "center" }} onPress={handleResetPress}>Forgot Password?</SizableText>
                    </View>
                    <Button
                      onPress={() => {
                        if (isConnected) {
                          handleSubmit(values)
                        } else {
                          dispatch({ type: HIDE_ALL_MODALS });
                          dispatch({
                            type: SHOW_AUTH_MODAL, data: {
                              content: "No network connection", type: "error"
                          }
                          })
                        }
                      }}
                      icon={<FontAwesome name="user" color={"white"} size={24} />}>
                      Sign In
                    </Button>
                    <Pressable style={{ flex: 1, height: 80 }}>
                      <Text style={{ textAlign: "center" }}>
                        Don’t have an account?{' '}
                        <SizableText
                          userSelect="none"
                          pointerEvents="box-only"
                          style={{ color: "blue", flex: 1, textAlign: "right" }}
                          onPress={async () => {
                            if (isConnected) {
                              dispatch({ type: LOGIN_FLIP })
                            } else {
                              dispatch({ type: HIDE_ALL_MODALS });
                              dispatch({
                                type: SHOW_AUTH_MODAL, data: {
                                  content: "No network connection", type: "error"
                                }
                              })
                            }
                          }}>
                          Sign Up
                        </SizableText>
                      </Text>
                    </Pressable>
                    <View
                      style={{
                        flexDirection: 'row', // Aligns lines and text horizontally
                        alignItems: 'center', // Vertically aligns the text with the lines
                        justifyContent: 'center',
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          height: 1,
                          backgroundColor: 'gray', // Line color
                          marginRight: 8, // Adds space between the line and text
                        }}
                      />
                      <Text
                        style={{
                          paddingHorizontal: 16, // Adds padding around the text
                          backgroundColor: 'transparent', // Transparent background for text
                          zIndex: 1, // Keeps text on top of the line
                          textAlign: 'center',
                        }}
                      >
                        Or sign in with
                      </Text>
                      <View
                        style={{
                          flex: 1,
                          height: 1,
                          backgroundColor: 'gray', // Line color
                          marginLeft: 8, // Adds space between the text and the line
                        }}
                      />
                    </View>
                    <XStack columnGap={30} style={styles.centerEverything} flexWrap="wrap">
                      <YStack gap={30} flex={2} height={250}>
                        <GoogleButton />
                        <MicrosoftButton />
                        <AppleButton />
                        {
                          __DEV__ && !isConnected &&
                          <Button
                            onPress={() => navigation.navigate("Dashboard")}
                          >Bypass
                          </Button>
                        }
                      </YStack>
                    </XStack>
                  </YStack>
                )}
              </Formik>
            </YStack>
            <YStack flex={1} gap={30}>
              <Animatable.View transition={'opacity'} style={{ opacity: flip ? 0 : 1, flex: 1, gap: 20 }}>
                <View style={{ flex: 2, minHeight: 80 }}>
                  <SizableText adjustsFontSizeToFit style={{ color: 'black', textAlign: 'justify', opacity: 0.4, lineHeight: 20, fontSize: 12 }}>By using SmartSaaS®️ Leaf, you agree to abide by our <Text onPress={privacyPolicy} adjustsFontSizeToFit style={[styles.paragraphText, { textDecorationLine: 'underline', fontSize: 12, color: 'blue' }]}>privacy policy</Text> and <Text onPress={termsAndConditions} adjustsFontSizeToFit style={[styles.paragraphText, { textDecorationLine: 'underline', fontSize: 12, color: 'blue' }]}>terms.</Text>
                    Please log in using your original sign-in method to avoid creating duplicate accounts. If you have forgotten your password, use the 'Forgot Password' link to reset your password.
                  </SizableText>
                  {/* <SizableText textAlign="justify" fontSize={12} flex={4}>This software is in testing, as such, no warranty is given or implied, please use this software at your own risk. {'\n'} Data stored in this version may not be carried across to the next.</SizableText> */}
                </View>
              </Animatable.View>
            </YStack>
          </View>
          <View style={[styles.back]}>
            {
              showTypeSelection ?
                <Animatable.View animation={'fadeInDown'} style={{ flex: 1, justifyContent: 'center', top: -100 }}>
                  <Title style={{ marginVertical: 50, backgroundColor: "white", overflow: 'hidden', padding: 10, paddingHorizontal: 20 }}>Are you an individual user or an organisation?</Title>
                  <XStack flex={1} maxHeight={200} gap={20} margin={10}>
                    <Pressable onHoverIn={() => { setInHovered(true) }} onHoverOut={() => { setInHovered(false) }} onPress={() => { setIsUserOrganisation(false); setShowTypeSelection(false) }} style={[styles.centerEverything, { minWidth: "50%", minHeight: "100%", borderRadius: 20, backgroundColor: inHovered ? "rgb(90,192,234)" : "transparent", flex: 1 }]}>
                      <LottieView style={{ height: 100 }} source={require('@assets/animations/41180-customer-service-support-agent-animation.json')} autoPlay />
                      <Text adjustsFontSizeToFit style={{ color: "black", flex: 1, fontWeight: '600' }}>Individual</Text>
                    </Pressable>
                    <Pressable onHoverIn={() => { setOrgHovered(true) }} onHoverOut={() => { setOrgHovered(false) }} onPress={() => { setIsUserOrganisation(true); setShowTypeSelection(false) }} style={[styles.centerEverything, { minWidth: "50%", minHeight: "100%", padding: 15, borderRadius: 20, backgroundColor: orgHovered ? "rgb(90,192,234)" : "transparent", flex: 1 }]}>
                      <LottieView style={{ height: 100 }} source={require('@assets/animations/40274-girl-reviewing-business-analytics.json')} autoPlay />
                      <Text adjustsFontSizeToFit style={{ color: "black", flex: 1, fontWeight: '600' }}>Organisation</Text>
                    </Pressable>
                  </XStack>
                  <Button style={{ alignSelf: "center" }} onPress={() => { dispatch({ type: LOGIN_FLIP }) }}>Back</Button>
                </Animatable.View>
                :
                <Animatable.View style={{ flex: 1, paddingHorizontal: 20 }} animation={'fadeIn'}>
                  <View style={{ flex: 1, paddingHorizontal: 20, justifyContent: 'center', alignSelf: 'stretch', flexDirection: 'row', backgroundColor: '#ECECEC66', elevation: 1, marginVertical: 10, paddingVertical: 5, marginHorizontal: 15 }}>
                    <Text adjustsFontSizeToFit style={{ textAlign: 'left', flex: 4, justifyContent: 'center', fontWeight: '600', alignSelf: 'center' }}>Account Type</Text>
                    <View style={{ flexDirection: 'row', flex: 7, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                      <Text adjustsFontSizeToFit style={{ flex: 4, fontSize: 14 }}> Register as an {isUserOrganisation ? 'Organisation' : 'Individual'} </Text>
                      <SwitchWithIcons style={{ flex: 1 }}
                        onValueChange={toggleSwitch}
                        value={isUserOrganisation}
                        trackColor={{ false: '#58BFEA', true: '#58BFEA' }}
                        thumbColor={{ false: 'grey', true: 'grey' }}
                        animationDuration={150}
                        icon={{ false: 'user', true: 'users' }} />
                    </View>
                  </View>
                  <Formik
                    innerRef={registerForm}
                    initialValues={{
                      email: null,
                      password: null,
                      name: null,
                      surname: null,
                      companyName: null,
                      companyNumber: null,
                      city: 'London',
                      role: null,
                      access: null,
                      password2: null,
                      department: null,
                      interests: null,
                      industryType: null,
                      planType: selectedPlanState,
                      isUserIndividual: !isUserOrganisation
                    }}
                    onSubmit={async values => {
                      apis.registerUser(values)
                    }}
                  >{({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View style={{ flex: 13, gap: 20 }}>
                      <View style={{ flex: 13, flexDirection: 'row', paddingHorizontal: 5 }}>
                        <ScrollView style={{ flex: 1, paddingVertical: 20, paddingHorizontal: 10 }}
                          onContentSizeChange={height => {
                            setCompleteScrollBarHeight(height / 3);
                          }}
                          onLayout={({
                            nativeEvent: {
                              layout: { height }
                            }
                          }) => {
                            setVisibleScrollBarHeight(height);
                          }}
                          showsVerticalScrollIndicator={false}
                          nestedScrollEnabled={true}
                          onScroll={Animated.event(
                            [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
                            { useNativeDriver: false }
                          )}
                          scrollEventThrottle={16}
                        >
                          <View style={styles.credentialInputContainer}>
                            <View style={{ overflow: 'visible', flex: 1, minWidth: '100%', gap: 15 }} >
                              {
                                registrationInfo.map((registrationInfo, n) => {
                                  if (isUserOrganisation) {
                                    if (registrationInfo.fieldName == 'organizationNumber') {
                                      return (
                                        <View style={{ flex: 1, maxWidth: '100%' }}>
                                          <View style={{ flex: 1 }}>
                                            <Text adjustsFontSizeToFit  >{registrationInfo.placeHolderValue}</Text>
                                          </View>
                                          <TextInput
                                            key={n}
                                            onChangeText={handleChange('companyNumber')}
                                            onBlur={handleBlur('companyNumber')}
                                            textContentType={registrationInfo.fieldName}
                                            placeholder={registrationInfo.placeHolderValue}
                                            placeholderTextColor={'grey'}
                                            style={styles.inputOutline}
                                          />
                                        </View>
                                      )
                                    }
                                    if (registrationInfo.fieldName == 'city') {
                                      return (
                                        <View style={{ flex: 1, gap: 5 }}>
                                          <Text adjustsFontSizeToFit >{registrationInfo.placeHolderValue}</Text>
                                          <ScrollView nestedScrollEnabled keyboardShouldPersistTaps='always' style={{ zIndex: 5 }}>
                                            <GooglePlacesAutocomplete
                                              query={{
                                                key: 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM',
                                                langauge: 'en'
                                              }}
                                              listViewDisplayed={true}
                                              placeholder='Add your city'
                                              onPress={(data, details = null) => {
                                                setCity(data.description);
                                              }}
                                              requestUrl={{
                                                useOnPlatform: 'web',
                                                url: `${USER_API_BASE_URL}/proxy/google-map-proxy`,
                                              }}
                                              isRowScrollable={false}
                                              styles={{ zIndex: 10 }}
                                              enablePoweredByContainer={false}
                                            />
                                          </ScrollView>
                                        </View>
                                      )
                                    }
                                    if (registrationInfo.fieldName == 'organizationName') {
                                      return (
                                        <View style={{ flex: 1 }}>
                                          <Text adjustsFontSizeToFit >{registrationInfo.placeHolderValue}</Text>
                                          <TextInput
                                            key={n}
                                            onChangeText={handleChange('companyName')}
                                            onBlur={handleBlur('companyName')}
                                            textContentType={registrationInfo.fieldName}
                                            placeholder={registrationInfo.placeHolderValue}
                                            placeholderTextColor={'grey'}
                                            style={styles.inputOutline}
                                          />
                                        </View>
                                      )
                                    }
                                    return (
                                      <View style={{ flex: 1 }}>
                                        <Pressable onHoverIn={() => { console.log('item hovered, show tip tool') }} style={{ flexDirection: 'row' }}>
                                          <Text adjustsFontSizeToFit >{registrationInfo.placeHolderValue}</Text>
                                          <View style={{ flex: 0.05 }} />
                                          <EvilIcons name="question" size={16} color="black" />
                                        </Pressable>
                                        <TextInput
                                          key={n}
                                          onChangeText={handleChange(registrationInfo.fieldState.toString())}
                                          onBlur={handleBlur(registrationInfo.fieldName)}
                                          textContentType={registrationInfo.fieldName}
                                          placeholderTextColor={'grey'}
                                          placeholder={registrationInfo.placeHolderValue}
                                          style={styles.inputOutline}
                                        />
                                      </View>
                                    )
                                  }
                                  else {
                                    if (registrationInfo.type != 'organisation') {
                                      if (registrationInfo.fieldName == 'city') {
                                        return (
                                          <View style={{ flex: 1, gap: 5 }}>
                                            <Text adjustsFontSizeToFit >{registrationInfo.placeHolderValue}</Text>
                                            <ScrollView nestedScrollEnabled keyboardShouldPersistTaps='always' style={{ zIndex: 5 }}>
                                              <GooglePlacesAutocomplete
                                                query={{
                                                  key: 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM',
                                                  langauge: 'en'
                                                }}
                                                listViewDisplayed={true}
                                                placeholder='Add your city'
                                                onPress={(data) => {
                                                  const city = data.description
                                                  handleChange('city', city);
                                                }}
                                                onFail={() => console.log('google places failed')}
                                                isRowScrollable={false}
                                                styles={{ zIndex: 10 }}
                                                enablePoweredByContainer={false}
                                              />
                                            </ScrollView>
                                          </View>
                                        )
                                      } else
                                        return (
                                          <View style={{ flex: 1 }}>
                                            <Text adjustsFontSizeToFit >{registrationInfo.placeHolderValue}</Text>
                                            <TextInput
                                              key={n}
                                              onChangeText={handleChange(registrationInfo.fieldState.toString())}
                                              enablesReturnKeyAutomatically
                                              textContentType={registrationInfo.fieldName}
                                              placeholderTextColor={'grey'}
                                              autoComplete={registrationInfo.fieldName}
                                              placeholder={registrationInfo.placeHolderValue}
                                              onBlur={handleBlur(registrationInfo.fieldName.toString())}
                                              style={styles.inputOutline}
                                            />
                                          </View>
                                        )
                                    }
                                  }
                                })
                              }
                              <View style={{ flex: 1 }}>
                                <Text adjustsFontSizeToFit >Password</Text>
                                < TextInput
                                  onChangeText={handleChange('password')}
                                  textContentType={'password'}
                                  placeholderTextColor={'grey'}
                                  style={styles.inputOutline}
                                  placeholder="Password"
                                  secureTextEntry
                                  autoCapitalize="none"
                                />
                              </View>
                              <View style={{ flex: 1 }}>
                                <Text adjustsFontSizeToFit >Repeat Password</Text>
                                <TextInput
                                  onChangeText={handleChange('password2')}
                                  placeholderTextColor={'grey'}
                                  style={styles.inputOutline}

                                    placeholder="Re-enter Password"
                                    secureTextEntry
                                    autoCapitalize="none"
                                  />
                                </View>
                                <DropDowns />


                              </View>
                            </View>
                          </ScrollView>
                          <View
                            style={{
                              maxHeight: '100%',
                              width: 6,
                              backgroundColor: 'grey',
                              opacity: .6,
                              borderRadius: 8,
                              overflow: 'hidden',
                              left: 10
                            }}
                          >
                            <Animated.View
                              style={{
                                width: 6,
                                borderRadius: 8,
                                padding: 2,
                                backgroundColor: '#58BFEA',
                                height: scrollIndicatorSize,
                                transform: [{ translateY: scrollIndicator }]
                              }}
                            />
                          </View>
                        </View>
                        {
                          selectedPlan &&
                          <Pressable onPress={() => dispatch({ type: SHOW_REGISTER_MODAL })} style={{ padding: 20, borderRadius: 20, backgroundColor: 'white', height: 150, flexDirection: 'row' }}>
                            {
                              HandleIcon(0)
                            }
                            <Text adjustsFontSizeToFit style={{ textAlign: 'center', fontSize: 16, fontWeight: '600' }}>{selectedPlan.name}</Text>
                          </Pressable>
                        }
                        <View style={{ flexDirection: 'row', justifyContent: 'center', flex: 1, padding: 10, margin: 10, backgroundColor: 'white', borderRadius: 20 }}>
                          <View style={{ flex: 10, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', minHeight: 50 }}>
                            <Pressable onPress={termsAndConditions} style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                              <Text adjustsFontSizeToFit numberOfLines={2} style={{ textDecorationLine: 'underline', fontSize: 12, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                Check this box to agree to our Terms and Conditions
                              </Text>
                            </Pressable>
                          </View>
                          <View style={{ flex: 1, justifyContent: 'center' }}>
                            <Checkbox
                              style={styles.checkbox}
                              value={isChecked}
                              onCheckedChange={() => {
                                setChecked(!isChecked)
                              }}
                            />
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignContent: 'center', flex: 4, overflow: 'visible', alignSelf: 'center' }}>
                          <Pressable
                            onPress={() => {
                              dispatch({ type: BACK_TO_LOGIN })
                              dispatch({ type: HIDE_REGISTER_MODAL })
                            }}
                            style={[styles.BackToLogin, { flexDirection: 'row', flex: 1, opacity: 1, minWidth: 200 }]}
                          >
                            <View />
                            <Ionicons name={"arrow-back-outline"} size={20} color={'white'} ></Ionicons>
                            <Text adjustsFontSizeToFit style={{ color: 'white' }}>Back</Text>
                            <View />
                          </Pressable>
                          {selectedPlanState != null && registerForm.current?.values.password != null ?
                            <Pressable
                              onPress={handleSubmit}
                              style={checkedStyle()}
                              onPressIn={() => setSubmitHovered(true)}
                              onPressOut={() => setSubmitHovered(false)}
                              disabled={isChecked ? false : true}
                            >
                              <Text adjustsFontSizeToFit style={styles.appButtonText}>Submit</Text>
                            </Pressable>
                            :
                            <Pressable
                              onPress={async () => {
                                if (isUserOrganisation) {
                                  const companyName = registerForm.current.values.companyName;
                                  const companyNumber = registerForm.current.values.companyNumber;
                                  const userEmail = registerForm.current.values.email;
                                  const company = { 'companyName': companyName, 'companyNumber': companyNumber, 'email': userEmail }
                                  if (!companyName && !companyNumber) {
                                    dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Please include Company Name and/or number', type: 'error' } })
                                    return
                                  }
                                  const values = registerForm.current.values;
                                  apis.companyCheck(company, values)
                                } else {
                                  await apis.getProducts().then((res) => {
                                    if (res) {
                                      dispatch({ type: SHOW_REGISTER_MODAL, payload: registerForm.current.values })
                                    }
                                  }
                                  )
                                };
                              }
                              }
                              style={inverseCheckedStyle()}
                              onPressIn={() => setSubmitHovered(true)}
                              onPressOut={() => setSubmitHovered(false)}
                              disabled={isChecked ? false : true}
                            >
                              <Text adjustsFontSizeToFit style={{ color: 'white' }}>Plans</Text>
                            </Pressable>
                          }
                        </View>
                      </View>
                    )}
                  </Formik>
                </Animatable.View>
            }
          </View>
        </FlipCard>
        
      </View>
      {
        windowDimensions.width > 1080 &&
        <View style={{ flex: 2.5, zIndex: -1, overflow: "hidden" }}>
          <LottieView source={require('@assets/animations/backgroundLottie.json')} style={{ minHeight: windowDimensions.height, overflow: 'visible', minWidth: '200%', maxHeight: 2200, position: "absolute", left: "-60%", zIndex: -10 }} autoPlay loop={true}></LottieView>
          {
            isConnected ?
              <View style={{ flex: 1, maxHeight: "90%", paddingHorizontal: "5%" }}>
            <DynamicContent {...{ registerModalState, selectedPlanState }} />
          </View>
                :
                <View style={[styles.centerEverything, { maxWidth: "50%", maxHeight: "50%", flex: 1 }]}>
                  <LottieView source={require("@assets/animations/113096-coming-soon.json")} style={{ flex: 1 }} />
                  <H1 style={{ lineHeight: 70, fontSize: 32 }}>Looks like there may be an issue with your connection. Please ensure you are connected before proceeding.</H1>
                </View>
            }
          <CityScape key={'hi' + cityKey} style={{ flex: 1, minHeight: 200, alignSelf: "center", justifyContent: "center", bottom: 0, maxHeight: '45%', resizeMode: 'contain', position: 'fixed' }} />
          <PageTurnCorner href={"https://smartsaas.co.uk"}/>
        </View>
      }
      <RegisterModal />
    </View >
  );
}


export default LoginScreen;
