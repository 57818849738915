import { StyleSheet, Dimensions, Platform, StatusBar } from "react-native";
import { isTablet } from "react-native-device-info";

const bgheight = Dimensions.get('window').height;
const bgwidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    slider:{ 
        width: "100%",
        height: 50,
    },
    snapPoint: {
        position: 'absolute',
        backgroundColor: 'blue', // Customize color
        width: 10, // Size of the snap point
        height: 10,
        borderRadius: 5, // Makes the point circular
    },
    contextComponentWrapper: {
        flex: 1,
        alignSelf: "stretch",
        minWidth: "100%",
        maxHeight: "90%"
    },
    contextStyle: {
        flex: 15,
        marginVertical: "5%",
        alignSelf: "stretch",
        minWidth: "100%"
    },
    noticeCardContainer:{
        flexDirection: "row", gap: 30, borderRadius: 20, backgroundColor: "#ececec", margin: 10, padding: 10, shadowRadius: 20, shadowOpacity: .2
    },
    infoTextStyle: {
        fontSize: 84,
        textAlign: "center",
        marginVertical: 20,
    },
    //Texts
    headingText: {
        fontSize: 32,
        fontWeight: '600'
    },
    validationView: {
        flexWrap: 'wrap',
        flex: 1,
        gap: 15,
    },
    defaultShadow: {
        shadowOpacity: .2,
        shadowRadius: 20,
    },
    defaultRoundedShadow: {
        borderRadius: 20,
        shadowOpacity: .2,
        shadowRadius: 20,
        padding:10,
        paddingHorizontal: 20
    },
    timelineItem: {
        width: 80, // Adjust as needed
        height: 80, // Adjust as needed
        borderRadius: 40,
        transform: [{ scale: 0.8 }],
        borderWidth: 2,
        borderColor: "white",
        backgroundColor: 'rgb(90,192,234)', // Adjust as needed
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 8,
        padding: 7 // Adjust as needed
    },
    contextContainer: {
        padding: 20,
        alignSelf: 'stretch',
        flex: 1,
        paddingBottom: 50,
    },
    contextWrapper: {
        flex:1,
    },
    dataBrowserItemStyle:{
        backgroundColor: 'transparent',
        minHeight: 400,
        flex: 1,
        alignSelf: 'stretch',
    },
    dataContainerStyle: {
        padding: 10,
        minWidth: 350,
        // width: (Platform.OS === 'web' ? "100%" : "105%"),
        alignSelf: 'stretch',
        flex: 10,
        borderRadius: 20,
        borderTopEndRadius: 0,
        borderBottomEndRadius: 0,
        overflow: 'visible',
        elevation: 0,
        shadowRadius: 20,
        shadowOpacity: 0.2,
        shadowColor: 'grey'
    },
    extraSearchBarComponents: {
        alignSelf: 'stretch', flex: 1, flexDirection: 'row', alignContent: 'space-around', borderRadius: 40, backgroundColor: 'white', elevation: 15, shadowRadius: 5, shadowOpacity: .2, maxHeight: 40, padding: 20, width: "100%", paddingHorizontal: '5%'
    },
    settingsRow: {
        flex: 1,
        flexDirection: Platform.OS === 'web' ? 'row' : 'column',
        maxWidth: '100%', padding: Platform.OS === 'web' ? 15 : undefined,
        maxHeight: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginVertical: 10
    },
    iconStyleUpload: {
        borderRadius: 20,
        shadowRadius: 10,
        minWidth: 200,
        shadowOpacity: 0.2,
        backgroundColor: "white",
        flex: 1,
        gap: 10,
        maxHeight: 150
    },
    contextViewStyle: {
        flex: 1,
        backgroundColor: 'white',
        minWidth: 320,
        borderRadius: 20,
        padding: 20,
        marginVertical: 5
    },
    extendedMenu: {
        flex: 1,
        backgroundColor: '#ececec',
        borderRadius: 30,
        minHeight: 300
    },
    welcomeScreenSideBar: { minWidth: '100%', padding: 10 },
    text: {
        color: "white",
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center",
        flex: 1,
    },
    channelStyle: { flex: 1, paddingHorizontal: 5, shadowRadius: 20, shadowOpacity: .2, borderRadius: 10, height: 50, paddingVertical: 2 },
    uploadText: {
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        flex: 1,
    },
    rowItem: {
        alignItems: "center",
        justifyContent: "center",
        // flex: 1,
        padding: 5,
        borderRadius: 15
    },
    centerEverything: {
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    searchSwipeIcons: {
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 15,
        maxWidth: 110,
        height: 70,
        padding: 15
    },
    paragraphText: {
        fontSize: 16,
    },
    flipCard: {
        flex: 1,
    },

    face: {
        flex: 1,
        backfaceVisibility: 'hidden',
        backgroundColor: 'transparent'
    },
    back: {
        flex: 7,
    },
    SplitBoxesFocused: {
        borderColor: 'rgba(90, 192, 239, 1)',
        borderWidth: 3,
        borderRadius: 5,
        padding: 12,
        minWidth: 50,
        backgroundColor: 'white',
        marginHorizontal: 3,
        minHeight: 60,
    },
    SplitBoxes: {
        fontSize: 20,
        textAlign: 'center',
        color: 'black',
        borderWidth: 3,
        borderColor: 'grey',
        minWidth: 50,
        minHeight: 60,
        borderRadius: 5,
        backgroundColor: 'white',
        marginHorizontal: 3,
        padding: 12
    },
    AndroidSafeArea: {
        flex: 1,
        marginTop: Platform.select({
            ios: -70,
            android: -30,
            web: -5
        }),
        marginBottom: Platform.select({
            ios: -40,
            android: -30,
            web: 0
        }),
        paddingTop: Platform.select({
            android: 50,
            web: 0
        })
    },
    credentialInputContainer: {
        alignItems: 'center',
        textAlign: 'left'
    },
    messageSearchContainer: {
        alignItems: 'center',
        textAlign: 'left',
        flex: 1,
    },
    statusBar: {
        flex: .07,
        elevation: 1,
        zIndex: 10,
        shadowOffset: {
            width: 0,
            height: 0
        }
    },
    headerBar: {
        flex: 1.5,
        zIndex: 20,
        elevation: 1, shadowRadius: 8,
        maxHeight: '6.5%'
    },
    footerBar: {
        minHeight: 30,
        maxHeight: 30,
    },
    credentialInputContainer2: {
        width: 450,
        alignItems: 'center',
    },
    inputContainer: {
        width: 450,
        margin: 10,
        alignContent: 'center',
    },
    registerInputContainer: {
        flex: 0.1,
        margin: 1,
        height: 10,
        padding: 2,
    },
    registerInputContainer3: {
        flex: 3,
        margin: 10,
        height: 15,
        padding: 2,
    },
    registerInputContainer2: {
        flex: 0.1,
        margin: 10,
        padding: 2,
        marginBottom: 300,
        height: 15,
    },
    inputStyle: {
        borderColor: "black",
        borderWidth: 1,
        padding: 10,
    },
    manageTeamWrapper: {
        width: 350,
    },
    manageTeamTitle: {
        marginBottom: 10,
    },
    addTeamMemberInput: {
        borderBottomColor: "black",
        borderBottomWidth: 1,
        marginTop: 5,
        fontSize: 18,
    },
    manageTeamButtonContainer: {
        textAlign: "left",
        borderTopColor: "grey",
        borderTopWidth: 1,
        borderBottomColor: "grey",
        borderBottomWidth: 1,
    },
    plusButton: {
        fontSize: 28,
        fontWeight: "400",
    },
    container: {
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        zIndex: -1,
        backgroundColor: '#eeeeee',
        flex: 1
    },
    lightContainer: {
        backgroundColor: '#d0d0c0',
    },
    darkContainer: {
        backgroundColor: '#242c40',
    },
    lightThemeText: {
        color: '#242c40',
    },
    darkThemeText: {
        color: '#d0d0c0',
    },
    animationContainer: {
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
    },
    inputLabel: {
        color: 'black',
        textAlign: 'left',
        alignItems: 'flex-start'
    },
    input: {
        margin: 15,
        minHeight: 30,
        borderColor: '#4791bd',
        maxHeight: 35,
        width: Platform.OS === 'web' ? 245 : 325,
        minWidth: 200,
        borderRadius: 5,
        backgroundColor: 'white',
        flex: 1,
        alignSelf: 'center',
        paddingHorizontal: 10
    },
    inputOutline: {
        margin: 5,
        minHeight: 40,
        borderColor: '#4791bd',
        minWidth: Platform.OS === 'web' ? '90%' : undefined,
        maxWidth: 400,
        padding: 10,
        borderRadius: 5,
        flex: 1,
        alignSelf: 'stretch',
        borderWidth: 1,
        maxHeight: 55
    },
    validationTextInput: {
        minHeight: 40,
        borderColor: '#4791bd',
        maxWidth: 400,
        padding: 10,
        borderRadius: 5,
        flex: 1,
        alignSelf: 'stretch',
        borderWidth: 1,
        maxHeight: 40,
        minWidth: '90%',
        marginHorizontal: 0,
        marginVertical: 5
    },
    validationOptionContainer: {
        flexDirection: 'row', alignItems: 'center', flex: 1, alignSelf: 'stretch', minWidth: '100%', flexWrap: 'wrap'
    },
    wpDrag: {
        maxWidth: 20,
        height: "100%",
        flex: 1,
        alignSelf: "center",
        justifyContent: "center",
    },
    textRotate: {
        transform: [{ rotate: '-90deg' }],
        alignSelf: 'stretch',
        flex: 1,
        left: 35,
        minHeight: 100,
        minWidth: 100,
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    iconStyle: {
        height: 30,
        width: 30,
        padding: 5,
        borderRadius: 10,
        backgroundColor: "white",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        maxWidth: 35,
    },
    mailSideIconStyle: {
        shadowRadius: 20,
        shadowOpacity: 0.2,
        backgroundColor: "white",
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
        alignSelf: "flex-end",
        padding: 20,
        paddingHorizontal: 10,
        width: 65,
        maxWidth: 65
    },
    searchInputMessages: {
        marginHorizontal: 15,
        marginVertical: 10,
        height: 30,
        borderColor: '#4791bd',
        minWidth: 245,
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',
        flexWrap: 'wrap'
    },
    bottomSheet: {
        borderRadius: 20,
        backgroundColor: '#ececec',
        shadowRadius: 20,
        shadowOpacity: .2,
        gap: 5,
        zIndex: 100,
        paddingHorizontal: "4%",
        padding: 10,
        elevation: 10,
        width: '100%'
    },
    messageField: {
        marginVertical: 10,
        height: 30,
        borderColor: '#4791bd',
        minWidth: 260,
        padding: 10,
        backgroundColor: 'white',
    },
    userMessageListItem: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
        marginHorizontal: -10,
        padding: 15,
        overflow: 'visible'
    },
    dropdownInput: {
        marginVertical: 15,
        paddingHorizontal: 4,
        borderColor: '#4791bd',
        borderRadius: 5,
        backgroundColor: 'white',
        flexWrap: 'wrap',
        flex: 1
    },
    input3: {
        margin: 10,
        height: 40,
        borderColor: '#4791bd',
        borderWidth: 1,
        minWidth: bgwidth < 400 ? bgwidth - 30 : 600,
        padding: 5,
        borderRadius: 15,
        backgroundColor: '#ffffff'
    },
    input2: {
        margin: 15,
        height: 30,
        backgroundColor: '#ECECEC',
        borderColor: '#4791bd',
        borderWidth: 1,
        minWidth: 225,
        padding: 5,
        borderRadius: 5
    },
    modalInput: {
        height: 30,
        borderColor: '#4791bd',
        borderWidth: 1,
        flex: 3,
        paddingHorizontal: 10,
        borderRadius: 10,
        backgroundColor: 'white'
    },
    noteInput: {
        margin: 5,
        borderWidth: 1,
        borderColor: '#4791bd',
        padding: 5,
        flex: 1,
        alignContent: 'stretch',
        width: '100%'
    },
    noteInputContainer: {
        margin: 15,
        padding: 5,
        flex: 1,
        alignContent: 'stretch',
        height: 100,
    },
    submitButton: {
        backgroundColor: '#4791bd',
        padding: 10,
        margin: 15,
        height: 40,
        width: 300
    },
    submitContainer: {
        alignItems: 'flex-end'
    },
    searchButton: {
        padding: 10,
        height: 30,
        width: 70,
        alignContent: 'center',
        justifyContent: 'center',
        borderTopEndRadius: 10,
        borderBottomEndRadius: 10,
        marginLeft: -15
    },
    searchInput: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        margin: 15,
        height: 30,
        borderColor: '#4791bd',
        borderWidth: 1,
        padding: 5,
        flex: 5

    },
    footerButtonHovered: {
        backgroundColor: '#4791bd'
    },
    footerButton: {

    },
    submitButton2: {
        backgroundColor: '#4791bd',
        padding: 10,
        height: 30,
        width: 70,
        alignContent: 'center',
        justifyContent: 'center',
    },
    smallAvatar: {
        width: Platform.OS != 'web' ? 80 : 80,
        height: Platform.OS != 'web' ? 120 : 80,
        flex: 1,
        borderRadius: 20,
        overflow: 'hidden',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    messageAvatar: {
        width: Platform.OS === 'web' ? 50 : 40,
        height: Platform.OS === 'web' ? 50 : 40,
        borderRadius: 20,
    },
    profileAvatar: {
        width: 225,
        height: 225,
        flex: 1,
        borderRadius: 20,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    bigAvatar: {
        width: 120,
        height: 120,
    },
    standardSize: { width: 230, height: 48 },
    wideSize: { width: 312, height: 48 },
    avatarView: {
        flex: 1,
        flexDirection: "row",
        overflow: 'hidden'
    },
    avatarViewClosed: {
        flex: 1,
        flexDirection: "row",
        overFlow: 'hidden'
    },
    registerSubmitButton: {
        backgroundColor: '#4791bd',
        padding: 10,
        margin: 15,
        height: 40,
        width: 300
    },
    submitButtonText: {
        color: 'white',
        alignItems: 'center',
        alignContent: 'center',
    },
    informativeText: {
        color: 'black',
        fontSize: 12,
        alignContent: "space-between",
    },
    title: {
        color: 'rgb(51,122,205)',
        fontSize: 20,
        textAlign: 'center'
    },
    developmentModeText: {

    },
    contentContainer: {
        flex: 10,
    },
    contentContainer2: {
        flex: 1,
        flexDirection: 'column',
        padding: 50,
        paddingTop: -50
    },
    buttonRow: {
        flexDirection: 'column',
        flex: 2,
        flexWrap: 'wrap',
        flexBasis: 'auto',
        marginRight: 25
    },
    headerContainer: {
        backgroundColor: '#eeeeee',
        padding: 15,
        borderBottomWidth: 1,
        borderColor: 'black'
    },
    chartContainer: {
        flex: 1,
    },
    welcomeContainer: {
        alignItems: 'center',
        paddingTop: 300,
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        height: 150,
        padding: 20,
        margin: 10,
        marginBottom: -20,
        alignSelf: 'stretch',
    },
    customerLogo: {
        height: 35,
        width: Platform.OS != 'web' && bgwidth < 400 ? 140 : 140,
    },
    largeCustomerLogo: {
        height: 150,
        width: Platform.OS != 'web' && bgwidth < 400 ? 80 : 280,
    },
    affitiliateLogo: {
        height: 40,
    },
    Image: {
        height: 275,
    },
    weatherImage: {
        height: Platform.OS === 'web' ? 200 : 100,
        width: Platform.OS === 'web' ? 200 : 150,
    },
    menuLogo: {
        width: 100,
        height: 80,
        marginTop: 5,
        alignItems: 'center',
        alignSelf: 'center',
        alignContent: 'flex-end',
    },
    getStartedContainer: {
        alignItems: 'center',
        marginHorizontal: 50,
    },
    homeScreenFilename: {
        marginVertical: 7,
    },
    codeHighlightText: {
        color: 'rgba(96,100,109, 0.8)',
    },
    codeHighlightContainer: {
        borderRadius: 3,
        paddingHorizontal: 4,
    },
    getStartedText: {
        fontSize: 17,
        lineHeight: 24,
        textAlign: 'center',
        textDecorationStyle: 'solid',
        fontWeight: "bold",
    },
    headerText: {
        fontSize: 17,
        lineHeight: 24,
        textAlign: 'left',
        textDecorationStyle: 'solid',
        fontWeight: "bold",
        marginBottom: 40
    },
    radioButton: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: -18,
        marginTop: -10,
        borderRadius: 5,
    },
    radioButtonText: {
        marginTop: 7,
        marginLeft: 3,
        flexWrap: 'wrap',
        maxWidth: 250,
        margin: 10
    },
    helpContainer: {
        marginTop: 15,
        marginHorizontal: 20,
        alignItems: 'center',
    },
    helpLink: {
        paddingVertical: 5,
        alignItems: 'center',
        color: 'black'
    },
    helpLinkText: {
        textAlign: 'right',
        fontSize: 24,
    },
    separator: {
        marginVertical: 1,
        height: 3,
        color: 'black',
        opacity: 1,
        flex: 1
    },
    largeSeparator: {
        marginVertical: Platform.OS != 'web' ? 0 : 1,
        height: Platform.OS != 'web' ? 0 : '10%',
        width: Platform.OS != 'web' ? 0 : 1,
        opacity: Platform.OS != 'web' ? 0 : 1,
        flex: 5
    },
    logo: {
        marginTop: 40,
        marginBottom: 10,
        flex: 1,
    },
    link: {
        textDecorationLine: 'underline'
    },
    flagContainer: {
        flex: 1,
        maxHeight: 50
    },
    linkText: {
        fontSize: 14,
        color: '#2e78b7',
    },
    background: {
        zIndex: -1
    },
    gradientBackground: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height: 300,
        borderRadius: 20,
        paddingHorizontal: 30
    },
    backgroundOverlay: {
        flex: 1,
    },
    Button: {
        paddingVertical: 6,
        margin: 10,
        marginTop: 20,
        backgroundColor: 'rgb(51,199,238)',
        width: 175,
        height: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center'
    },
    ButtonHovered: {
        paddingVertical: 6,
        margin: 10,
        marginTop: 20,
        backgroundColor: 'rgb(49,178,210)',
        width: 175,
        height: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center'
    },
    ButtonPressed: {
        paddingVertical: 6,
        margin: 10,
        marginTop: 20,
        backgroundColor: 'rgb(51,199,238)',
        width: 175,
        height: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center'
    },
    LoginButton: {
        paddingVertical: 6,
        borderColor: 'rgb(51,199,238)',
        borderWidth: 2,
        maxHeight: 45,
        textAlign: 'center',
        verticalAlign: "middle",
        justifyContent: 'center',
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        elevation: 1,
        shadowRadius: 2,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 10,
        alignSelf: 'stretch',
        flex: 1,
        minHeight: 40,
        backgroundColor: '#ececec'
    },
    LoginButtonDisabled: {
        paddingVertical: 6,
        margin: 15,
        height: 50,
        backgroundColor: 'grey',
        width: 175,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        elevation: 1,
        shadowRadius: 5,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center',
        opacity: 0.7,
        justifyContent: 'center'
    },
    LoginButtonHovered: {
        paddingVertical: 6,
        margin: 15,
        backgroundColor: 'grey',
        borderColor: 'rgb(51,199,238)',
        borderWidth: 2,
        maxHeight: 50,
        textAlign: 'center',
        verticalAlign: "middle",
        justifyContent: 'center',
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        elevation: 1,
        shadowRadius: 20,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 30,
        alignSelf: 'stretch',
        flex: 1,
        minHeight: 40
    },
    LoginButtonHoveredMobile: {
        paddingVertical: 6,
        margin: 15,
        backgroundColor: 'rgb(51,199,140)',
        width: 175,
        minHeight: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.6,
        elevation: 1, shadowRadius: 7,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center',
        flex: 1
    },
    LogoutButton: {
        paddingVertical: 6,
        margin: 15,
        backgroundColor: 'rgb(204,0,51)',
        width: 175,
        height: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.6,
        elevation: 1, shadowRadius: 7,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center',
        flex: 1
    },
    LogoutButtonHovered: {
        paddingVertical: 6,
        margin: 15,
        backgroundColor: 'rgb(204,51,51)',
        width: 175,
        height: 31,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.6,
        elevation: 1, shadowRadius: 7,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'center',
    },
    BackToLogin: {
        paddingVertical: 6,
        margin: 15,
        backgroundColor: 'grey',
        height: 50,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        elevation: 1, shadowRadius: 5,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 15,
        alignSelf: 'stretch',
        opacity: 0.7,
        justifyContent: 'center'
    },
    checkbox: {
        margin: 8,
    },
    Button2: {
        padding: 25,
        margin: 15,
        backgroundColor: 'rgb(51,199,238)',
        width: 175,
        height: 20,
        textAlign: 'center',
        verticalAlign: "middle",
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 2.5,
            height: 2.5,
        },
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        alignSelf: 'flex-end'
    },
    appButtonText: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        color: 'white',
    },
    appButtonText2: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    //TODO #19 add dynamicity
    menuOpen: {
        alignContent: 'flex-start',
        flex: 1,
        zIndex: 1,
    },
    menuClosed: {
        alignContent: 'flex-start',
        flex: .3,
        zIndex: 1
    },
    avatarContainer: {
        margin: 25,
        marginLeft: 30,
        flex: 1
    },
    avatar: {
        width: 130,
        height: 130,
        borderRadius: 50,
        borderWidth: 2,
        borderColor: 'white',
        shadowOffset: {
            width: 2,
            height: 2,
        },
        shadowOpacity: 0.4,
        shadowColor: 'black',
        margin: 25,
        marginLeft: 50,
    },
    name: {
        position: 'absolute',
        left: 100,
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black',
    },
    name2: {
        position: 'relative',
        left: 100,
        fontSize: 14,
        color: 'black',
        marginTop: -20,
        paddingRight: 25,
    },
    description: {
        position: 'relative',
        top: 40,
        left: 90,
        fontSize: 10,
    },
    item: {
        fontSize: 14,
        fontWeight: '300',
        paddingTop: 5,
    },
    welcome: {
        fontSize: 20,
        textAlign: 'center',
        margin: 10,
        marginBottom: 40,
    },
    instructions: {
        textAlign: 'center',
        color: '#333333',
        marginBottom: 5,
    },
    linearGradient: {
        paddingLeft: 15,
        paddingRight: 15,
        height: bgheight,
    },
    screen: {
        flex: 1,
        padding: 20,
    },
    menuItem: {
        padding: 5,
        margin: 2,
        minWidth: 60,
        marginVertical: .5,
        flex: 1,
        backgroundColor: '#62BB64CC',
        borderColor: '#ffffff4D',
        flexWrap: "nowrap",
        shadowOpacity: 0.2,
        elevation: 1,
        shadowRadius: 10,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    menuItemSelected: {
        padding: 5,
        minWidth: 60,
        margin: 2,
        marginVertical: .5,
        flex: 1,
        borderColor: '#ffffff4D',
        flexWrap: "nowrap",
    },
    menuHovered: {
        padding: 5,
        margin: 2,
        minWidth: 60,
        marginVertical: .5,
        flex: 1,
        backgroundColor: '#6FAA6F',
        elevation: 1,
        shadowRadius: 10,
        shadowOpacity: 0.2,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        alignItems: 'center',
    },
    menuHideShow: {
        padding: 5,
        backgroundColor: '#eeeeee',
        borderRadius: 50,
        shadowColor: 'black',
        shadowOpacity: 0.4,
        elevation: 1,
        shadowRadius: 8,
        textAlign: 'center',
        color: 'grey',
    },
    menuHideShow2: {
        padding: 5,
        backgroundColor: '#eeeeee',
        borderRadius: 50,
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        elevation: 1, shadowRadius: 8,
        shadowOffset: {
            width: 3,
            height: 0,
        },
        textAlign: 'center',
        verticalAlign: "middle",
        color: 'grey',
        marginTop: 40,
        marginLeft: -10
    },
    menuMarginOpen: {
        flexDirection: 'column',
        height: 40,
        marginLeft: 100,
        position: 'absolute',
        zIndex: 1,
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    menuMarginClosed: {
        flex: 1,
        height: 40,
        marginLeft: 200,
        position: 'absolute'
    },
    menuItemIcon: {
        height: 30,
        width: 30,
        textAlign: 'center',
        verticalAlign: "middle",
        justifyContent: 'center',
        top: -5,
    },
    buttonIcon: {
        marginLeft: 20,
        marginTop: -13,
        paddingTop: 4,
        height: 40,
        width: 40,
        borderRadius: 7,
        borderTopEndRadius: 7,
        borderTopLeftRadius: 7,
        textAlign: 'left',
        verticalAlign: "middle",
        color: '#eeeeee',
        overflow: 'hidden'
    },
    footerButtonIcon: {
        paddingTop: 4,
        marginTop: -4,
        marginHorizontal: 4,
        height: 40,
        width: 40,
        borderRadius: 7,
        borderTopEndRadius: 7,
        borderTopLeftRadius: 7,
        textAlign: 'left',
        verticalAlign: "middle",
        color: '#eeeeee',
        overflow: 'hidden'
    },
    tab: {
        marginTop: -15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderColor: 'black',
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#8BBEDC',
        elevation: 1, 
        shadowRadius: 5,
        shadowOpacity: .2,
        flex: 1,
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    activetab: {
        marginTop: -15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderColor: 'black',
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#5fb561ff',
        elevation: 1, shadowRadius: 5,
        flex: 1,
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    tabInactive: {
        flex: 1,
        margin: 3,
        flexDirection: 'row'
    },
    tabHovered: {
        flex: 1,
        margin: 3,
        flexDirection: 'row',
        backgroundColor: '#5fb561ff',
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        elevation: 1, 
        shadowRadius: 100,
        shadowOpacity: 1,
        shadowColor: 'white',
        marginTop: -25
    },
    editAvatarButton: {
        paddingTop: 4,
        height: 40,
        width: 40,
        backgroundColor: 'white',
        borderRadius: 7,
        borderTopEndRadius: 7,
        borderTopLeftRadius: 7,
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 3,
            height: 3,
        },
        textAlign: 'center',
        verticalAlign: "middle",
        color: 'grey',
        zIndex: 1,
        position: 'absolute',
        overflow: 'hidden'
    },
    statusBarIcon: {
        minHeight: 30,
        width: 30,
        textAlign: 'center',
        verticalAlign: "middle",
        justifyContent: 'center',
        color: 'black',
        overflow: 'hidden',
        paddingTop: 5
    },
    triangle: {
        width: 2,
        height: 2,
        borderLeftWidth: 50,
        borderRightWidth: 50,
        borderBottomWidth: 100,
        borderStyle: 'solid',
        borderBottomColor: 'green',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
    },
    menuItemIconSelected: {
        marginLeft: 20,
        marginTop: -29,
        paddingTop: 4,
        height: 40,
        width: 40,
        backgroundColor: 'white',
        borderRadius: 7,
        borderTopEndRadius: 7,
        borderTopLeftRadius: 7,
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 3,
            height: 3,
        },
        textAlign: 'center',
        verticalAlign: "middle",
        color: 'black',
        overflow: 'hidden'
    },
    menuItemText: {
        marginTop: 4,
        fontSize: 14,
        flex: 0.01,
        fontWeight: '500',
        color: 'white',
        overflow: 'visible',
    },
    fieldItemText: {
        fontSize: 18,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: '600',
        textAlign: 'left',
    },
    date: {
        fontSize: 14,
        fontWeight: '600',
        verticalAlign: "middle",
        textAlign: 'left',
        padding: 6,
        shadowColor: 'grey',
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 3,
            height: 3,
        },
        elevation: 1, shadowRadius: 2,
        borderRadius: 5,
        zIndex: 2,
        backgroundColor: 'white'
    },
    footerTabs: {
        flex: .05,
        zIndex: -4,
        marginTop: -10,
        alignItems: 'center'
    },
    headerSection: {

    },
    pageContainer: {
        flexDirection: 'column',
        flex: 1,
        paddingTop: Platform.OS === 'web' ? StatusBar.currentHeight : 10,
    },
    innerContainer: {
        paddingLeft: Platform.OS === 'web' ? 30 : 0,
    },
    appContainer: {
        flex: 11,
    },
    loginContainer: {
        flex: 1,
    },
    registrationContainer: {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'flex-start',
    },
    dashContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignContent: 'space-around',
    },
    cardContainerAlt: {
        flexDirection: 'column'
    },
    settingsCardBack: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    cardContainer: {
        flex: .7,
        flexDirection: 'row',
        margin: 5,
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 80,
        minWidth: 300
    },
    searchBarContainer: {
        flex: 1,
        flexDirection: 'row',
        margin: 30,
        marginBottom: -20,
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 10,
        minWidth: 300,
        overflow: 'hidden'

    },
    cardContainer2: {
        flex: 1,
        flexDirection: 'column',
        margin: 5,
        alignSelf: 'stretch',
        alignContent: 'center',
        alignItems: 'center',
        shadowColor: 'black',
        backgroundColor: '#eeeeee',
        // elevation: 1, shadowRadius: 10,
        // shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 15,
        minWidth: 300,
        maxHeight: 500,
    },
    cardContainer3: {
        flex: 1,
        flexDirection: 'column',
        margin: 5,
        alignSelf: 'stretch',
        alignContent: 'center',
        alignItems: 'center',
        shadowColor: 'black',
        backgroundColor: '#eeeeee',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 15,
        minWidth: 280
    },
    cardContainer4: {
        flex: 2,
        width: '100%',
        flexDirection: 'row',
        margin: 5,
        alignSelf: 'stretch',
        alignContent: 'center',
        alignItems: 'center',
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        backgroundColor: '#eeeeee',
        shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 15,
        overflow: 'hidden'
    },
    cardContainer5: {
        flex: 1,
        flexDirection: 'column',
        margin: 5,
        // alignSelf: 'stretch',
        alignContent: 'center',
        alignItems: 'center',
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        borderRadius: 15,
        padding: 15,
        maxHeight: 350
    },
    card: {
        width: '100%',
        flex: 1,
    },
    card1: {
        width: '100%',
        flex: 1,
    },
    card2: {
        flex: 1,
        alignContent: 'center',
        alignSelf: 'center',
    },
    noteContainer: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        shadowColor: 'black',
        elevation: 1,
        borderRadius: 10,
        flexWrap: 'wrap',
    },
    smallWidgetContainer: {
        flex: 1,
        minWidth: 300,
        alignSelf: 'center',
        elevation: 1,
        padding: 5,
        top: 3,
        borderTopEndRadius: 20,
        borderTopStartRadius: 20
    },
    noteContainerNoShadow: {
        flex: 1,
        flexDirection: 'column',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        shadowColor: 'black',
        borderRadius: 10,
        minWidth: 325,
        flexWrap: 'wrap',
    },
    rounderShadowContainer: {
        flex: 1,
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        shadowColor: 'black',
        elevation: 1,
        shadowRadius: 10,
        shadowOpacity: 0.4,
        borderRadius: 10,
        flexWrap: 'wrap',
    },
    captureCardContainer: {
        flex: 1,
        backgroundColor: '#eeeeee',
        flexDirection: 'column'
    },
    standardCard: {
        padding: 20,
        backgroundColor: '#eeeeee',
        alignItems: 'stretch',
    },
    spacer: {
        margin: 15,
        paddingBottom: 100,
        marginBottom: 250,
    },
    interactiveContentContainer: {
        alignContent: 'center',
        height: bgheight
    },
    errorTextStyle: {
        fontWeight: 'bold',
        color: 'red',
        fontSize: 14,
    },
    dataSheet: {
        flex: 1,
        flexDirection: Platform.OS != 'web' ? 'column' : 'row',
        flexWrap: 'wrap',
        alignSelf: 'flex-start',
        padding: 25
    },
    dataSheet2: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
    },
    dataEnquirySmall: {
        flex: 1,
        flexDirection: 'row',
        padding: 1,
        flexWrap: 'wrap',
        minWidth: 200
    },
    dataEnquiryMedium: {
        flex: 1,
        flexDirection: 'row',
        padding: 1,
        flexWrap: 'wrap',
        minWidth: 300,
        alignContent: 'flex-start'
    },
    dataEnquiry1: {
        flex: 2,
        flexDirection: 'column',
        padding: '1%',
        flexWrap: 'wrap',
        // margin: 35
    },
    dataEnquiry2: {
        flex: 2,
        flexDirection: 'column',
        padding: '5%',
        flexWrap: 'wrap'
    },
    dataEnquiry3: {
        flex: 1,
        flexDirection: 'column',
        padding: '5%',
        flexWrap: 'wrap'
    },
    dataEnquiry4: {
        flex: 1,
        flexDirection: 'column',
        padding: '5%',
        flexWrap: 'wrap',
    },
    searchFields: {
        flex: 1,
        alignContent: 'flex-start',
    },
    modalContainer: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center'
    },
    modalContaineralt: {
        alignItems: 'center',
        borderRadius: 15,
        zIndex: 99999,
        justifyContent: 'center',
        margin: 'auto',
        padding: 30,
        width: 300,
        elevation: 1, shadowRadius: 20,
        shadowOpacity: 0.4,
        overflow: 'hidden',
        flex: .4,
        flexShrink: 1,
        flexBasis: 'auto',
        backgroundColor: 'white'
    },
    modalContainerAuth: {
        alignItems: 'center',
        borderRadius: 15,
        justifyContent: 'center',
        alignSelf: 'center',
        padding: 5,
        backgroundColor:"white",
        elevation: 1, shadowRadius: 20,
        shadowOpacity: 0.4,
        overflow: 'hidden',
        flex: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        zIndex: 99999,
        maxHeight: 700
    },
    modalContainerHelp: {
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        borderColor: 'white',
        borderRadius: 20,
        borderWidth: .4,
        justifyContent: 'center',
        height: 300,
        margin: 'auto',
        padding: 30,
        width: 300,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 20,
        shadowOpacity: 0.4,
        overflow: 'hidden',
        position: 'absolute'
    },
    modalContainerLanguage: {
        alignSelf: Platform.OS != 'web' ? 'center' : 'flex-end',
        alignContent: 'flex-start',
        padding: Platform.OS != 'web' ? 0 : 15,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        elevation: 1,
        shadowRadius: 20,
        shadowOpacity: 0.4,
        justifyContent: 'flex-start',
        flex: 1,
        width: 400,
        backgroundColor: '#eeeeee',
        bottom: 0,
        marginTop: Platform.OS != 'web' ? -950 : 0,
        marginLeft: Platform.OS != 'web' ? -100 : 0,
        minHeight: 600,
        maxHeight: 600,
        flexDirection: 'column'
    },
    modalContainerNotifications: {
        padding: 15,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        elevation: 1, shadowRadius: 20,
        shadowOpacity: 0.4,
        flex: 1,
        backgroundColor: '#eeeeee',
        minHeight: 600,
        maxHeight: 600,
        minWidth: '80%',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    modalContainerMessage: {
        alignSelf: 'flex-end',
        alignContent: 'flex-end',
        justifyContent: 'flex-start',
        flex: 0.1,
        borderRadius: 20,
        elevation: 1, shadowRadius: 50,
        shadowOpacity: 0.4,
        minWidth: 465,
        minHeight: 600,
        maxHeight: 600,
        backgroundColor: '#eeeeee',
        marginTop: -855,
        position: 'absolute'
    },
    appInfoContainer: {
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        justifyContent: 'center',
        borderRadius: 20,
        marginHorizontal: bgwidth / 3.5,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4
    },
    appLoadingContainer: {
        flex: 5,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        width: 600,
        backgroundColor: '#eeeeee',
        borderRadius: 20,
        elevation: 1, shadowRadius: 6,
        shadowOpacity: 0.4,
        padding: 50
    },
    modalInfoContainer: {
        alignItems: 'stretch',
        backgroundColor: '#eeeeee',
        alignSelf: 'center',
        height: 800,
        borderRadius: 20,
        width: bgwidth / 2,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        padding: 40,
        margin: 40
    },
    modalInfoContainerMobile: {
        alignItems: 'stretch',
        backgroundColor: '#eeeeee',
        alignSelf: 'center',
        height: 800,
        borderRadius: 20,
        width: bgwidth / 1.1,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        padding: 10,
        margin: 10
    },
    loginMobile: {
        marginTop: bgwidth < 400 ? '20%' : 50,
        marginBottom: '20%',
        flex: 1
    },
    fullScreenModal: {
        alignItems: 'stretch',
        backgroundColor: '#eeeeee',
        alignSelf: 'center',
        height: bgheight,
        borderRadius: 20,
        width: bgwidth,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        padding: 40,
        justifyContent: 'space-around'
    },
    addUserModal: {
        marginTop: -2,
        alignItems: 'stretch',
        backgroundColor: '#eeeeee',
        alignSelf: 'center',
        height: bgheight / 1.3,
        borderRadius: 20,
        width: bgwidth / 3,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        padding: 40,
        justifyContent: 'space-around',
        maxWidth: 1000
    },
    blurViewStyle: {
        position: 'absolute', bottom: -3000, top: -3000, left: -3000, right: -3000, opacity: 1
    },
    addUserModalMobile: {
        marginTop: -2,
        alignItems: 'stretch',
        backgroundColor: '#eeeeee',
        alignSelf: 'center',
        height: bgheight / 1.3,
        borderRadius: 20,
        width: bgwidth - 10,
        shadowColor: 'black',
        elevation: 1, shadowRadius: 10,
        shadowOpacity: 0.4,
        padding: 40,
        justifyContent: 'space-around'
    },
    fullScreenModalBackground: {
        alignItems: 'stretch',
        alignSelf: 'center',
        height: bgheight,
        width: bgwidth,
        justifyContent: 'space-around'
    },
    gap: {
        height: bgheight / 19
    },
    headerBanner: {
        color: 'black',
        fontSize: 24,
        paddingLeft: 30,
        paddingBottom: 8,
        paddingTop: 3,
        fontWeight: '600',
        textAlign: 'left',
        alignContent: 'center',
        marginVertical: 15
    },
    cardBig: {
        borderRadius: 20,
        flex: 1
    },
    defaultCardContainer: {
        borderRadius: 15,
        shadowRadius: 20,
        shadowOpacity: .2,
        shadowColor: "rgb(90,192,234)",
        padding:10
    }
});

export default styles;